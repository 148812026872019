import {
  selectOutcomeType,
  basketProviderActions
} from 'astra-core/containers/BasketProvider'
import {
  selectSport,
  selectOutcomeCategoryById,
  ESportsCodes
} from 'astra-core/containers/CommonDataProvider'
import { getBasketOutcomeName } from 'astra-core/utils/outcomes'
import { FC, memo, useMemo, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getEventTitle } from 'astra-core'

import { CoefficientCoupon } from 'features/coefficient-coupon'
import { useTextOverflow } from 'shared/lib/text-overflow/use-text-overflow'
import { IconDependentOutcomes } from 'shared/ui/Icon/General/IconDependentOutcomes'
import { Tooltip } from 'shared/ui/Tooltip'
import { RootState } from 'shared/types/store'
import { IconInfoCircleFilled } from 'shared/ui/Icon/General/IconInfoCircleFilled'
import { SportIcon } from 'shared/ui/Icon/sport-icon'
import { IconCloseOutline16 } from 'shared/ui/Icon/General/IconCloseOutline16'

import {
  StyledBetCardCouponWrapper,
  StyledBetCardDependentList,
  StyledBetCardIcon,
  StyledBetGroup,
  StyledBetGroupTitle,
  StyledEventCouponCoefficientName,
  StyledEventCouponCoefficientValue,
  StyledEventCouponContent,
  StyledEventCouponHeader,
  StyledEventCouponName,
  StyledEventCouponTeams,
  StyledEventCouponTeamsWrapper,
  StyledEventCouponTitle
} from '../common-outcomes'
import {
  BetCardCouponDependentProps,
  BetCardCouponProps,
  DependentOutcomesProps
} from '../../lib'

import {
  StyledEventCouponDependentBottom,
  StyledEventCouponDependentBottomWrapper,
  StyledEventCouponInner
} from './DependentOutcomes.styled'

export const DependentOutcomes: FC<DependentOutcomesProps> = ({
  dependentEvents
}) => {
  const [t] = useTranslation()

  return (
    <StyledBetGroup>
      <StyledBetGroupTitle>
        <div>{t('dependent outcomes')}</div>
        <Tooltip title={t('dependent outcomes hover text')}>
          <IconInfoCircleFilled size={16} />
        </Tooltip>
      </StyledBetGroupTitle>
      <StyledBetCardDependentList>
        {dependentEvents.map(({ eventId, outcomes }) => (
          <DependentOutcomeCard key={eventId} outcomes={outcomes} />
        ))}
      </StyledBetCardDependentList>
    </StyledBetGroup>
  )
}

const DependentOutcomeCard: FC<BetCardCouponDependentProps> = memo(
  ({ outcomes }) => {
    const { event } = outcomes[0]

    const { code: sportCode } = useSelector(
      (state: RootState) => selectSport(state, event.tournament.sportId)!
    )

    const eventTitle = getEventTitle(event)

    const [teamsRef, isTeamsOverflowing] = useTextOverflow<HTMLSpanElement>()
    const [namesRef, isNamesOverflowing] = useTextOverflow<HTMLDivElement>()

    return (
      <StyledBetCardCouponWrapper isDependent>
        <StyledEventCouponContent>
          <StyledEventCouponHeader>
            <StyledEventCouponTitle>
              <SportIcon size="s" sport={sportCode as ESportsCodes} />
              <Tooltip
                isCommon={!isNamesOverflowing}
                title={event.tournament.name}
              >
                <StyledEventCouponName ref={namesRef}>
                  {event.tournament.name}
                </StyledEventCouponName>
              </Tooltip>
            </StyledEventCouponTitle>
          </StyledEventCouponHeader>

          <StyledEventCouponTeamsWrapper>
            <Tooltip isCommon={!isTeamsOverflowing} title={eventTitle}>
              <StyledEventCouponTeams ref={teamsRef}>
                {eventTitle}
              </StyledEventCouponTeams>
            </Tooltip>
          </StyledEventCouponTeamsWrapper>

          {outcomes.map((outcome) => (
            <DependentOutcomeCardItem key={outcome.id} outcome={outcome} />
          ))}
        </StyledEventCouponContent>
      </StyledBetCardCouponWrapper>
    )
  }
)

const DependentOutcomeCardItem: FC<BetCardCouponProps> = memo(({ outcome }) => {
  const { event, probability, id: outcomeId } = outcome

  const outcomeType = useSelector((state: RootState) =>
    selectOutcomeType(state, probability.outcomeTypeId)
  )
  const outcomeCategory = useSelector((state: RootState) =>
    selectOutcomeCategoryById(state, outcomeType?.categoryId)
  )

  const basketOutcomeName = useMemo(() => {
    return outcomeType
      ? getBasketOutcomeName({
          probWithOutcome: {
            ...probability,
            outcomeTypeData: outcomeType
          },
          event,
          outcomeCategory
        })
      : ''
  }, [event, outcomeCategory, outcomeType, probability])

  const dispatch = useDispatch()

  const deleteBasketOutcome = useCallback(() => {
    dispatch(basketProviderActions.deleteBasketOutcome({ outcomeId }))
  }, [dispatch, outcomeId])

  const [outcomeRef, isOutcomeOverflowing] = useTextOverflow<HTMLDivElement>()

  return (
    <StyledEventCouponDependentBottomWrapper>
      <StyledEventCouponDependentBottom>
        <IconDependentOutcomes />

        <StyledEventCouponInner>
          <Tooltip isCommon={!isOutcomeOverflowing} title={basketOutcomeName}>
            <StyledEventCouponCoefficientName ref={outcomeRef}>
              {basketOutcomeName}
            </StyledEventCouponCoefficientName>
          </Tooltip>
        </StyledEventCouponInner>

        <StyledEventCouponCoefficientValue>
          <CoefficientCoupon
            eventId={event.id}
            eventProbability={outcome.probability}
          />
        </StyledEventCouponCoefficientValue>
        <StyledBetCardIcon onClick={deleteBasketOutcome}>
          <IconCloseOutline16 colorToken="icon-secondary-3" />
        </StyledBetCardIcon>
      </StyledEventCouponDependentBottom>
    </StyledEventCouponDependentBottomWrapper>
  )
})
