import { getIsEventEnded, getIsEventPending, getEventTitle } from 'astra-core'
import {
  basketProviderActions,
  IBasketError,
  selectMakeBetErrors
} from 'astra-core/containers/BasketProvider'
import { EAllBasketErrors } from 'astra-core/containers/BasketProvider/constants'
import {
  ESportsCodes,
  getOutcomeTypeById,
  selectOutcomeCategoryById,
  selectSport
} from 'astra-core/containers/CommonDataProvider'
import { getBasketOutcomeName } from 'astra-core/utils/outcomes'
import {
  FC,
  memo,
  useState,
  useMemo,
  useCallback,
  PropsWithChildren
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import { CoefficientCoupon } from 'features/coefficient-coupon'
import { useTextOverflow } from 'shared/lib/text-overflow/use-text-overflow'
import { Tooltip } from 'shared/ui/Tooltip'
import { RootState } from 'shared/types/store'
import { SportIcon } from 'shared/ui/Icon/sport-icon'
import { IconCloseOutline16 } from 'shared/ui/Icon/General/IconCloseOutline16'

import { BetCardCouponProps, BetCardElementProps } from '../../lib'

import {
  StyledBetCardCouponWrapper,
  StyledEventCouponContent,
  StyledEventCouponHeader,
  StyledEventCouponTitle,
  StyledEventCouponName,
  StyledBetCardIcon,
  StyledEventCouponTeams,
  StyledEventCouponBottom,
  StyledEventCouponCoefficientName,
  StyledEventCouponCoefficientValue,
  StyledEventCouponChildrenWrapper,
  StyledEventCouponError,
  StyledBetCardElementWrapper
} from './CommonOutcomes.styled'

export const BetCardCoupon: FC<PropsWithChildren<BetCardCouponProps>> = memo(
  ({ outcome, children }) => {
    const [t] = useTranslation()
    const [isHover, setIsHover] = useState<boolean>(false)
    const { event, probability, id: outcomeId, status } = outcome
    const { tradingStatus } = probability
    const { status: eventStatus, id: eventId } = event
    const outcomeType = useSelector((state: RootState) =>
      getOutcomeTypeById(state, probability.outcomeTypeId)
    )
    const outcomeCategory = useSelector((state: RootState) =>
      selectOutcomeCategoryById(state, outcomeType?.categoryId)
    )

    const sport = useSelector(
      (state: RootState) => selectSport(state, event.tournament.sportId)!
    )

    const dispatch = useDispatch()

    const isEventEnded = useMemo(
      () => getIsEventEnded(eventStatus, tradingStatus),
      [eventStatus, tradingStatus]
    )

    const isEventPending = useMemo(
      () => getIsEventPending(status, eventStatus, tradingStatus),
      [eventStatus, status, tradingStatus]
    )

    const toggleIsHover = useCallback(
      (flag: boolean) => {
        if (!isEventEnded) {
          setIsHover(flag)
        }
      },
      [isEventEnded]
    )

    const onMouseEnter = () => toggleIsHover(true)
    const onMouseLeave = () => toggleIsHover(false)

    const deleteBasketOutcome = useCallback(() => {
      dispatch(basketProviderActions.deleteBasketOutcome({ outcomeId }))
    }, [dispatch, outcomeId])

    const eventTitle = getEventTitle(event)

    const basketOutcomeName = useMemo(() => {
      return outcomeType
        ? getBasketOutcomeName({
            probWithOutcome: {
              ...probability,
              outcomeTypeData: outcomeType
            },
            event,
            outcomeCategory
          })
        : ''
    }, [event, outcomeCategory, outcomeType, probability])

    const errors: Dictionary<IBasketError[]> = useSelector(selectMakeBetErrors)

    const isEventCanceled = useMemo(() => {
      const errorArray: IBasketError[] = Object.values(errors).flat()

      const isEventOutdate =
        !event.live && new Date(event.eventDate) < new Date()

      return (
        isEventOutdate ||
        errorArray.find((err) => err.code === EAllBasketErrors.EVENT_CANCELED)
      )
    }, [errors, event.eventDate, event.live])

    const [namesRef, isNamesOverflowing] = useTextOverflow<HTMLDivElement>()
    const [teamsRef, isTeamsOverflowing] = useTextOverflow<HTMLSpanElement>()
    const [outcomeRef, isOutcomeOverflowing] = useTextOverflow<HTMLDivElement>()

    return (
      <>
        <StyledBetCardCouponWrapper
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <StyledEventCouponContent isEventEnded={isEventEnded}>
            <StyledEventCouponHeader>
              <StyledEventCouponTitle>
                <SportIcon size="s" sport={sport?.code as ESportsCodes} />
                <Tooltip
                  isCommon={!isNamesOverflowing}
                  title={event.tournament.name}
                >
                  <StyledEventCouponName ref={namesRef}>
                    {event.tournament.name}
                  </StyledEventCouponName>
                </Tooltip>
              </StyledEventCouponTitle>

              {(isHover || isEventEnded) && (
                <StyledBetCardIcon onClick={deleteBasketOutcome}>
                  <IconCloseOutline16 colorToken="icon-secondary-3" />
                </StyledBetCardIcon>
              )}
            </StyledEventCouponHeader>

            <Tooltip isCommon={!isTeamsOverflowing} title={eventTitle}>
              <StyledEventCouponTeams ref={teamsRef}>
                {eventTitle}
              </StyledEventCouponTeams>
            </Tooltip>

            <Tooltip isCommon={!isOutcomeOverflowing} title={basketOutcomeName}>
              <StyledEventCouponBottom>
                <StyledEventCouponCoefficientName ref={outcomeRef}>
                  {basketOutcomeName}
                </StyledEventCouponCoefficientName>

                <StyledEventCouponCoefficientValue>
                  {isEventEnded || isEventCanceled ? (
                    <div>-</div>
                  ) : (
                    <CoefficientCoupon
                      eventId={eventId}
                      eventProbability={probability}
                    />
                  )}
                </StyledEventCouponCoefficientValue>
              </StyledEventCouponBottom>
            </Tooltip>
          </StyledEventCouponContent>

          {isEventEnded || isEventCanceled ? (
            <StyledEventCouponChildrenWrapper>
              <StyledEventCouponError>
                {isEventEnded && t('event is over')}
                {isEventCanceled && t('event canceled')}
              </StyledEventCouponError>
            </StyledEventCouponChildrenWrapper>
          ) : (
            <>
              {(children || isEventPending) && (
                <StyledEventCouponChildrenWrapper>
                  {children}
                  {isEventPending && (
                    <StyledEventCouponError>
                      {t('event suspended')}
                    </StyledEventCouponError>
                  )}
                </StyledEventCouponChildrenWrapper>
              )}
            </>
          )}
        </StyledBetCardCouponWrapper>
      </>
    )
  }
)

export const BetCardElement: FC<BetCardElementProps> = memo(({ outcome }) => (
  <StyledBetCardElementWrapper>
    <BetCardCoupon outcome={outcome} />
  </StyledBetCardElementWrapper>
))
