import React, { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  accountProviderActions,
  selectTypeAuthModal
} from 'astra-core/containers/AccountProvider'
import {
  EPostMessageIdEvent,
  useGetPostAuthServiceMessage
} from 'astra-core/containers/AuthMessageProvider'

import {
  ENotificationContainerIds,
  usePopUpNotification
} from 'shared/lib/Toastify'
import { IconCheckCircleFilled } from 'shared/ui/Icon/General/IconCheckCircleFilled'
import { EAdditionalPostMessageIdEvent } from 'entities/auth/model'

import { SuccessSignUp, UnauthorizedModal } from './components'

interface AuthClosePayload {
  notification?: { type: string; text: string }
}

export const Auth: FC = () => {
  const dispatch = useDispatch()
  const typeAuthModal = useSelector(selectTypeAuthModal)

  const showToast = usePopUpNotification({
    content: {
      leftContent: <IconCheckCircleFilled colorToken="icon-positive" />
    },
    options: {
      containerId: ENotificationContainerIds.SYSTEM,
      autoClose: 5000
    }
  })

  const handleClose = useCallback(
    (payload?: AuthClosePayload) => {
      if (payload?.notification) {
        if (payload.notification.type === 'SUCCESS') {
          showToast(payload.notification.text)
        }
      }
    },
    [showToast]
  )

  const handleSuccessPage = () => {
    dispatch(accountProviderActions.getUser({}))
  }

  useGetPostAuthServiceMessage(EPostMessageIdEvent.close, handleClose)
  useGetPostAuthServiceMessage(
    EAdditionalPostMessageIdEvent.SUCCESS_PAGE as unknown as EPostMessageIdEvent,
    handleSuccessPage
  )

  return (
    <>
      {typeAuthModal ? <UnauthorizedModal /> : null}
      <SuccessSignUp />
    </>
  )
}
