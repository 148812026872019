import React, { FC, PropsWithChildren, useCallback, useMemo } from 'react'
import { OddsTableProps } from 'astrabet-templates-kit'
import { EventProbability, ParameterType } from 'betweb-openapi-axios'
import keyBy from 'lodash/keyBy'
import sortBy from 'lodash/sortBy'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'

import {
  StyledOddsTable,
  CoefficientTableCellStyled,
  StyledTableWrapper,
  StyledHeaderCell,
  StyledTextCell,
  StyledTablePlainRow,
  StyledPlainWrapper,
  StyledPlainHeaderCell,
  StyledOddsTableHeader,
  MockOdd
} from 'widgets/outcomes-grid/odds-table/odds-table.styled'
import { TableCellProps } from 'widgets/outcomes-grid/odds-table/odds-table.types'

const getArrayDepth = (value) =>
  Array.isArray(value) ? 1 + Math.max(...value.map(getArrayDepth)) : 0

const wrapTitle = (title: OddsTableProps['title']): string[][] =>
  getArrayDepth(title) === 2
    ? (title as string[][])
    : wrapTitle([title] as string | string[] | string[][])

const getOutcomeId = ({ outcomeTypeId, parameters = [] }: EventProbability) =>
  `${outcomeTypeId}-${sortBy(parameters, ['type'])
    .map((p) => p.value)
    .join('-')}`

const removeEmptyElements = (arr) => {
  const nonEmptyElements = filter(arr, (item) => !isEmpty(item))
  return isEmpty(nonEmptyElements) ? [''] : nonEmptyElements
}

export const OddsTable: FC<OddsTableProps & { bottomIsRound: boolean }> = ({
  rows,
  columns,
  title,
  withoutTitle,
  probabilities,
  isPlain,
  isLast,
  ...props
}) => {
  const formatTitle = useCallback(
    (title?: string) => {
      const probsParams = keyBy(
        probabilities.map((p) => p.parameters).flat(),
        'type'
      )

      const result = Object.values(ParameterType).reduce((res, cur) => {
        res = res.replaceAll(`{$${cur}}`, probsParams[cur]?.value ?? '')
        return res
      }, title ?? '')

      return result
        .replaceAll(
          '{$competitor1}',
          props.competitors.homeCompetitors.map((c) => c.name).join(', ')
        )
        .replaceAll(
          '{$competitor2}',
          props.competitors.awayCompetitors.map((c) => c.name).join(', ')
        )
    },
    [
      probabilities,
      props.competitors.awayCompetitors,
      props.competitors.homeCompetitors
    ]
  )

  const formattedTitles = useMemo(
    () =>
      wrapTitle(title)
        .filter((titlesGroup) => titlesGroup !== undefined)
        .map((titlesGroup) =>
          titlesGroup
            .filter((title) => title !== undefined)
            .map((title) => formatTitle(title))
        ),
    [formatTitle, title]
  )

  const formattedTitlesClearEmpty = removeEmptyElements(formattedTitles)

  const clearHeaderCell = useMemo(() => {
    if (formattedTitlesClearEmpty.flat().length === 1) {
      return formattedTitlesClearEmpty.flat()[0] !== ''
    }

    return !withoutTitle && !!formattedTitlesClearEmpty.length
  }, [formattedTitlesClearEmpty, withoutTitle])

  if (!rows.length) {
    return null
  }

  return (
    <>
      {/* {!withoutDivider && <OddsDivider />} */}
      <StyledTableWrapper>
        {clearHeaderCell
          ? formattedTitlesClearEmpty.filter(Boolean).map((formattedTitle) =>
              formattedTitle.length ? (
                isPlain ? (
                  formattedTitle[0] && (
                    <StyledOddsTableHeader
                      columns={formattedTitlesClearEmpty.flat()}
                      labelInButton={props.labelInButton}
                    >
                      <StyledPlainHeaderCell>
                        {formattedTitle[0]}
                      </StyledPlainHeaderCell>
                    </StyledOddsTableHeader>
                  )
                ) : (
                  <StyledOddsTableHeader
                    columns={formattedTitlesClearEmpty.flat()}
                    labelInButton={props.labelInButton}
                  >
                    {columns.map(
                      (column, index, array) =>
                        typeof formattedTitle[index] === 'string' && (
                          <StyledHeaderCell
                            dangerouslySetInnerHTML={{
                              __html: formattedTitle[index]
                            }}
                            isFirstInRow={index === 0}
                            isLastInRow={index === array.length - 1}
                            isOdd={index > 0 && column === 'odd'}
                            key={formattedTitle[index]}
                          />
                        )
                    )}
                  </StyledOddsTableHeader>
                )
              ) : null
            )
          : null}

        <StyledOddsTable
          columns={columns}
          isPlain={isPlain}
          labelInButton={props.labelInButton}
        >
          <PlainWrapper isPlain={isPlain}>
            {rows.map((row, rowIndex) => {
              const key = row
                .map((item) => {
                  if (typeof item === 'string') {
                    return item
                  } else if (item) {
                    return getOutcomeId(item)
                  } else {
                    return rowIndex
                  }
                })
                .join('-')

              return isPlain ? (
                <StyledTablePlainRow key={key}>
                  {columns.map((column, columnIndex) => {
                    const cellValue = row[columnIndex]

                    const keyColumn =
                      typeof cellValue === 'string'
                        ? cellValue
                        : cellValue
                        ? getOutcomeId(cellValue)
                        : columnIndex

                    if (column === 'label') {
                      return null
                    }

                    return (
                      <TableCell
                        data={cellValue}
                        hideBorder={isLast && rowIndex === rows.length - 1}
                        isLastInRow={columnIndex === columns.length - 1}
                        isOdd={column === 'odd'}
                        key={`${key}-${keyColumn || columnIndex}`}
                        isPlain
                        {...props}
                      />
                    )
                  })}
                </StyledTablePlainRow>
              ) : (
                <React.Fragment key={key}>
                  {columns.map((column, columnIndex) => {
                    const cellValue = row[columnIndex]

                    if (column === 'label' && props.labelInButton) {
                      return null
                    }

                    const keyColumn =
                      typeof cellValue === 'string'
                        ? cellValue
                        : !cellValue
                        ? columnIndex
                        : getOutcomeId(cellValue)

                    return (
                      <TableCell
                        data={cellValue}
                        hideBorder={isLast && rowIndex === rows.length - 1}
                        isLastInRow={columnIndex === columns.length - 1}
                        isOdd={column === 'odd'}
                        key={`${key}-${keyColumn || columnIndex}`}
                        {...props}
                      />
                    )
                  })}
                </React.Fragment>
              )
            })}
          </PlainWrapper>
        </StyledOddsTable>
      </StyledTableWrapper>
    </>
  )
}

const PlainWrapper: FC<PropsWithChildren<{ isPlain?: boolean }>> = ({
  children,
  isPlain = false
}) => {
  return isPlain ? (
    <StyledPlainWrapper>{children}</StyledPlainWrapper>
  ) : (
    <>{children}</>
  )
}

const TableCell: FC<TableCellProps> = ({ data, renderOdd, ...props }) => {
  if (!data) {
    return (
      <CoefficientTableCellStyled {...props}>
        <MockOdd>–</MockOdd>
      </CoefficientTableCellStyled>
    )
  }

  if (props.isOdd && typeof data !== 'string') {
    const { labelInButton } = props
    return (
      <CoefficientTableCellStyled {...props}>
        {renderOdd?.({ ...data, labelInButton })}
      </CoefficientTableCellStyled>
    )
  }

  return (
    <CoefficientTableCellStyled {...props}>
      <StyledTextCell>{data as string}</StyledTextCell>
    </CoefficientTableCellStyled>
  )
}
