import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import {
  useGetActivePaymentMethodVariantKey,
  useGetPaymentMethodVariantsVariants
} from '../store'
import { BalanceOperationsTypes, paymentProviderActions } from '../../model'

export const useSetInitialPaymentVariant = (
  balanceOperationType: BalanceOperationsTypes
) => {
  const dispatch = useDispatch()

  const activePaymentVariantKey =
    useGetActivePaymentMethodVariantKey(balanceOperationType)
  const paymentVariants =
    useGetPaymentMethodVariantsVariants(balanceOperationType)

  // Initial set first payment variant as default
  useEffect(() => {
    if (!activePaymentVariantKey && paymentVariants.length) {
      dispatch(
        paymentProviderActions.setActivePaymentMethodVariantKey({
          key: paymentVariants[0].key,
          balanceOperationsType: balanceOperationType
        })
      )
    }
  }, [activePaymentVariantKey, balanceOperationType, dispatch, paymentVariants])
}
