import React from 'react'
import { useTranslation } from 'react-i18next'

import { StyledFooterTopHelpSupport } from 'entities/footer-of-page'
import { Button } from 'shared/ui/Button'

export const OpenSupport = () => {
  const [t] = useTranslation()

  const handleButtonClick = () => window.LC_API.open_chat_window()

  return (
    <Button view="secondary-large" onClick={handleButtonClick}>
      <StyledFooterTopHelpSupport>
        {t('support service')}
      </StyledFooterTopHelpSupport>
    </Button>
  )
}
