import React, { FC, PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'
import { selectConfig } from 'astra-core/containers/ConfigProvider'

import { Auth } from 'widgets/auth'
import { Header } from 'widgets/header'
import { useIframeLocation } from 'app/lib/use-iframe-messages'

import { StyledAppLayout, StyledHeaderWrapper } from './app-layout.styled'

export const AppLayout: FC<PropsWithChildren> = ({ children }) => {
  const config = useSelector(selectConfig)
  useIframeLocation()

  return (
    <StyledAppLayout>
      {!config.IS_IFRAME && (
        <>
          <Auth />
          <StyledHeaderWrapper>
            <Header />
          </StyledHeaderWrapper>
        </>
      )}
      {children}
    </StyledAppLayout>
  )
}
