import { BetOutcomeStatus, BetStatus, BetType } from 'betweb-openapi-axios'

export interface LegacyBet {
  bet_timestamp: 1721375314
  dttm: 1721375314
  cid: 500877706463
  cardnumber: 'Astrabet 1ef45a34-c49d-6ea1-8bd9-cd5ec2d64724'
  bet_id: 183
  total_bet: number
  pay_sum: 0
  bet_status: 1
  bet_type: 0
  dt: '19.07.2024'
  tm: '13:48:34'
  btype: 1
  cashout_allowed: false
  calc_cashout_sum: null
  disableCashout: false
  co_time: null
  MainTotalSum: null
  main_bet_id: null
  events: LegacyBetEvent[]
  results: [0, 0, 1, 0, 0, 0]
  result: 2
  mix: 0
  gbi: 0
  vto: 0
  vfl: 0
  cfl: 0
  vbl: 0
  vdr: 0
  vhc: 0
  vbox: 0
  bonus: 0
  bonus_id: 0
  bingo37: 0
  betgames: 0
  superexpress: 0
  ezuga: 0
  sportgamestv: 0
  lotto: 0
  lotto2: 0
  bingo: 0
  binaryoptions: 0
  oddingg: 0
  bonus_exp: 0
  final_odd: 1.81
  is_odd: true
  sys_count: 1
  sys_size: 1
  sys_size_text: ''
  sys_combi: 1
  virtual: false
  result_text: 'Проиграло'
  cashout_result: -1
  cashout_amount: 0
  type_name: 'Ординар'
  act16: {
    joined: 0
    canjoin: 0
    canadd: 0
    info: { min: 0; max: 0; sum: 0; error: '' }
  }
  true_bet_id: 183
}

export interface LegacyBetEvent {
  matchname: 'Хапоэль Кфар Саба - Хапоэль Рамат-ха-Шарон'
  eventname: 'Исход матча. Хапоэль Рамат-ха-Шарон'
  champname: 'Товарищеские матчи'
  mdt: '19.07.2024'
  mtm: '07:00:00'
  mdttm: 1721350800
  result: 2
  live_in_mix: 0
  begun: 1
  value1: 1.81
  value2: 0
  value3: 0
  koefnum: 1
  eventid: -1678536505
  matchid: 79659798
  rec_msg_text: null
  old_price: null
  sport_id: 99
  data_source: 40
  is_odd: true
  value: 1.81
  result_text: 'Проиграло'
}

/*
0 - не рассчитано
1 - выигрыш
2 - проигрыш
3 - возврат
4 - выигрыш с частичным возвратом
5 - проигрыш с частичным возвратом
 */

export const legacyResultToBetStatus = {
  0: BetStatus.Current,
  1: BetStatus.Won,
  2: BetStatus.Lost,
  3: BetStatus.Returned,
  4: BetStatus.Won,
  5: BetStatus.Lost
}

export const legacyResultToBetOutcomeStatus = {
  0: BetOutcomeStatus.Na,
  1: BetOutcomeStatus.Win,
  2: BetOutcomeStatus.Lost,
  3: BetOutcomeStatus.Return,
  4: BetOutcomeStatus.WinReturn,
  5: BetOutcomeStatus.LostReturn
}

export const legacyBtypeToBetType = {
  1: BetType.Ordinar,
  2: BetType.Express
}

export type LegacyResponseError = {
  err_code: number
  err_desc: string | null
}

export type BaseLegacyResponse<DataType> = {
  isCache: boolean
  error: LegacyResponseError
  data: DataType
}
