import { TFunction } from 'react-i18next'
import { BetStatus } from 'betweb-openapi-axios'
import {
  betsHistoryProviderActions,
  selectBetsHistoryDataBetsFetchItems,
  selectBetsHistoryDataBetsHasMore,
  selectBetsHistoryPagination
} from 'astra-core/containers/BetsHistoryProvider'

import { DataListWithReduxPaginationProps } from 'shared/ui/DataList'

export const PAGINATION_SETTINGS = {
  setPaginationAction: betsHistoryProviderActions.setPagination,
  selectorHasMore: selectBetsHistoryDataBetsHasMore,
  selectorPagination: selectBetsHistoryPagination,
  selectorRequestStatus: selectBetsHistoryDataBetsFetchItems
} as DataListWithReduxPaginationProps['pagination']

export const ALL_OPTION_VALUE = 'ALL'

export const BET_STATUS_OPTIONS = (t: TFunction) => [
  {
    label: t('all'),
    value: BetStatus.Unknown
  },
  {
    label: t('current'),
    value: BetStatus.Current
  },
  {
    label: t('history computed'),
    value: BetStatus.Computed
  },
  {
    label: t('history won'),
    value: BetStatus.Won
  },
  {
    label: t('history returned'),
    value: BetStatus.Returned
  },
  {
    label: t('history sold'),
    value: BetStatus.Sold
  },
  {
    label: t('history lost'),
    value: BetStatus.Lost
  }
]

export const BETS_HISTORY_PAGE_SIZE = 10
