import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  selectCurrentLanguage,
  selectLanguagesList,
  useChangeLanguage
} from 'astra-core/containers/LanguagesProvider'
import { Language } from 'betweb-openapi-axios'
import { Languages, languagesList } from 'astra-core/constants'

import { Flag } from 'shared/ui/Flag'
import {
  StyledSettingItem,
  StyledSettingItemTitle
} from 'widgets/user-account-settings/Settings.styled'
import { Typography } from 'shared/ui/typography'

import {
  StyledLanguagesList,
  StyledLanguagesListItem,
  StyledLanguagesListItemContent
} from './SettingLanguage.styled'

const LANGUAGES_ORDER = ['ru', 'kz', 'en']

const LanguageItem = (item: Language) => {
  const { name, code } = item
  const { changeLanguage } = useChangeLanguage()
  const currentLanguage = useSelector(selectCurrentLanguage)

  const onChangeLanguage = () => changeLanguage(code)

  const checked = currentLanguage?.code === code

  return (
    <StyledLanguagesListItem checked={checked} onClick={onChangeLanguage}>
      <StyledLanguagesListItemContent>
        <Flag country={code} />
        <Typography
          color={checked ? 'text-primary' : 'text-secondary'}
          font="Body / 14 Medium"
        >
          {name}
        </Typography>
      </StyledLanguagesListItemContent>
    </StyledLanguagesListItem>
  )
}

export const SettingLanguage: FC = () => {
  const [t] = useTranslation()
  const languageList = useSelector(selectLanguagesList)

  const languages = useMemo(() => {
    const filtered = languageList.filter((item) =>
      languagesList.includes(item.code as Languages)
    )
    filtered.sort((a, b) => {
      if (
        LANGUAGES_ORDER.includes(a.code) &&
        !LANGUAGES_ORDER.includes(b.code)
      ) {
        return -1
      }
      if (
        !LANGUAGES_ORDER.includes(a.code) &&
        LANGUAGES_ORDER.includes(b.code)
      ) {
        return 1
      }
      if (
        !LANGUAGES_ORDER.includes(a.code) &&
        !LANGUAGES_ORDER.includes(b.code)
      ) {
        return 0
      }
      return LANGUAGES_ORDER.indexOf(a.code) - LANGUAGES_ORDER.indexOf(b.code)
    })
    return filtered
  }, [languageList])

  return (
    <StyledSettingItem>
      <StyledSettingItemTitle>{t('language')}</StyledSettingItemTitle>{' '}
      <StyledLanguagesList>
        {languages.map((item: Language) => (
          <LanguageItem key={item?.id} {...item} />
        ))}
      </StyledLanguagesList>
    </StyledSettingItem>
  )
}
