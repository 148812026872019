import { PayloadAction } from '@reduxjs/toolkit'
import { BannerList } from 'betweb-openapi-axios'

import { createSlice } from 'shared/lib/@reduxjs/toolkit'
import { getPreloadedState } from 'shared/lib/@reduxjs'
import { requestInitialState } from 'shared/lib/api'

import {
  ContainerState,
  FetchTopSuccessPayload,
  SelectSportPayload,
  SetLiveFilterPayload
} from './types'

const REDUCER_KEY = 'mainPageContainer'

// The initial state of the SportCountersProvider container
export const initialState: ContainerState = getPreloadedState(REDUCER_KEY, {
  banners: {
    items: [],
    host: '',
    fetchItems: requestInitialState
  },
  topEvents: {
    eventsIdsBySport: {},
    currentSportId: 0,
    fetchItems: requestInitialState
  },
  liveEvents: {
    eventsIdsBySport: {},
    currentSportId: 0,
    fetchItems: requestInitialState,
    filters: {
      isTop: false,
      withTranslation: false,
      isBroadcast: false
    }
  }
})

const mainPageContainerSlice = createSlice({
  name: REDUCER_KEY,
  initialState,
  reducers: {
    /**
     * Banners
     */
    fetchBanners(state) {
      state.banners.fetchItems.loading = true
    },
    fetchBannersSuccess(state, { payload }: PayloadAction<BannerList>) {
      state.banners.host = payload.host
      state.banners.items = payload.items
      state.banners.fetchItems.loading = false
    },
    fetchBannersError(state, { payload }: PayloadAction<ErrorPayload>) {
      state.banners.fetchItems.loading = false
      state.banners.fetchItems.error = payload
    },
    /**
     * Top Events
     */
    selectTopEventsSport(
      state,
      { payload }: PayloadAction<SelectSportPayload>
    ) {
      state.topEvents.currentSportId = payload.sportId
    },
    fetchTopEvents(state) {
      state.topEvents.fetchItems.loading = true
    },
    fetchTopEventsSuccess(
      _state,
      _action: PayloadAction<FetchTopSuccessPayload>
    ) {},
    setTopEvents(state, { payload }: PayloadAction<Dictionary<number[]>>) {
      state.topEvents.eventsIdsBySport = payload
      state.topEvents.fetchItems.loading = false

      const firstSport = Object.keys(payload)[0]
      if (firstSport && !state.topEvents.currentSportId) {
        state.topEvents.currentSportId = Number(firstSport)
      }
      state.topEvents.fetchItems.loaded = true
    },
    fetchTopEventsError(state, { payload }: PayloadAction<ErrorPayload>) {
      state.topEvents.fetchItems.loading = false
      state.topEvents.fetchItems.error = payload
      state.topEvents.fetchItems.loaded = true
    },
    /**
     * Live events
     */
    selectLiveEventsSport(
      state,
      { payload }: PayloadAction<SelectSportPayload>
    ) {
      state.liveEvents.currentSportId = payload.sportId
    },
    setLiveEventsFilters(
      state,
      { payload }: PayloadAction<SetLiveFilterPayload>
    ) {
      state.liveEvents.filters[payload.key] = payload.value
    },
    fetchLiveEvents(state) {
      state.liveEvents.fetchItems.loading = true
    },
    fetchLiveEventsSuccess(
      _state,
      _action: PayloadAction<FetchTopSuccessPayload>
    ) {},
    setLiveEvents(state, { payload }: PayloadAction<Dictionary<number[]>>) {
      state.liveEvents.eventsIdsBySport = payload
      state.liveEvents.fetchItems.loading = false

      const firstSport = Object.keys(payload)[0]
      if (firstSport && !state.liveEvents.currentSportId) {
        state.liveEvents.currentSportId = Number(firstSport)
      }
      state.liveEvents.fetchItems.loaded = true
    },
    fetchLiveEventsError(state, { payload }: PayloadAction<ErrorPayload>) {
      state.liveEvents.fetchItems.loading = false
      state.liveEvents.fetchItems.error = payload
      state.liveEvents.fetchItems.loaded = true
    },
    toggleFilterIsBroadcast(state) {
      state.liveEvents.filters.isBroadcast =
        !state.liveEvents.filters.isBroadcast
    },
    resetFilterIsBroadcast(state) {
      state.liveEvents.filters.isBroadcast =
        initialState.liveEvents.filters.isBroadcast
    }
  }
})

export const {
  actions: mainPageContainerActions,
  reducer,
  name: sliceKey
} = mainPageContainerSlice
