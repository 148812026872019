import { FC, useCallback, useMemo, KeyboardEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  basketProviderActions,
  getBasketConstraints,
  selectBasketBonusWallet,
  selectDisabledButtonExpressSystem,
  selectExpressOutcomeValue,
  selectExpressRate,
  selectMaxBetExpressSystem
} from 'astra-core/containers/BasketProvider'
import { useTranslation } from 'react-i18next'
import {
  EFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'

import { SumInput } from 'features/input-sum'
import { validateBasketInputValue } from 'shared/lib/basket/validateBasketInputValue'
import { separateNumber } from 'shared/lib/format/separate-number'
import {
  BetCardElement,
  DependentOutcomes,
  StyledInputSum
} from 'features/basket/basket-outcomes'
import { BasketBottom } from 'features/basket/basket-bottom'
import { BasketTooltipProvider } from 'shared/ui/Tooltip/basket-tooltip'
import { Tooltip } from 'shared/ui/Tooltip'
import { RootState } from 'shared/types/store'
import { IconMaxTag } from 'shared/ui/Icon/General/IconMaxTag'

import {
  StyledBasketBodyList,
  StyledBetCardList,
  StyledBetGroupsList
} from '../BasketBody.styled'
import { useBetErrors, useEvents } from '../../lib'

import {
  StyledMaxTag,
  StyledTotalAmount,
  StyledTotalAmountText,
  StyledTotalAmountValue,
  StyledTotalAmountValueWrapper
} from './Express.styled'
import { ExpressProps } from './Express.types'
import { ExpressBonus } from './express-bonus'

export const Express: FC<ExpressProps> = ({ outcomes }) => {
  const dispatch = useDispatch()

  const outcomeValue = useSelector(selectExpressOutcomeValue)
  const expressRate = useSelector(selectExpressRate)
  const maxExpressBet = useSelector(selectMaxBetExpressSystem)
  const disabledButton = useSelector(selectDisabledButtonExpressSystem)
  const isBonusWallet = useSelector(selectBasketBonusWallet)
  const constrains = useSelector((state: RootState) =>
    getBasketConstraints(state)
  )

  const [t] = useTranslation()

  const { minAmountError, maxAmountError } = useBetErrors()
  const { events, dependentEvents } = useEvents(outcomes)

  const [inputValue, setInputValue] = useState('')

  const calcWinSum: number = useMemo(
    () =>
      (inputValue !== '' &&
        (+expressRate.toFixed(2) - +isBonusWallet) * +inputValue) ||
      0,
    [expressRate, inputValue, isBonusWallet]
  )

  const setOutcomeAmount = useCallback(
    (value: string) => {
      setInputValue(value)
      dispatch(
        basketProviderActions.setExpressAmount({
          amount: validateBasketInputValue(value)
        })
      )
    },
    [dispatch]
  )

  const bonusOnExpressEnabled = useFeatureFlag(
    EFeatureFlags.BONUS_ON_EXPRESS_ENABLED
  )

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        dispatch(basketProviderActions.makeBet())
      }
    },
    [dispatch]
  )

  return (
    <>
      <StyledBasketBodyList>
        <StyledBetGroupsList>
          <BasketTooltipProvider>
            {!!dependentEvents.length && (
              <DependentOutcomes dependentEvents={dependentEvents} />
            )}

            <StyledBetCardList>
              {events.map((outcome) => (
                <BetCardElement key={outcome.id} outcome={outcome} />
              ))}
            </StyledBetCardList>
          </BasketTooltipProvider>
        </StyledBetGroupsList>
      </StyledBasketBodyList>

      <BasketBottom disabledButton={disabledButton || !+outcomeValue}>
        <StyledTotalAmount>
          <StyledTotalAmountText>
            {t('overall coefficient')}
          </StyledTotalAmountText>

          <StyledTotalAmountValueWrapper>
            <StyledTotalAmountValue>
              {separateNumber(expressRate.toFixed(2))}
            </StyledTotalAmountValue>

            {constrains?.betExpressMaxCoef === expressRate && (
              <Tooltip title={t('maximum total odds size')} topPlacement>
                <StyledMaxTag>
                  <IconMaxTag />
                </StyledMaxTag>
              </Tooltip>
            )}
          </StyledTotalAmountValueWrapper>
        </StyledTotalAmount>

        {bonusOnExpressEnabled && <ExpressBonus winSum={calcWinSum} />}

        <StyledInputSum>
          <SumInput
            errorMessage={maxAmountError?.message || minAmountError?.message}
            maxValue={maxExpressBet}
            winSum={calcWinSum}
            onChange={setOutcomeAmount}
            onKeyDown={handleKeyDown}
          />
        </StyledInputSum>
      </BasketBottom>
    </>
  )
}
