import styled, { css } from 'styled-components'

import { StyledEventCouponCoefficientName } from '../common-outcomes'

export const StyledEventCouponDependentBottomWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.tokens.alias.space['space-xxs-6']}px 0px;
  `}
`

export const StyledEventCouponInner = styled.div`
  flex-grow: 1;
  overflow: hidden;
`

export const StyledEventCouponDependentBottom = styled.div`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.tokens.colors.text['text-error']};
    display: flex;
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
  `}

  ${StyledEventCouponCoefficientName} {
    width: 100%;
  }
`
