import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { formatAmountLocaleString, useGetCurrencyIcon } from 'astra-core'
import { useDispatch } from 'react-redux'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { SerializedError } from '@reduxjs/toolkit'

import {
  GetCashoutResponse,
  SaveCashoutRequest,
  SaveCashoutResponse
} from 'entities/legacy-api/lib/cashout/cashout.types'
import { LegacyBet, markBetSold } from 'entities/legacy-api'

import { DISABLE_HOVER_ON_CHILD } from '../../../constants'

import { StyledCurrentCashoutWrapper } from './CurrentCashoutModal.styled'
import { getCurrentCashoutModalContent } from './constants'

export const CurrentCashoutModal: FC<{
  cashoutResponse: GetCashoutResponse
  bet: LegacyBet
  onCancel: () => void
  saveCashout: (
    args: SaveCashoutRequest
  ) => Promise<
    | { data: SaveCashoutResponse }
    | { error: FetchBaseQueryError | SerializedError }
  >
}> = ({ cashoutResponse, bet, onCancel, saveCashout }) => {
  const dispatch = useDispatch()
  const [t] = useTranslation()

  const cashout = cashoutResponse.data
  const cashoutError = cashoutResponse.error
  const currencyIcon = useGetCurrencyIcon()
  const { amount } = cashout

  const cashoutAmount = `${t('sell bet for')} ${formatAmountLocaleString({
    value: amount,
    currency: currencyIcon
  })}?`

  const makeCashout = async () => {
    await saveCashout({
      bet_id: bet.bet_id,
      amount
    })

    dispatch(markBetSold({ betId: bet.bet_id }))
  }

  const closeCashout = () => {
    onCancel()
  }

  return (
    <StyledCurrentCashoutWrapper
      className={DISABLE_HOVER_ON_CHILD}
      isMakeCashoutLoading={false}
    >
      {getCurrentCashoutModalContent({
        cashoutError,
        cashout,
        cashoutAmount,
        makeCashout,
        closeCashout
      })}
    </StyledCurrentCashoutWrapper>
  )
}
