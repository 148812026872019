import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'
import { IconPolygonBgLeftPart } from 'shared/ui/Icon/General/IconPolygonBgLeftPart'
import { Typography } from 'shared/ui/typography'
import { StyledTooltip } from 'shared/ui/Tooltip/Tooltip.styled'

export const StyledWinSum = styled.div<{ isCouponApplied: boolean }>`
  ${typography['Body / 12 Bold']}
  ${({ isCouponApplied }) => css`
    align-items: flex-end;
    color: ${isCouponApplied ? '#7845E7' : '#5cd65c'};
    display: flex;
    flex-direction: column;
    padding-left: 8px;
    white-space: nowrap;
  `}
`

export const StyledWinCurrency = styled.div`
  ${typography['Body / 14 Medium']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary-2']};
    margin-right: 4px;
  `}
`

export const StyledWinSumContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;

  ${StyledTooltip} {
    cursor: default;
  }
`

export const StyledMaxSum = styled.div`
  ${typography['Body / 12 SemiBold']}
`

export const StyledCouponSum = styled.span`
  ${typography['Body / 12 Bold']}
`

export const StyledMaxTagPart = styled(IconPolygonBgLeftPart)`
  height: 12px;
  width: max-content;
`

export const StyledMaxTagTypography = styled(Typography)`
  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-brand-default']};
    line-height: 12px;
    margin-left: -0.2px;
    margin-right: -0.2px;
  `}
`

export const StyledMaxTag = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  & > span {
    width: 27px;
  }

  ${({ theme }) => css`
    & svg {
      color: ${theme.tokens.colors.surface['surface-brand-default']};
    }
  `}
`

export const StyledIconWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-right: 4px;
`

export const StyledAlertWraper = styled.div`
  padding-top: 6px;
`
