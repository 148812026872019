import { BetType } from 'betweb-openapi-axios'

import { LegacyBet } from 'entities/legacy-api/lib'
import { getBetTypeFromLegacy } from 'entities/legacy-api/lib/utils'

export const getExpressSystemOutcomesCount = (item: LegacyBet) => {
  const outcomes = item.events.filter((e) => !!e.sport_id)
  if (getBetTypeFromLegacy(item) === BetType.System) {
    return `${item.sys_size}/${item.sys_count}`
  }
  if (getBetTypeFromLegacy(item) === BetType.Express) {
    return outcomes.length
  }
  return ''
}

export const isBonusBalanceBet = (bet: LegacyBet) => {
  if (
    bet.bonus !== 0 &&
    bet.bonus_id === 0 &&
    bet.cardnumber[bet.cardnumber.length - 1] === '-'
  ) {
    return true
  }
  return false
}

export const isCouponBonusBet = (bet: LegacyBet) => {
  if (bet.bonus !== 0 && bet.bonus_id !== 0) {
    return true
  }

  if (
    bet.bonus !== 0 &&
    bet.bonus_id === 0 &&
    !Number.isNaN(+bet.cardnumber[bet.cardnumber.length - 1])
  ) {
    return true
  }

  return false
}
