import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  betsHistoryProviderActions,
  selectCashoutError
} from 'astra-core/containers/BetsHistoryProvider'

import { LoaderPoints } from 'shared/ui/LoaderPoints'

import {
  CASHOUT_BUTTOR_ERROR_TEXT,
  ECurrentCashoutModalContent,
  ICurrentCashoutModalContent,
  IGetCurrentCashoutModalContent
} from './CurrentCashoutModal.types'
import {
  StyledCurrentCashoutButton,
  StyledCurrentCashoutButtonWrapper,
  StyledCurrentCashoutModalContentWrapper,
  StyledCurrentCashoutText,
  StyledMakeCashoutLoadingWrapper
} from './CurrentCashoutModal.styled'

// TODO try to use a single cashout component
export const getCurrentCashoutModalContent = ({
  cashoutError,
  cashout,
  isCashoutAmountHasChanged,
  setCurrentCashout,
  cashoutAmount
}: IGetCurrentCashoutModalContent) => {
  if (cashoutError) {
    return (
      <CurrentCashoutModalContent
        cashoutAmount={cashoutAmount}
        contentType={ECurrentCashoutModalContent.SELL_BET_ERROR}
        setCurrentCashout={setCurrentCashout}
      />
    )
  } else if (cashout?.outdated) {
    return (
      <CurrentCashoutModalContent
        cashoutAmount={cashoutAmount}
        contentType={ECurrentCashoutModalContent.SELL_NOT_POSSIBLE}
        setCurrentCashout={setCurrentCashout}
      />
    )
  } else if (isCashoutAmountHasChanged) {
    return (
      <CurrentCashoutModalContent
        cashoutAmount={cashoutAmount}
        contentType={ECurrentCashoutModalContent.AMOUNT_HAS_CHANGED}
        setCurrentCashout={setCurrentCashout}
      />
    )
  } else {
    return (
      <CurrentCashoutModalContent
        cashoutAmount={cashoutAmount}
        contentType={ECurrentCashoutModalContent.ACTIVE_SELL}
        setCurrentCashout={setCurrentCashout}
      />
    )
  }
}

// TODO try to use a single cashout component
export const CurrentCashoutModalContent = ({
  cashoutAmount,
  contentType
}: ICurrentCashoutModalContent) => {
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const cashoutError = useSelector(selectCashoutError)!

  const unsetCurrentCashout = (event) => {
    event.stopPropagation()
    dispatch(betsHistoryProviderActions.unsetCurrentCashout())
  }

  const makeCashout = (event) => {
    event.stopPropagation()
    dispatch(betsHistoryProviderActions.makeCashout())
  }

  if (contentType === ECurrentCashoutModalContent.ACTIVE_SELL) {
    return (
      <StyledCurrentCashoutModalContentWrapper>
        <StyledCurrentCashoutText>{cashoutAmount}</StyledCurrentCashoutText>

        <StyledCurrentCashoutButtonWrapper>
          <StyledCurrentCashoutButton onClick={makeCashout}>
            {t('yes')}
          </StyledCurrentCashoutButton>
          <StyledCurrentCashoutButton
            view="secondary-large"
            onClick={unsetCurrentCashout}
          >
            {t('no')}
          </StyledCurrentCashoutButton>
        </StyledCurrentCashoutButtonWrapper>
      </StyledCurrentCashoutModalContentWrapper>
    )
  } else if (contentType === ECurrentCashoutModalContent.AMOUNT_HAS_CHANGED) {
    return (
      <StyledCurrentCashoutModalContentWrapper>
        <StyledCurrentCashoutText>
          {t('bet amount has changed')}. {cashoutAmount}
        </StyledCurrentCashoutText>

        <StyledCurrentCashoutButtonWrapper>
          <StyledCurrentCashoutButton onClick={makeCashout}>
            {t('yes')}
          </StyledCurrentCashoutButton>
          <StyledCurrentCashoutButton
            view="secondary-large"
            onClick={unsetCurrentCashout}
          >
            {t('no')}
          </StyledCurrentCashoutButton>
        </StyledCurrentCashoutButtonWrapper>
      </StyledCurrentCashoutModalContentWrapper>
    )
  } else if (contentType === ECurrentCashoutModalContent.SELL_NOT_POSSIBLE) {
    return (
      <StyledCurrentCashoutModalContentWrapper>
        <StyledCurrentCashoutText>
          {t('sell bet is not possible')}
        </StyledCurrentCashoutText>

        <StyledCurrentCashoutButton onClick={unsetCurrentCashout}>
          {t('close')}
        </StyledCurrentCashoutButton>
      </StyledCurrentCashoutModalContentWrapper>
    )
  } else if (contentType === ECurrentCashoutModalContent.SELL_BET_ERROR) {
    return (
      <StyledCurrentCashoutModalContentWrapper>
        <StyledCurrentCashoutText>
          {t(cashoutError?.title ?? 'error')}
        </StyledCurrentCashoutText>

        <StyledCurrentCashoutButton onClick={unsetCurrentCashout}>
          {t(CASHOUT_BUTTOR_ERROR_TEXT[cashoutError?.code] ?? 'close')}
        </StyledCurrentCashoutButton>
      </StyledCurrentCashoutModalContentWrapper>
    )
  } else {
    return null
  }
}

export const MakeCashoutLoading = () => (
  <StyledMakeCashoutLoadingWrapper view="secondary-medium">
    <LoaderPoints
      settingsAnimation={{
        timeStep: 0.3,
        timeAfterEnd: 1.7,
        scaleActivePoint: 1
      }}
      count={3}
      size={4}
    />
  </StyledMakeCashoutLoadingWrapper>
)
