import { selectConfig } from 'astra-core/containers/ConfigProvider'
import { getCurrentLng } from 'astra-core/utils'
import Cookies from 'js-cookie'
import qs from 'qs'
import { EAuthPagesAndModal } from 'astra-core/constants/auth'
import { selectTypeAuthModal } from 'astra-core/containers/AccountProvider'
import { useSelector } from 'react-redux'
import { useCallback, useMemo } from 'react'
import { useTheme } from 'styled-components'

import { ADVERT_INFO_COOKIE } from 'constants/cookies.constants'
import { EAdditionalAuthModalsAndPages } from 'entities/auth/model'
import { selectTheme } from 'shared/lib/theme/select-theme'

export const useGetIframeData = () => {
  const typeAuthModal = useSelector(selectTypeAuthModal) as
    | EAuthPagesAndModal
    | EAdditionalAuthModalsAndPages
    | null
  const config = useSelector(selectConfig)
  const typeTheme = useSelector(selectTheme)
  const theme = useTheme()

  const rawParams = {
    'front-state': `desktop${typeTheme ? `-${typeTheme.toLowerCase()}` : ''}`,
    locale: `${getCurrentLng()}`,
    'advert-info': Cookies.get(ADVERT_INFO_COOKIE),
    fastSignUpRedirect: `${config.AUTH_REDIRECT_URL}/fast-sign-up-success`,
    recoveryRedirect: `${config.AUTH_REDIRECT_URL}/recovery`,
    defaultRedirect: `${config.AUTH_REDIRECT_URL}/success`,
    changePassword: `${config.AUTH_REDIRECT_URL}/change-password`,
    clientRulesUrl: `${config.CLIENT_RULES_URL}`
  }
  const queryParams = qs.stringify(rawParams)

  const shieldedRedirect = useCallback(() => {
    switch (typeAuthModal) {
      case EAuthPagesAndModal.FastSignUp:
        return rawParams.fastSignUpRedirect
      case EAuthPagesAndModal.Recovery:
        return rawParams.recoveryRedirect
      case EAdditionalAuthModalsAndPages.ChangePassword:
        return rawParams.changePassword
      default:
        return rawParams.defaultRedirect
    }
  }, [
    rawParams.changePassword,
    rawParams.defaultRedirect,
    rawParams.fastSignUpRedirect,
    rawParams.recoveryRedirect,
    typeAuthModal
  ])

  const shieldedQueryParams = useMemo(
    () =>
      qs.stringify(
        { 'redirect-url': shieldedRedirect() },
        { delimiter: '%26' }
      ),
    [shieldedRedirect]
  )

  const src = useMemo(() => {
    switch (typeAuthModal) {
      case EAuthPagesAndModal.FastSignUp:
        return `${config.AUTH_SERVER_URL}/api/fast-sign-up?${queryParams}&redirect-url=${config.API_URL}/oauth2/authorization/betweb-api-oidc?${shieldedQueryParams}`
      case EAuthPagesAndModal.Recovery:
        return `${config.AUTH_SERVER_URL}/ui/recovery?${queryParams}&redirect-url=${config.API_URL}/oauth2/authorization/betweb-api-oidc?${shieldedQueryParams}`
      case EAdditionalAuthModalsAndPages.ChangePassword:
        return `${config.AUTH_SERVER_URL}/ui/change-password?${queryParams}&redirect-url=${config.API_URL}/oauth2/authorization/betweb-api-oidc?${shieldedQueryParams}`
      default:
      case EAuthPagesAndModal.SignIn:
        return `${config.API_URL}/oauth2/authorization/betweb-api-oidc?${queryParams}`
    }
  }, [
    config.API_URL,
    config.AUTH_SERVER_URL,
    queryParams,
    shieldedQueryParams,
    typeAuthModal
  ])

  return {
    id: 'iframeAuthId',
    src,
    styles: {
      minWidth: '375px',
      padding: `${theme.tokens.alias.space['space-xxl-32']}px`
    },
    typeAuthModal
  }
}
