import React, { FC, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Event, MarketGroup } from 'betweb-openapi-axios'
import { selectMarketGroupsByEvent } from 'astra-core/containers/EventsProvider'

import { outcomesContainerActions } from 'containers/OutcomesContainer/slice'
import { selectOutcomesGroup } from 'containers/OutcomesContainer/selectors'
import { SegmentedControl } from 'shared/ui/segmented-control'

type OutcomesFilterTabsProps = {
  eventId: Event['id']
}

const NO_GROUP_SELECTED_VALUE = 'event-groups-all'

export const OutcomesGroupsTabs: FC<OutcomesFilterTabsProps> = ({
  eventId
}) => {
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const selectedGroup = useSelector(selectOutcomesGroup)
  const marketGroups = useSelector((state) =>
    selectMarketGroupsByEvent(state, eventId)
  )

  useEffect(() => {
    return () => {
      dispatch(outcomesContainerActions.setSelectedOutcomesGroup(null))
    }
  }, [dispatch])

  const handleSportTypeClick = useCallback(
    (groupId: MarketGroup['id']) =>
      dispatch(
        outcomesContainerActions.setSelectedOutcomesGroup(
          marketGroups.find((m) => m.id === groupId) ?? null
        )
      ),
    [dispatch, marketGroups]
  )

  const items = [
    { label: t('all'), value: NO_GROUP_SELECTED_VALUE },
    ...marketGroups.map((m) => ({
      value: m.id,
      label: m.name
    }))
  ]

  return (
    <SegmentedControl
      items={items}
      itemSize={24}
      value={selectedGroup?.id ?? NO_GROUP_SELECTED_VALUE}
      view="secondary"
      onChange={handleSportTypeClick}
    />
  )
}
