import { FC, useState, MouseEvent } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { selectIsMakeCashoutLoading } from 'astra-core/containers/BetsHistoryProvider'
import { useGetCurrencyIcon, formatAmountLocaleString } from 'astra-core'

import { DISABLE_HOVER_ON_CHILD } from 'widgets/bets-history/components/constants'
import { Tooltip } from 'shared/ui/Tooltip'
import { MakeCashoutLoading } from 'features/basket/basket-history/ui/BasketHistoryCashout/components/CurrentCashoutModal/constants'
import {
  cashoutApi,
  LegacyBet,
  SaveCashoutRequest
} from 'entities/legacy-api/lib'

import { CurrentCashoutModal } from '../CurrentCashoutModal'
import { StyledMakeCashoutLoadingWrapper } from '../CurrentCashoutModal/CurrentCashoutModal.styled'

import {
  StyledCellEventCashoutText,
  StyledCellEventCashoutWrapper,
  CurrentCashoutModalWholeBackground,
  StyledCellEventCashoutCurrency
} from './CellEventCashout.styled'
import { useCashoutToast } from './use-cashout-toast'

const CellEventCashoutText: FC<{ amount: number }> = ({ amount }) => {
  const [t] = useTranslation()
  const currencyIcon = useGetCurrencyIcon()
  const isMakeCashoutLoading = useSelector(selectIsMakeCashoutLoading)

  const text = `${t('sell for')} ${formatAmountLocaleString({
    value: amount
  })}`

  return (
    <Tooltip
      isCommon={!isMakeCashoutLoading}
      title={t('currently impossible to sell a bet')}
    >
      <StyledCellEventCashoutText
        className={DISABLE_HOVER_ON_CHILD}
        disabled={isMakeCashoutLoading}
        isLoading={isMakeCashoutLoading}
        view="secondary-large"
      >
        {text}
        <StyledCellEventCashoutCurrency>
          {currencyIcon}
        </StyledCellEventCashoutCurrency>
      </StyledCellEventCashoutText>
    </Tooltip>
  )
}

interface Props {
  bet: LegacyBet
  className?: string
}

const { useGetCashoutQuery, useSaveCashoutMutation } = cashoutApi

export const CellEventCashout: FC<Props> = ({ bet, className }) => {
  const [visible, setVisible] = useState(false)

  const { showErrorToast, showSuccessToast } = useCashoutToast()

  const [saveCashout, { isLoading: isSaveLoading }] = useSaveCashoutMutation()

  const { data, isLoading: isGetLoading } = useGetCashoutQuery(
    { bet_id: bet.bet_id },
    { skip: !bet.cashout_allowed }
  )

  const unsetCurrentCashout = () => {
    setVisible(false)
  }

  const setCurrentCashout = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    setVisible(true)
  }

  const handleBackgroundClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    unsetCurrentCashout()
  }

  const isLoading = isGetLoading || isSaveLoading

  const handleCashoutSave = async (args: SaveCashoutRequest) => {
    const response = await saveCashout(args)

    if ('error' in response) {
      showErrorToast()
    } else {
      showSuccessToast()
    }

    return response
  }

  if (!bet.cashout_allowed) {
    return null
  }

  return (
    <StyledCellEventCashoutWrapper
      className={className}
      onClick={setCurrentCashout}
    >
      {isLoading ? (
        <StyledMakeCashoutLoadingWrapper>
          <MakeCashoutLoading />
        </StyledMakeCashoutLoadingWrapper>
      ) : (
        <CellEventCashoutText amount={bet.cashout_amount} />
      )}

      {visible && !!data && !isLoading && (
        <>
          <CurrentCashoutModal
            bet={bet}
            cashoutResponse={data}
            saveCashout={handleCashoutSave}
            onCancel={unsetCurrentCashout}
          />
          <CurrentCashoutModalWholeBackground onClick={handleBackgroundClick} />
        </>
      )}
    </StyledCellEventCashoutWrapper>
  )
}
