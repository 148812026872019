import { useSelector } from 'react-redux'
import { useMemo } from 'react'
import {
  IBasketError,
  selectDuplicateEvents,
  selectMakeBetErrorsTop
} from 'astra-core/containers/BasketProvider'
import { BasketOutcome } from 'betweb-openapi-axios'
import { EAllBasketErrors } from 'astra-core/containers/BasketProvider/constants'

export const useEvents = (outcomes) => {
  const duplicateEventsId = useSelector(selectDuplicateEvents)

  const { events, dependentEvents } = useMemo(() => {
    const duplicateEventsSet = new Set(duplicateEventsId)

    const dependentEvents: { eventId: number; outcomes: BasketOutcome[] }[] = [
      ...duplicateEventsSet
    ].map((id) => {
      return { eventId: id, outcomes: [] }
    })

    const events: BasketOutcome[] = []

    outcomes.forEach((outcome) => {
      const eventId = outcome.event.id

      if (duplicateEventsSet.has(eventId)) {
        dependentEvents
          .find((item) => item.eventId === eventId)
          ?.outcomes.push(outcome)
      } else {
        events.push(outcome)
      }
    })

    return { events, dependentEvents }
  }, [duplicateEventsId, outcomes])

  return { events, dependentEvents }
}

export const useBetErrors = () => {
  const errors: IBasketError[] = useSelector(selectMakeBetErrorsTop)

  const minAmountError = useMemo(() => {
    return errors.find(
      (err) => err.code === EAllBasketErrors.MIN_AMOUNT_MUST_EXCEED
    )
  }, [errors])

  const maxAmountError = useMemo(() => {
    return errors.find(
      (err) => err.code === EAllBasketErrors.BETS_PAY_MAX_EXCEEDED
    )
  }, [errors])

  return { minAmountError, maxAmountError }
}
