import React, {
  ElementRef,
  FC,
  memo,
  useCallback,
  useRef,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  eventsProviderActions,
  selectEventById
} from 'astra-core/containers/EventsProvider'
import {
  ESportsCodes,
  selectSports
} from 'astra-core/containers/CommonDataProvider'
import {
  EFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'

import { useInterval } from 'hooks'
import { LoaderPoints } from 'shared/ui/LoaderPoints'
import { OutcomesGroupsTabs } from 'widgets/outcomes-grid/Filters/components/OutcomesFilterTabs'
import { IconButton } from 'shared/ui/buttons/IconButton'
import { IconChevronDoubleUp } from 'shared/ui/Icon/General/IconChevronDoubleUp'
import { HotProbabilities } from 'features/hot-probabilities/hot-probabilities'
import { selectOutcomesGroup } from 'containers/OutcomesContainer/selectors'
import { FooterOfPage } from 'widgets/footer-of-page'
import { Scrollable } from 'shared/ui/scrollable'

import { OutcomesComponentType, OutcomesProps } from './outcomes-grid.types'
import * as S from './outcomes-grid.styled'
import { OutcomesCategories } from './Table'
import {
  DESKTOP_COLUMNS_COUNT,
  EVENT_OUTCOMES_POLLING_INTERVAL
} from './constants'
import { OutcomesSearch } from './outcomes-search/outcomes-search'

export const OutcomesGrid: FC<OutcomesProps> = memo(
  ({ eventId, type, isLoading = false, onScrollCallback }) => {
    const event = useSelector((state) => selectEventById(state, eventId))
    const sports = useSelector(selectSports)
    const outcomesRef = useRef<ElementRef<typeof OutcomesCategories>>(null)
    const dispatch = useDispatch()
    const [areAllOpened, setAllOpened] = useState(true)
    const selectedGroup = useSelector(selectOutcomesGroup)
    const OUTCOMES_SEARCH_ENABLED = useFeatureFlag(
      'OUTCOMES_SEARCH_ENABLED' as EFeatureFlags
    )

    const fetchEventProbabilities = useCallback(() => {
      dispatch(eventsProviderActions.fetchEventProbabilities({ eventId }))
    }, [dispatch, eventId])

    const openAll = useCallback(() => {
      setAllOpened(true)
      outcomesRef.current?.openAll()
    }, [])

    const closeAll = useCallback(() => {
      setAllOpened(false)
      outcomesRef.current?.closeAll()
    }, [])

    /*
  Add polling event outcomes only for Events List,
  since polling full event data is already enabled on Event Page
   */
    useInterval(
      fetchEventProbabilities,
      type === OutcomesComponentType.EVENTS_LIST
        ? EVENT_OUTCOMES_POLLING_INTERVAL
        : null,
      true
    )

    const isEventPage = type === OutcomesComponentType.EVENT_PAGE

    const showHotProbabilities =
      isEventPage &&
      selectedGroup === null &&
      event.tournament.sportId &&
      sports[event.tournament.sportId]?.code === ESportsCodes.FOOTBALL

    return (
      <S.StyledOutcomes isEventPage={isEventPage}>
        {isLoading ? (
          <S.StyledOutcomesLoaderWrap>
            <LoaderPoints size={6} />
          </S.StyledOutcomesLoaderWrap>
        ) : (
          <>
            <S.StyledOutcomesTables isEventPage={isEventPage}>
              <S.OutcomesHead>
                <OutcomesGroupsTabs eventId={eventId} />
                <S.ButtonsBlock>
                  {OUTCOMES_SEARCH_ENABLED && (
                    <OutcomesSearch eventId={eventId} />
                  )}
                  <IconButton
                    icon={IconChevronDoubleUp}
                    twist={areAllOpened ? 0 : 180}
                    view="outline-l"
                    onClick={() => (areAllOpened ? closeAll() : openAll())}
                  />
                </S.ButtonsBlock>
              </S.OutcomesHead>
              <Scrollable onScrollCallback={onScrollCallback}>
                <S.OutcomesContent>
                  <S.ProbabilitiesWrapper>
                    {showHotProbabilities && (
                      <HotProbabilities eventId={eventId} />
                    )}
                    <OutcomesCategories
                      columnsCount={DESKTOP_COLUMNS_COUNT}
                      eventId={eventId}
                      ref={outcomesRef}
                    />
                  </S.ProbabilitiesWrapper>
                  {isEventPage && <FooterOfPage />}
                </S.OutcomesContent>
              </Scrollable>
            </S.StyledOutcomesTables>
          </>
        )}
      </S.StyledOutcomes>
    )
  }
)
