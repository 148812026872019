import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'
import { ThemeContext } from 'shared/lib/theme'

import { TableRowStatusOperation } from '../../model'

export const ROWS_SIZE = [
  { width: '0' },
  { width: '0' },
  { width: '30%' },
  {},
  { width: '0' },
  { width: '5.6rem' }
]

const ITEM_STYLES = ({ theme }: { theme: ThemeContext }) => css`
  color: ${theme.tokens.colors.text['text-primary-2']};
  padding: 0.75rem ${theme.tokens.alias.space['space-xs-8']}px;
  white-space: nowrap;

  ${typography['Body / 14 Medium']};
`

export const Item = styled.td`
  ${({ theme }) =>
    css`
      ${ITEM_STYLES({ theme })}
    `}
`

export const TableRow = styled.tr<{ $isDisabledCancelTransaction: boolean }>`
  ${({ theme, $isDisabledCancelTransaction }) => css`
    position: relative;
    td:first-of-type {
      padding-left: ${theme.tokens.alias.space['space-m-16']}px;
    }

    ${ItemAmount} {
      padding-right: ${theme.tokens.alias.space['space-m-16']}px;
    }

    ${!$isDisabledCancelTransaction &&
    css`
      &:hover {
        ${ItemAmount} {
          transform: translateX(-9.375rem);
          position: relative;
        }

        /* ${ItemStatus} {
        transform: translateX(-9.375rem);
        position: relative;
        width: fit-content;
      } */

        ${ItemButton} {
          display: inline-block;
        }
      }
    `}
  `}
`

export const ItemButton = styled.td`
  display: none;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
`

export const ItemAccent = styled.td`
  ${({ theme }) => css`
    ${ITEM_STYLES({ theme })}

    color: ${theme.tokens.colors.text['text-secondary-2']};
    width: ${ROWS_SIZE[0].width};
  `}
`

export const ItemAmount = styled.td`
  ${({ theme }) => css`
    ${ITEM_STYLES({ theme })}
    text-align: right;
    width: ${ROWS_SIZE[4].width};
  `}
`

export const ItemOperationType = styled(Item)`
  width: ${ROWS_SIZE[2].width};
`

export const ItemStatus = styled.td<{
  $type: TableRowStatusOperation
}>`
  ${({ theme, $type }) => css`
    ${ITEM_STYLES({ theme })};
    width: ${ROWS_SIZE[5].width};

    ${$type === TableRowStatusOperation.SUCCESS &&
    css`
      color: ${theme.tokens.colors.text['text-green']};
    `}

    ${$type === TableRowStatusOperation.IN_PROGRESS &&
    css`
      color: ${theme.tokens.colors.text['text-secondary-2']};
    `}

    ${$type === TableRowStatusOperation.DECLINED &&
    css`
      color: ${theme.tokens.colors.text['text-error']};
    `}

    ${$type === TableRowStatusOperation.CANCELED &&
    css`
      color: ${theme.tokens.colors.text['text-warning']};
    `}
    
    & button {
      display: none;
    }
  `}
`

export const StatusWrapper = styled.div`
  ${({ theme }) => css`
    column-gap: ${theme.tokens.alias.space['space-l-20']}px;
    width: 0;
  `}
`
