import React from 'react'

import { useInjectReducer, useInjectSaga } from 'shared/lib/@reduxjs'

import { LineTournaments } from '../ui/line-tournaments'

import { lineTournamentsContainerSaga } from './saga'
import { useLineTournamentsContainerData } from './hooks'
import { reducerLine, sliceKeyLine } from './slice'
import { LineTournamentsProps } from './types'

export function LineTournamentsContainer(_props: LineTournamentsProps) {
  useInjectReducer({ key: sliceKeyLine, reducer: reducerLine })
  useInjectSaga({ key: sliceKeyLine, saga: lineTournamentsContainerSaga })

  useLineTournamentsContainerData()

  return <LineTournaments />
}
