import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getSelectedPeriodOptionValue } from 'astra-core'
import { INITIAL_FILTER_PERIOD_ID } from 'astra-core/containers/LineProvider/slice'

import { ERoutes } from 'shared/types/routes'
import { getFilterPeriod } from 'pages/page-line-tournaments/LineTournamentsContainer/selectors'
import { OPTIONS_SELECT_PERIOD } from 'widgets/events-page-header/ui/select-filter-period/constants'
import { lineTournamentsContainerActions } from 'pages/page-line-tournaments/LineTournamentsContainer/slice'

export const useResetLineFilter = () => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const selectPeriodOptions = OPTIONS_SELECT_PERIOD()
  const filterPeriod = useSelector(getFilterPeriod)

  const selectedPeriodValue = useMemo(
    () =>
      filterPeriod
        ? getSelectedPeriodOptionValue(selectPeriodOptions, filterPeriod)
        : undefined,
    [filterPeriod, selectPeriodOptions]
  )

  const resetLineFilter = useCallback(() => {
    dispatch(lineTournamentsContainerActions.resetFilters())
  }, [dispatch])

  useEffect(() => {
    if (
      !pathname.includes(ERoutes.LineRedirect) &&
      selectedPeriodValue?.id !== INITIAL_FILTER_PERIOD_ID
    ) {
      resetLineFilter()
    }
  }, [pathname, selectedPeriodValue, resetLineFilter])

  return null
}
