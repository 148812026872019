import {
  ChangeEvent,
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState
} from 'react'
import debounce from 'lodash/debounce'
import { searchProviderActions } from 'astra-core/containers/SearchProvider'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { GlobalSearchValueContext } from './global-search-value-context'

interface Props {
  isOverlay?: boolean
}

export const GlobalSearchValueProvider: FC<PropsWithChildren<Props>> = ({
  children,
  isOverlay
}) => {
  const dispatch = useDispatch()
  const location = useLocation<{ search: string }>()
  const { search = '' } = location.state ?? {}

  const [searchValue, setSearchValue] = useState(!isOverlay ? search : '')

  useEffect(() => {
    if (search) {
      window.history.replaceState({}, '')
    }
  }, [search])

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
    debouncedChangeInputSearch(event.target.value)
  }

  const debouncedChangeInputSearch = useMemo(
    () =>
      debounce(
        (value) =>
          dispatch(searchProviderActions.setSearch({ valueSearch: value })),
        100
      ),
    [dispatch]
  )

  const setValue = (newValue: string) => {
    setSearchValue(newValue)
    dispatch(searchProviderActions.setSearch({ valueSearch: newValue }))
  }

  return (
    <GlobalSearchValueContext.Provider
      value={{
        value: searchValue,
        setValue,
        handleInputChange
      }}
    >
      {children}
    </GlobalSearchValueContext.Provider>
  )
}
