import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'
import { useTranslation } from 'react-i18next'
import { bonusOnExpressToPercent, formatAmountLocaleString } from 'astra-core'
import { useGetCurrencyIcon } from 'astra-core/hooks'
import { BetStatus, BetType } from 'betweb-openapi-axios'

import {
  BetsHistoryHeader,
  BetsHistoryOutcomesTable
} from 'features/bets-history-outcomes-table-legacy'
import { CellEventCashout } from 'widgets/bets-history-legacy/components/Cashout'
import { ExpressBonusModal } from 'features/express-bonus-modal/express-bonus-modal'
import { IconPolygonBgLeftPart } from 'shared/ui/Icon/General/IconPolygonBgLeftPart'
import { IconInfoCircleSFilled } from 'shared/ui/Icon/General/IconInfoCircleSFilled'
import { IconTicketNo } from 'shared/ui/Icon/General/IconTicketNo'
import { CardDataCoupon } from 'features/bet-history-card/bet-history-card.styled'

import {
  getBetStatusFromLegacy,
  getBetTypeFromLegacy
} from '../../entities/legacy-api/lib/utils'
import { getLegacyBetBonusAmountFormatted } from '../../entities/bet-history'
import { useBetsHistoryLegacyContext } from '../../entities/bet-history/lib/bet-history-legacy.context'

import { BetHistoryCardLegacyIcon } from './bet-history-card-legacy-icon'
import {
  getExpressSystemOutcomesCount,
  isBonusBalanceBet,
  isCouponBonusBet
} from './bet-history-utils-legacy'
import { BetHistoryTagLegacy } from './bet-history-tag-legacy'
import {
  BetHistoryCardProps,
  ECellEventTitle
} from './bet-history-card-legacy.types'
import {
  CardBonusIcon,
  CardDataBlockAmount,
  CardDataBlockBonus,
  CardDataBlockCashout,
  CardDataBlockName,
  CardDataBlockOdd,
  CardDataBlockTag,
  CardDataFixedContainer,
  CardDataLabel,
  CardDataValue,
  CardDate,
  CardMain,
  CardName,
  CardTable,
  CardWrapper,
  CollapseCardIcon,
  CollapsedWrapper,
  ExpressBonusContent,
  ExpressBonusLabel,
  ExpressBonusPercent,
  ExpressBonusPercentContent,
  MainInfoBlock,
  StyledBonusIconWrapper,
  WonAmount
} from './bet-history-card-legacy.styled'

export const BetHistoryCardLegacy: FC<BetHistoryCardProps> = ({ item }) => {
  const { t } = useTranslation()
  const cardInfoRef = useRef<HTMLDivElement>(null)
  const currencyIcon = useGetCurrencyIcon()

  const bonusAsPercent = bonusOnExpressToPercent(item.bonus_exp ?? 1)

  const betAmountFormatted = getLegacyBetBonusAmountFormatted({
    bet: item,
    currencyIcon
  })

  const outcomes = item.events.filter((e) => !!e.sport_id)

  const betCount =
    outcomes.length > 1
      ? `${getExpressSystemOutcomesCount(item)} ${t('events', {
          count: outcomes.length
        }).toLowerCase()}`
      : ''

  const status = getBetStatusFromLegacy(item)
  const betType = getBetTypeFromLegacy(item)
  const betName = ECellEventTitle[betType]
    ? `${t(ECellEventTitle[betType])} ${betCount}`
    : ECellEventTitle.default
  const betTitle = `#${item.bet_id} ${betName}`

  const hideBetAmount =
    !betAmountFormatted ||
    status === BetStatus.Current ||
    status === BetStatus.Lost ||
    status === BetStatus.Returned ||
    status === BetStatus.Sold

  const { allExpressSystemCollapsed } = useBetsHistoryLegacyContext()

  const [isExpressSystemCollapsed, setIsExpressSystemCollapsed] = useState(
    allExpressSystemCollapsed && betType !== BetType.Ordinar
  )

  const onClickRowCollapse = useCallback(() => {
    if (betType !== BetType.Ordinar) {
      setIsExpressSystemCollapsed((prev) => !prev)
    }
  }, [])

  useEffect(() => {
    if (betType !== BetType.Ordinar) {
      setIsExpressSystemCollapsed(allExpressSystemCollapsed)
    }
  }, [allExpressSystemCollapsed])

  const cardInfoEl = cardInfoRef.current
  const collapsedHeight = cardInfoEl
    ? `${cardInfoEl.offsetHeight + 8}px`
    : undefined

  const isBonusBalance = useMemo(() => isBonusBalanceBet(item), [item])

  return (
    <CollapsedWrapper
      collapsedHeight={collapsedHeight}
      isCollapsed={isExpressSystemCollapsed}
    >
      <CardWrapper>
        <CardMain ref={cardInfoRef} onClick={onClickRowCollapse}>
          <MainInfoBlock>
            <BetHistoryCardLegacyIcon betStatus={status} />
            <CardDataBlockName>
              <CardName>{betTitle}</CardName>
              <CardDate>
                {dayjs.unix(item.dttm).format('DD.MM.YY HH:mm')}
              </CardDate>
            </CardDataBlockName>
          </MainInfoBlock>

          <CardDataBlockCashout>
            <CellEventCashout bet={item} />
          </CardDataBlockCashout>

          <CardDataBlockAmount justifyContent="flex-end">
            <CardDataLabel>{t('bet amount')}</CardDataLabel>

            {isCouponBonusBet(item) ? (
              <CardDataCoupon>
                <IconTicketNo />
                {t('coupon applied')}{' '}
                {formatAmountLocaleString({
                  value: item.total_bet,
                  currency: currencyIcon
                })}
              </CardDataCoupon>
            ) : (
              <CardDataValue>
                {formatAmountLocaleString({
                  value: item.total_bet,
                  currency: isBonusBalance ? '' : currencyIcon
                })}
                {isBonusBalance ? <CardBonusIcon size={12} /> : null}
              </CardDataValue>
            )}
          </CardDataBlockAmount>

          <CardDataBlockOdd justifyContent="flex-end">
            {betType !== BetType.Ordinar ? (
              <>
                <CardDataLabel>{t('total odds')}</CardDataLabel>
                <CardDataValue>
                  {formatAmountLocaleString({
                    value: item.final_odd?.toFixed(2)
                  })}
                </CardDataValue>
              </>
            ) : null}
          </CardDataBlockOdd>

          <CardDataFixedContainer>
            {betType !== BetType.System || status !== BetStatus.Current ? (
              <>
                {betType === BetType.Express &&
                status !== BetStatus.Sold &&
                bonusAsPercent > 0 ? (
                  <CardDataBlockBonus>
                    <CardDataLabel>
                      <ExpressBonusLabel>
                        {t('bonus')}
                        <ExpressBonusModal>
                          <StyledBonusIconWrapper>
                            <IconInfoCircleSFilled />
                          </StyledBonusIconWrapper>
                        </ExpressBonusModal>
                      </ExpressBonusLabel>
                    </CardDataLabel>

                    <ExpressBonusContent>
                      <ExpressBonusPercent>
                        <IconPolygonBgLeftPart />
                        <ExpressBonusPercentContent>
                          {bonusAsPercent}%
                        </ExpressBonusPercentContent>
                        <IconPolygonBgLeftPart twist={180} />
                      </ExpressBonusPercent>
                    </ExpressBonusContent>
                  </CardDataBlockBonus>
                ) : null}
              </>
            ) : null}

            <CardDataBlockTag justifyContent="flex-end">
              <BetHistoryTagLegacy status={status} />
              {!hideBetAmount && <WonAmount>{betAmountFormatted}</WonAmount>}
            </CardDataBlockTag>

            <CollapseCardIcon
              size={16}
              twist={!isExpressSystemCollapsed ? 180 : 0}
            />
          </CardDataFixedContainer>
        </CardMain>

        <CardTable>
          <BetsHistoryHeader />

          <BetsHistoryOutcomesTable outcomes={outcomes} />
        </CardTable>
      </CardWrapper>
    </CollapsedWrapper>
  )
}
