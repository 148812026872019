import { FC, useCallback, useRef, useState } from 'react'
import { formatAmount, useGetCurrencyIcon } from 'astra-core'
import { Trans, useTranslation } from 'react-i18next'
import { AppliedCoupon } from 'betweb-openapi-axios'

import { IconTicketNo } from 'shared/ui/Icon/General/IconTicketNo'
import { Typography } from 'shared/ui/typography'
import { IconInfoCircleFilled } from 'shared/ui/Icon/General/IconInfoCircleFilled'
import { ModalRef } from 'shared/ui/modal/modal.types'
import { Modal } from 'shared/ui/modal'

import {
  StyledAvailableCouponHide,
  StyledAvailableCouponInfo,
  StyledAvailableCoupons,
  StyledAvailableCouponsOpen,
  StyledAvailableCouponsSingle,
  StyledAvailableCouponsWrapper,
  StyledBasketAvailableCouponWrapper,
  StyledBonusText,
  StyledCouponModalInner
} from './BasketAvailableCoupons.styled'
import { IBasketAvailableCouponProps } from './BasketAvailableCoupons.types'

export const BasketAvailableCoupons: FC<{ coupons: AppliedCoupon[] }> = ({
  coupons
}) => {
  const [expand, setExpand] = useState(false)
  const [t] = useTranslation()

  return (
    <StyledAvailableCoupons>
      <Typography color="text-secondary-2" font="Body / 12 Medium">
        {t('available coupons')}
      </Typography>

      {expand ? (
        <StyledAvailableCouponsWrapper>
          {coupons.map((coupon) => (
            <BasketAvailableCoupon {...coupon} />
          ))}
          <StyledAvailableCouponHide onClick={() => setExpand(false)}>
            {t('curtail')}
          </StyledAvailableCouponHide>
        </StyledAvailableCouponsWrapper>
      ) : (
        <StyledAvailableCouponsSingle>
          <BasketAvailableCoupon {...coupons[0]} />
          <StyledAvailableCouponsOpen onClick={() => setExpand(true)}>
            {t(coupons.length === 2 ? 'one more coupon' : 'few more coupons', {
              num: coupons.length - 1
            })}
          </StyledAvailableCouponsOpen>
        </StyledAvailableCouponsSingle>
      )}
    </StyledAvailableCoupons>
  )
}

const BasketAvailableCoupon: FC<IBasketAvailableCouponProps> = ({
  possibleAmount,
  coupon: { amount, description }
}) => {
  const [t] = useTranslation()
  const currencyIcon = useGetCurrencyIcon()

  const couponValue = formatAmount({
    value: possibleAmount ?? amount,
    currency: currencyIcon
  })

  const modalRef = useRef<ModalRef>(null)

  const handleOpenModal = useCallback(() => {
    modalRef.current?.open()
  }, [])

  return (
    <>
      <StyledBasketAvailableCouponWrapper>
        <IconTicketNo />

        <StyledBonusText>
          <Trans
            components={{
              bold: <Typography color="text-primary-2" font="Body / 12 Bold" />
            }}
            i18nKey="coupon for sum"
            values={{ sum: couponValue }}
          />
        </StyledBonusText>

        <StyledAvailableCouponInfo onClick={handleOpenModal}>
          <IconInfoCircleFilled colorToken="icon-primary-2" />
          <Typography color="text-primary-2" font="Body / 12 Bold">
            {t('conditions')}
          </Typography>
        </StyledAvailableCouponInfo>
      </StyledBasketAvailableCouponWrapper>

      <Modal ref={modalRef} title={t('conditions for receiving a bonus')}>
        <StyledCouponModalInner>
          <Typography
            dangerouslySetInnerHTML={{
              __html: description || ''
            }}
            color="text-primary-2"
            font="Body / 14 Medium"
          />
        </StyledCouponModalInner>
      </Modal>
    </>
  )
}
