import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ESportsCodes } from 'astra-core/containers/CommonDataProvider'
import { useTranslation } from 'react-i18next'
import { selectSportsCountersList } from 'astra-core/containers/SportCountersProvider'

import { SportIcon } from 'shared/ui/Icon/sport-icon'
import { RootState } from 'shared/types/store'
import { lineTournamentsContainerActions } from 'pages/page-line-tournaments/LineTournamentsContainer/slice'
import { liveContainerActions } from 'pages/page-live-events/LiveContainer/slice'
import { SHORTNAMED_SPORT_CODES } from 'shared/lib/locales/sports'
import { Skeleton } from 'shared/ui/skeleton'
import { Filters } from 'pages/page-line-tournaments/LineTournamentsContainer/types'

import { SportMenuListLoader } from './sport-menu-list.loader'
import {
  ISportsMenuListProps,
  SportsMenuItemProps
} from './sports-menu-list.types'
import {
  StyledSportsMenuItem,
  StyledSportsMenuItemCount,
  StyledSportsMenuItemText,
  StyledSportsMenuList
} from './sports-menu-list.styled'
import { getMenuTabSportsCounterType, isActiveSport } from './utils'

export const SportsMenuList: FC<ISportsMenuListProps> = ({
  currentTabMenu
}) => {
  const [hasDataLoadedOnce, setHasDataLoadedOnce] = useState(false)
  const { pathname } = useLocation()
  const sportsCountersListByTop = useSelector((state: RootState) =>
    selectSportsCountersList(state, getMenuTabSportsCounterType(currentTabMenu))
  )

  useEffect(() => {
    if (sportsCountersListByTop.length > 0) {
      setHasDataLoadedOnce(true)
    }
  }, [sportsCountersListByTop])

  const isLoading = !hasDataLoadedOnce && !sportsCountersListByTop.length

  return (
    <StyledSportsMenuList>
      <Skeleton isLoading={isLoading} styledFallback={<SportMenuListLoader />}>
        {sportsCountersListByTop.map((sport) => (
          <SportsMenuItem
            currentTabMenu={currentTabMenu}
            isActive={isActiveSport(pathname, currentTabMenu, sport.id)}
            key={sport.id}
            sport={sport}
          />
        ))}
      </Skeleton>
    </StyledSportsMenuList>
  )
}

const SportsMenuItem: FC<SportsMenuItemProps> = memo(
  ({ sport, currentTabMenu, isActive }) => {
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()

    const handleClick = useCallback(() => {
      dispatch(
        lineTournamentsContainerActions.resetFilters({
          excludedFilters: [Filters.IS_TOP, Filters.PERIOD]
        })
      )
      dispatch(liveContainerActions.resetLiveFilter({ isResetFilter: false }))
      dispatch(liveContainerActions.resetFilterIsBroadcast())
    }, [dispatch])

    const name =
      SHORTNAMED_SPORT_CODES[sport.code] &&
      i18n.exists(SHORTNAMED_SPORT_CODES[sport.code])
        ? t(SHORTNAMED_SPORT_CODES[sport.code])
        : sport.name

    return (
      <StyledSportsMenuItem
        $isActive={isActive}
        sport={sport.code as ESportsCodes}
        to={`/${currentTabMenu}/${sport.id}`}
        onClick={handleClick}
      >
        <SportIcon sport={sport.code as ESportsCodes} />
        <StyledSportsMenuItemText>{name}</StyledSportsMenuItemText>
        <StyledSportsMenuItemCount>{sport.count}</StyledSportsMenuItemCount>
      </StyledSportsMenuItem>
    )
  }
)
