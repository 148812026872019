/**
 * Create the store with dynamic reducers
 */

import { configureStore } from '@reduxjs/toolkit'
import { createInjectorsEnhancer, forceReducerReload } from 'redux-injectors'
import createSagaMiddleware from 'redux-saga'

import { RootState } from 'shared/types/store'
import { baseLegacyApi, basePromoAPi } from 'entities/legacy-api/lib/api'

import { createReducer } from './reducers'

type ConfigureAppStoreParams = {
  preloadedState?: RootState
}

export function configureAppStore({ preloadedState }: ConfigureAppStoreParams) {
  const reduxSagaMonitorOptions = {}
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions)
  const { run: runSaga } = sagaMiddleware

  // Create the store with saga middleware
  const middlewares = [
    sagaMiddleware,
    baseLegacyApi.middleware,
    basePromoAPi.middleware
  ]

  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga
    })
  ]

  const store = configureStore({
    reducer: createReducer(),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false
      }).concat(...middlewares),
    devTools: process.env.NODE_ENV !== 'production' && {
      trace: true,
      traceLimit: 25
    },
    enhancers,
    preloadedState: preloadedState ?? undefined
  })

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      forceReducerReload(store)
    })
  }

  return store
}

export const serverStore = configureAppStore({})
