import { useCallback, useMemo } from 'react'
import debounce from 'lodash/debounce'
import { searchProviderActions } from 'astra-core/containers/SearchProvider'
import { useDispatch } from 'react-redux'

export const useSearchMarketsOnChangeInput = (
  eventId: Parameters<typeof searchProviderActions.setSearchQuery>[0]['eventId']
) => {
  const dispatch = useDispatch()

  const onChangeInputDebounceHandler = useMemo(
    () =>
      debounce(
        (value) =>
          dispatch(
            searchProviderActions.setSearchQuery({
              eventId,
              value
            })
          ),
        10
      ),
    [dispatch, eventId]
  )

  const onChangeInputDebounce = useCallback(
    (event) => onChangeInputDebounceHandler(event.target.value),
    [onChangeInputDebounceHandler]
  )

  return { onChangeInputDebounce }
}
