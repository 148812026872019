import { BetOutcomeWithPlayerName } from 'astra-core/utils/bets/types'
import {
  BetStatus,
  BetType,
  VirtualGameOutcome,
  VirtualGameVendor
} from 'betweb-openapi-axios'

export enum ECellEventTitle {
  ORDINAR = 'ordinar',
  EXPRESS = 'express',
  SYSTEM = 'system',
  default = 'none'
}

export interface BetHistoryItem {
  bet: number
  amount: number
  outcomeBonusIcon: string
  resultText: import('betweb-openapi-axios').BetStatus
  eventAdditional: unknown
  iconWrap: string
  coefficient: number | undefined
  outcomes: BetOutcomeWithPlayerName[]
  id: string
  clientId: string
  accountId: string
  source: number
  currencyId: number
  status: BetStatus
  betType: BetType
  virtualGameOutcomes: VirtualGameOutcome[]
  gameVendor?: VirtualGameVendor | undefined
  systemSize?: number | undefined
  timestamp: string
  bonusWallet: boolean
  updatedByOperator?: boolean | undefined
  appliedCouponId?: string | undefined
  coefMultiplier?: number
}

export interface BetHistoryCardProps {
  item: BetHistoryItem
  isLastElement?: boolean
}
