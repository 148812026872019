import { useSelector } from 'react-redux'

import { getEntityTagName, selectEntityTags } from 'entities/event'

export const useGetEntityTagName = (
  props: Pick<Parameters<typeof getEntityTagName>[0], 'tags'>
) => {
  const entityTags = useSelector(selectEntityTags)

  return getEntityTagName(props, entityTags)
}
