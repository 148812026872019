import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useMemo } from 'react'
import { getSelectedPeriodOptionValue } from 'astra-core'
import { OptionType } from 'astra-core/utils/line/types'

import { lineTournamentsContainerActions } from 'pages/page-line-tournaments/LineTournamentsContainer/slice'
import { getFilterPeriod } from 'pages/page-line-tournaments/LineTournamentsContainer/selectors'
import { IconTimeSelect } from 'shared/ui/Icon/General/icon-time-select'

import { OPTIONS_SELECT_PERIOD, getScheduleTime } from './constants'
import { StyledSelectFilterPeriod } from './select-filter-period.styled'

export const SelectFilterPeriod = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const filterPeriod = useSelector(getFilterPeriod)
  const selectPeriodOptions = OPTIONS_SELECT_PERIOD()

  const selectedPeriodValue = useMemo(
    () =>
      filterPeriod
        ? getSelectedPeriodOptionValue(selectPeriodOptions, filterPeriod)
        : undefined,
    [filterPeriod, selectPeriodOptions]
  )

  const selectPeriodHandle = useCallback(
    (option: OptionType) => {
      if (selectedPeriodValue?.id !== option.id) {
        dispatch(
          lineTournamentsContainerActions.setFilterPeriod({
            ...getScheduleTime(option)
          })
        )
      }
    },
    [dispatch, selectedPeriodValue]
  )

  if (!selectedPeriodValue) {
    return null
  }

  return (
    <StyledSelectFilterPeriod
      leftIcon={<IconTimeSelect colorToken="icon-secondary-2" />}
      options={selectPeriodOptions}
      placeholder={t('select')}
      value={selectedPeriodValue}
      onChange={selectPeriodHandle}
    />
  )
}
