import React from 'react'
import { sportCountersProviderActions } from 'astra-core/containers/SportCountersProvider'
import { useParams } from 'react-router-dom'

import { getRouteParamAtIndex } from 'pages/lib/getRouteParamAtIndex'
import { useRedirectToSport } from 'hooks/useRedirectToSport'
import { ESportsMenuTabTypes } from 'widgets/sports-menu'
import { LayoutEventsTable } from 'layouts/events-table'
import { LayoutPrimary } from 'layouts/primary'
import { lineTournamentsContainerActions } from 'pages/page-line-tournaments/LineTournamentsContainer/slice'
import { getFetchLineTournamentsReqData } from 'pages/page-line-tournaments/LineTournamentsContainer/utils'

import { liveContainerActions } from './LiveContainer/slice'
import { getFetchLiveReqData } from './LiveContainer/utils'
import { LiveEventsParams } from './LiveContainer/types'
import { LiveContainer } from './LiveContainer'

export const PageLiveEvents = () => {
  const { sportId } = useParams<LiveEventsParams>()

  useRedirectToSport({
    sportId: +sportId,
    currentTabMenu: ESportsMenuTabTypes.LIVE
  })

  return (
    <LayoutPrimary>
      <LayoutEventsTable>
        <LiveContainer />
      </LayoutEventsTable>
    </LayoutPrimary>
  )
}

export const pageLiveEventsPrefetch = ({ store, req }) => {
  const sportId = getRouteParamAtIndex(req.params, 1) as string

  if (sportId) {
    store.dispatch(
      liveContainerActions.fetchLive(
        getFetchLiveReqData({
          sportId
        })
      )
    )

    // TODO remove after tags will be added to GET /events
    store.dispatch(
      lineTournamentsContainerActions.fetchLineTournaments(
        getFetchLineTournamentsReqData({ sportId })
      )
    )
  }
  store.dispatch(sportCountersProviderActions.fetchLiveSportCounters({}))
}

export default PageLiveEvents
