import React from 'react'
import { useSelector } from 'react-redux'

import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import { useInjectReducer, useInjectSaga } from 'shared/lib/@reduxjs'
import { LiveTable } from 'pages/page-live-events/ui/live'
import { useLineTournamentsContainerData } from 'pages/page-line-tournaments/LineTournamentsContainer/hooks'
import {
  reducerLine,
  sliceKeyLine
} from 'pages/page-line-tournaments/LineTournamentsContainer/slice'
import { lineTournamentsContainerSaga } from 'pages/page-line-tournaments/LineTournamentsContainer/saga'

import { selectLiveIsEmpty } from './selectors'
import { useLiveContainerData } from './hooks'
import { reducerLive, sliceKeyLive } from './slice'
import { liveContainerSaga } from './saga'
import { LiveProps } from './types'

export function LiveContainer(_props: LiveProps) {
  useInjectReducer({ key: sliceKeyLive, reducer: reducerLive })
  useInjectSaga({ key: sliceKeyLive, saga: liveContainerSaga })

  // TODO remove after tags will be added to GET /events
  useInjectReducer({ key: sliceKeyLine, reducer: reducerLine })
  useInjectSaga({ key: sliceKeyLine, saga: lineTournamentsContainerSaga })
  const isEmpty = useSelector(selectLiveIsEmpty)

  useLiveContainerData()
  useLineTournamentsContainerData()

  return isEmpty ? <LoaderSpinner /> : <LiveTable />
}
