import { useDispatch, useSelector } from 'react-redux'
import React, { useCallback, useState } from 'react'
import {
  searchProviderActions,
  selectFetchItemsLoading,
  selectInputSearch
} from 'astra-core/containers/SearchProvider'

import {
  GlobalSearchContext,
  GlobalSearchInput,
  GlobalSearchLoader,
  GlobalSearchValueProvider,
  PopularQueries,
  RecentSearch,
  SearchItems,
  ValueWarning
} from 'features/global-search'
import {
  GlobalSearchBodyWrapper,
  OverlaySearchWrapper
} from 'widgets/global-search/ui/overlay/overlay.styled'
import { useClickOutside } from 'shared/lib/click-outside/click-outside'

export const GlobalSearchOverlay = () => {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const [isFocused, setIsFocused] = useState(false)

  const handleCloseSearch = useCallback(() => {
    setIsOpen((prevValue) => {
      if (prevValue) {
        dispatch(searchProviderActions.setSearch({ valueSearch: '' }))
        return false
      }
      return prevValue
    })
  }, [dispatch])

  const ref = useClickOutside<HTMLDivElement>(handleCloseSearch)

  return (
    <GlobalSearchContext.Provider
      value={{ isOpen, setIsOpen, isFocused, setIsFocused }}
    >
      <GlobalSearchValueProvider isOverlay>
        <OverlaySearchWrapper ref={ref}>
          <GlobalSearchInput isOverlaySearch />
          {isOpen && (
            <GlobalSearchBodyWrapper>
              <GlobalSearchOverlayBody />
            </GlobalSearchBodyWrapper>
          )}
        </OverlaySearchWrapper>
      </GlobalSearchValueProvider>
    </GlobalSearchContext.Provider>
  )
}

const GlobalSearchOverlayBody = () => {
  const valueSearch = useSelector(selectInputSearch) || ''
  const loading = useSelector(selectFetchItemsLoading)
  const showLengthWarning =
    valueSearch && valueSearch.length > 0 && valueSearch.length < 3

  if (!valueSearch || showLengthWarning) {
    return (
      <div>
        {showLengthWarning && <ValueWarning />}
        <RecentSearch />
        <PopularQueries />
      </div>
    )
  }

  if (loading) {
    return (
      <div style={{ overflow: 'hidden' }}>
        <GlobalSearchLoader />
        <GlobalSearchLoader count={4} />
        <GlobalSearchLoader count={5} />
      </div>
    )
  }

  return <SearchItems />
}
