import { useToggleEventFavourite } from 'astra-core'
import { selectAccountUser } from 'astra-core/containers/AccountProvider'
import { FC, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { IconFavoritesFilled12 } from 'shared/ui/Icon/General/IconFavoritesFilled12'
import { IconFavoritesFilled16 } from 'shared/ui/Icon/General/IconFavoritesFilled16'

import { StyledWrapperIconStar } from './FavoriteButton.styled'

export const FavoriteButton: FC<{ eventId: number; size?: 12 | 16 }> = ({
  eventId,
  size = 12
}) => {
  const user = useSelector(selectAccountUser)

  const { isEventFavourite, toggleEventFavourite } =
    useToggleEventFavourite(eventId)

  const handleClickFavorite = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      e.preventDefault()

      if (user) {
        toggleEventFavourite()
      }
    },
    [toggleEventFavourite, user]
  )

  return (
    <>
      {!!user && (
        <StyledWrapperIconStar
          isActive={isEventFavourite}
          onClick={handleClickFavorite}
        >
          {size === 12 && <IconFavoritesFilled12 size={12} />}
          {size === 16 && <IconFavoritesFilled16 />}
        </StyledWrapperIconStar>
      )}
    </>
  )
}
