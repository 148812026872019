import { useSelector } from 'react-redux'
import { Event } from 'betweb-openapi-axios'

import { selectEntityTags } from '../../../model'
import { isEventTopOrSuperTop } from '../is-event-top-or-super-top'

export const useIsEventListHasTop = (
  eventList: Array<
    Pick<Event, 'tags'> & {
      // Events can be pass as Event or Tournament
      [key: string]: any
    }
  >
) => {
  const entityTags = useSelector(selectEntityTags)

  const isEventListHasTop = eventList.some((event) =>
    isEventTopOrSuperTop({
      eventTags: event.tags,
      allEntityTags: entityTags
    })
  )

  return { isEventListHasTop }
}
