import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'
import {
  bonusOnExpressToPercent,
  formatAmountLocaleString,
  getBetOutcomeId,
  useGetCurrencyIcon
} from 'astra-core'
import { BetStatus, BetType } from 'betweb-openapi-axios'

import {
  BetHistoryCardProps,
  ECellEventTitle
} from 'features/bet-history-card-legacy/bet-history-card-legacy.types'
import { BasketTooltipProvider } from 'shared/ui/Tooltip/basket-tooltip'
import { Typography } from 'shared/ui/typography'
import { ExpressBonusModal } from 'features/express-bonus-modal/express-bonus-modal'
import { IconInfoCircleFilled } from 'shared/ui/Icon/General/IconInfoCircleFilled'
import { getLegacyBetBonusAmountFormatted } from 'entities/bet-history'
import {
  getBetStatusFromLegacy,
  getBetTypeFromLegacy
} from 'entities/legacy-api/lib/utils'
import { getExpressSystemOutcomesCount } from 'features/bet-history-card-legacy/bet-history-utils-legacy'

import { BasketHistoryOutcome } from '../BasketHistoryOutcome'

import {
  StyledBasketHistoryBonusWrapper,
  StyledBasketHistoryItem,
  StyledBasketHistoryItemBottom,
  StyledBasketHistoryItemMain,
  StyledBasketHistoryItemRow,
  StyledBasketHistoryItemWrapper,
  StyledBasketHistoryOutcomes,
  StyledBasketHistoryTextSecondary,
  StyledBetHistoryTag,
  StyledCashoutCell
} from './BasketHistoryCard.styled'

export const BasketHistoryCard: FC<BetHistoryCardProps> = ({ item }) => {
  const { t } = useTranslation()
  const currencyIcon = useGetCurrencyIcon()

  const [showOutcomes, setShowOutcomes] = useState(false)

  const betAmountFormatted = getLegacyBetBonusAmountFormatted({
    bet: item,
    currencyIcon
  })

  const handleToggle = useCallback(() => setShowOutcomes((prev) => !prev), [])

  const status = getBetStatusFromLegacy(item)
  const betType = getBetTypeFromLegacy(item)

  const outcomes = item.events.filter((e) => !!e.sport_id)
  const bonusAsPercent = bonusOnExpressToPercent(item.bonus_exp ?? 1)

  const hideBetAmount =
    !betAmountFormatted ||
    status === BetStatus.Current ||
    status === BetStatus.Lost ||
    status === BetStatus.Returned ||
    status === BetStatus.Sold
  const betCount =
    outcomes.length > 1
      ? `${getExpressSystemOutcomesCount(item)} ${t('events', {
          count: outcomes.length
        }).toLowerCase()}`
      : ''

  return (
    <StyledBasketHistoryItem>
      <StyledBasketHistoryItemWrapper>
        <StyledBasketHistoryItemMain onClick={handleToggle}>
          <StyledBasketHistoryItemRow>
            <Typography color="text-primary" font="Body / 12 SemiBold">
              {ECellEventTitle[betType]
                ? `${t(ECellEventTitle[betType])} ${betCount}`
                : ECellEventTitle.default}
            </Typography>
            <StyledBasketHistoryTextSecondary>
              {dayjs.unix(item.dttm).format('DD.MM.YY HH:mm')}
            </StyledBasketHistoryTextSecondary>
          </StyledBasketHistoryItemRow>
          <StyledBasketHistoryItemRow>
            <StyledBasketHistoryTextSecondary>
              {t('overall coefficient')}
            </StyledBasketHistoryTextSecondary>
            <Typography color="text-primary" font="Body / 12 SemiBold">
              {formatAmountLocaleString({
                value: item.final_odd?.toFixed(2)
              })}
            </Typography>
          </StyledBasketHistoryItemRow>
          <StyledBasketHistoryItemRow>
            <StyledBasketHistoryTextSecondary>
              {t('bets amount')}
            </StyledBasketHistoryTextSecondary>
            <StyledBasketHistoryTextSecondary>
              {formatAmountLocaleString({
                value: item.total_bet,
                currency: currencyIcon
              })}
            </StyledBasketHistoryTextSecondary>
          </StyledBasketHistoryItemRow>

          {/* {couponLoyalty?.coupon.amount && ( */}
          {/*  <StyledBasketHistoryItemRow> */}
          {/*    <StyledBasketHistoryTextCoupon> */}
          {/*      <IconTicketNo /> */}
          {/*      {t('coupon applied')} */}
          {/*    </StyledBasketHistoryTextCoupon> */}
          {/*    <StyledBasketHistorySumCoupon> */}
          {/*      {formatAmountLocaleString({ */}
          {/*        value: couponLoyalty?.coupon.amount, */}
          {/*        currency: currencyIcon */}
          {/*      })} */}
          {/*    </StyledBasketHistorySumCoupon> */}
          {/*  </StyledBasketHistoryItemRow> */}
          {/* )} */}

          {betType === BetType.Express &&
            status !== BetStatus.Sold &&
            bonusAsPercent > 0 && (
              <StyledBasketHistoryItemRow>
                <StyledBasketHistoryBonusWrapper>
                  <StyledBasketHistoryTextSecondary>
                    {t('bonus')}
                    <ExpressBonusModal>
                      <IconInfoCircleFilled size={16} />
                    </ExpressBonusModal>
                  </StyledBasketHistoryTextSecondary>
                </StyledBasketHistoryBonusWrapper>
                <Typography color="text-warning" font="Body / 12 Medium">
                  {bonusAsPercent}%
                </Typography>
              </StyledBasketHistoryItemRow>
            )}

          <StyledBasketHistoryItemRow>
            <StyledBetHistoryTag status={status} />
            {!hideBetAmount ? (
              <Typography color="text-primary" font="Body / 12 SemiBold">
                {betAmountFormatted}
              </Typography>
            ) : null}
          </StyledBasketHistoryItemRow>

          <StyledCashoutCell bet={item} />
          {/* <BasketHistoryCashout */}
          {/*  id={item.bet_id} */}
          {/*  isLastElement={isLastElement} */}
          {/* /> */}
        </StyledBasketHistoryItemMain>

        {showOutcomes && (
          <StyledBasketHistoryOutcomes>
            <BasketTooltipProvider>
              {outcomes.map((outcome) => {
                const { matchid, eventid } = outcome

                const eventProbability = {
                  eventId: matchid,
                  outcomeTypeId: eventid
                }
                return (
                  <BasketHistoryOutcome
                    key={getBetOutcomeId(eventProbability)}
                    outcome={outcome}
                  />
                )
              })}
            </BasketTooltipProvider>
          </StyledBasketHistoryOutcomes>
        )}
      </StyledBasketHistoryItemWrapper>
      {!showOutcomes && <StyledBasketHistoryItemBottom />}
    </StyledBasketHistoryItem>
  )
}
