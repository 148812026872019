import styled, { css } from 'styled-components'

import { Button } from 'shared/ui/Button'

export const StyledBasketBottomWrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-page']};
    bottom: 0px;
    box-shadow: inset 1px 0px
      ${({ theme }) => theme.tokens.colors.border['border-hover']};

    display: flex;
    flex-direction: column;
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
    padding: ${theme.tokens.alias.space['space-xxxs-4']}px
      ${theme.tokens.alias.space['space-xs-8']}px
      ${theme.tokens.alias.space['space-xs-8']}px;

    position: sticky;
  `}
`

export const StyledButtonWrapper = styled(Button)<{
  isBonus: boolean
  isCoupon: boolean
}>`
  justify-content: center;
  width: 100%;

  ${({ isBonus, theme, isCoupon }) => css`
    ${isBonus &&
    css`
      background: ${theme.tokens.colors.icon['icon-warning']} !important;
      color: #664d00 !important;
    `}

    ${isCoupon &&
    css`
      background: #7845e7 !important;
      color: ${theme.tokens.colors.text['text-invert']} !important;

      &:disabled {
        background: #c9b4f6 !important;
      }
    `}
  `}
`

export const StyledBonusContainer = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-modal']};
    outline: 1px solid ${theme.tokens.colors.divider['divider-primary']};
    outline-offset: -1px;
  `}
`

export const StyledDividerLine = styled.div`
  height: 1px;
  width: 100%;

  ${({ theme }) => css`
    background: ${theme.tokens.colors.divider['divider-primary']};
  `}
`
