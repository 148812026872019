import { FC, MouseEvent, useCallback } from 'react'
import { selectOutcomeTypes } from 'astra-core/containers/CommonDataProvider'
import { useSelector } from 'react-redux'

import { useEventMode } from 'entities/event-probability/lib/odd-mode'
import {
  PROBABILITY_LABEL_TEXT,
  PROBABILITY_ODD_TEXT
} from 'features/probability-cell/lib/probability-cell-style'
import { OddModes } from 'entities/event-probability/model/odd-mode'
import { useHandleAddCoefficientToBasket } from 'entities/event-probability'

import * as S from './probability-cell.styled'
import {
  ProbabilityCellInternalProps,
  ProbabilityCellProps
} from './probability-cell.types'

export const ProbabilityCell: FC<ProbabilityCellProps> = ({
  eventProbability,
  event,
  isSuspended
}) => {
  const outcomeTypes = useSelector(selectOutcomeTypes)
  const handleAddOutcomeToBasket = useHandleAddCoefficientToBasket({
    eventProbability,
    eventStatus: event.status,
    eventId: event.id
  })
  const { mode, isInBasket } = useEventMode({
    eventProbability,
    eventId: event.id
  })

  const handleWrapperClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation()
      handleAddOutcomeToBasket()
    },
    [handleAddOutcomeToBasket]
  )

  return (
    <ProbabilityCellInternal
      code={outcomeTypes[eventProbability.outcomeTypeId]?.shortName}
      isInBasket={isInBasket}
      isSuspended={isSuspended}
      mode={mode}
      odd={eventProbability.odd}
      onClick={handleWrapperClick}
    />
  )
}

export const ProbabilityCellInternal: FC<ProbabilityCellInternalProps> = ({
  mode,
  isInBasket,
  onClick,
  code,
  odd,
  isSuspended = false
}) => {
  const oddValue = odd ? odd.toFixed(2) : '–'

  return (
    <S.ProbabilityCellWrapper
      isEmpty={!odd || isSuspended}
      isInBasket={isInBasket}
      mode={mode}
      onClick={onClick}
    >
      <S.OutcomeCode className={PROBABILITY_LABEL_TEXT}>{code}</S.OutcomeCode>
      <S.ProbabilityOdd className={PROBABILITY_ODD_TEXT}>
        {oddValue}
      </S.ProbabilityOdd>
      {mode === OddModes.Increase && <S.IncreaseIcon size={8} />}
      {mode === OddModes.Decrease && <S.DecreaseIcon size={8} />}
    </S.ProbabilityCellWrapper>
  )
}
