import { FC, useContext, useEffect, useMemo } from 'react'
import { LogoEntityType } from 'betweb-openapi-axios'
import { useDispatch, useSelector } from 'react-redux'
import { selectEventStatistics } from 'astra-core/containers/EventsProvider'
import { useTranslation } from 'react-i18next'
import useMeasure from 'react-use-measure'
import { selectSport } from 'astra-core/containers/CommonDataProvider'

import { EventContext, IEventContext } from 'widgets/Event/Event.context'
import { EntityLogo } from 'features/entity-logo'
import { getLogos } from 'entities/logos/actions'
import { Sets } from 'features/event-sets'
import { RootState } from 'shared/types/store'

import {
  SportStatistics,
  sportStatisticsMap,
  useEventBannerData,
  useEventBannerLiveStatistics
} from '../../libs'
import { LiveIndicator } from '../LiveIndicator'

import {
  CompetitorName,
  StyledBannerContentLive,
  StyledBannerContentColumn,
  StyledBannerTeamLive,
  StyledBannerTeamLiveWrapper,
  StyledBannerCompact,
  StyledBannerCompactTeams,
  StyledBannerCompactScore,
  StyledBannerCompactStats,
  StyledStatistics,
  StyledStatisticsName,
  StyledStatisticsValue,
  StyledStatiscticsItem
} from './BannerContent.styled'

export const BannerLiveContent: FC<{
  setMaxHeight: (height: number) => void
}> = ({ setMaxHeight }) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const { event } = useContext(EventContext) as IEventContext
  useEventBannerLiveStatistics()
  const { competitors } = useEventBannerData()
  const eventStatistics = useSelector((state: RootState) =>
    selectEventStatistics(state, event.id)
  )

  const sport = useSelector((state: RootState) =>
    selectSport(state, event.tournament.sportId)
  )

  useEffect(() => {
    dispatch(
      getLogos({
        entityType: LogoEntityType.Competitor,
        ids: event.competitors.map((c) => c.id)
      })
    )
  }, [dispatch, event.competitors])

  const [ref, { height }] = useMeasure()

  useEffect(() => {
    setMaxHeight(height)
  }, [height, setMaxHeight])

  const sportStatisticsArray = useMemo(() => {
    const statisctics: { text: string; away: number; home: number }[] = []

    sportStatisticsMap[sport?.code]?.forEach(
      ({ text, field }: SportStatistics) => {
        const home = eventStatistics?.homeStatistics?.[field]
        const away = eventStatistics?.awayStatistics?.[field]

        if (Number.isFinite(home) && Number.isFinite(away)) {
          statisctics.push({ text, away, home })
        }
      }
    )

    return statisctics
  }, [sport?.code, eventStatistics])

  return (
    <>
      <StyledBannerCompact>
        <StyledBannerCompactTeams>
          {competitors.homeCompetitors[0]?.name} -{' '}
          {competitors.awayCompetitors[0]?.name}
        </StyledBannerCompactTeams>

        <StyledBannerCompactStats>
          <StyledBannerCompactScore>
            {eventStatistics?.homeStatistics?.score}:
            {eventStatistics?.awayStatistics?.score}
          </StyledBannerCompactScore>
          <LiveIndicator />
        </StyledBannerCompactStats>
      </StyledBannerCompact>

      <StyledBannerContentLive ref={ref}>
        <StyledBannerContentColumn>
          <LiveIndicator />
          <StyledBannerTeamLiveWrapper>
            <StyledBannerTeamLive>
              {competitors.homeCompetitors[0] && (
                <EntityLogo
                  entityId={competitors.homeCompetitors[0]?.id}
                  entityType={LogoEntityType.Competitor}
                  size={32}
                />
              )}
              <CompetitorName>
                {competitors.homeCompetitors[0]?.name}
              </CompetitorName>
            </StyledBannerTeamLive>

            <StyledBannerTeamLive>
              {competitors.awayCompetitors[0] && (
                <EntityLogo
                  entityId={competitors.awayCompetitors[0]?.id}
                  entityType={LogoEntityType.Competitor}
                  size={32}
                />
              )}
              <CompetitorName>
                {competitors.awayCompetitors[0]?.name}
              </CompetitorName>
            </StyledBannerTeamLive>
          </StyledBannerTeamLiveWrapper>
        </StyledBannerContentColumn>

        {sportStatisticsMap[sport?.code] && (
          <StyledStatistics>
            {/* <StyledStatisticsList>
              {sportStatisticsMap[sport?.code].map((item: SportStatistics) => (
                <StyledStatisticsName key={item.text}>
                  {t(item.text)}
                </StyledStatisticsName>
              ))}
            </StyledStatisticsList>

            <StyledStatisticsList>
              {sportStatisticsMap[sport?.code].map((item: SportStatistics) => (
                <StyledStatisticsValue key={item.text}>
                  {eventStatistics?.homeStatistics?.[item.field] || 0}
                </StyledStatisticsValue>
              ))}
            </StyledStatisticsList>

            <StyledStatisticsList>
              {sportStatisticsMap[sport?.code].map((item: SportStatistics) => (
                <StyledStatisticsValue key={item.text}>
                  {eventStatistics?.awayStatistics?.[item.field] || 0}
                </StyledStatisticsValue>
              ))}
            </StyledStatisticsList> */}

            {sportStatisticsArray.map(({ text, home, away }) => (
              <StyledStatiscticsItem>
                <StyledStatisticsName>{t(text)}</StyledStatisticsName>
                <StyledStatisticsValue>{home}</StyledStatisticsValue>
                <StyledStatisticsValue>{away}</StyledStatisticsValue>
              </StyledStatiscticsItem>
            ))}
          </StyledStatistics>
        )}

        <Sets />
      </StyledBannerContentLive>
    </>
  )
}
