import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const LiveContentScoreCell = styled.td`
  padding-right: 8px;
  white-space: nowrap;
`

export const LiveContentStatsCell = styled.td`
  min-width: 116px;
  padding-right: 8px;
  white-space: nowrap;
`

export const ScoresStatistics = styled.div`
  align-items: center;
  display: flex;
  gap: ${({ theme }) => theme.tokens.alias.space['space-xxxxs-2']}px;
  justify-content: flex-end;
`

export const EventScore = styled.div`
  ${typography['Body / 12 Bold']};
  display: flex;
  flex-direction: column;
  text-align: center;
  ${({ theme }) => css`
    color: ${theme.tokens.colors.icon['icon-primary']};
    padding: 0 ${theme.tokens.alias.space['space-xxxxs-2']}px;
  `}
`

export const EventScoreByPeriods = styled.div`
  display: flex;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xxxxs-2']}px;
  `}
`

export const EventScoreByPeriodsColumn = styled.div`
  ${typography['Body / 12 Regular']};
  display: flex;
  flex-direction: column;
  text-align: center;
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary-2']};
    padding: 0 ${theme.tokens.alias.space['space-xxxxs-2']}px;
  `}
`

export const TimeStatsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
  `}
`

export const TimeWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: ${(p) => p.theme.tokens.alias.space['space-xxxxs-2']}px;
`

export const AdditionalBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
  `}
`

export const IconsWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: end;
`

export const StyledIconVideoFilledWrapper = styled.div<{ isActive: boolean }>`
  ${({ theme, isActive }) => css`
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;

    & svg {
      color: ${isActive
        ? `${theme.tokens.colors.icon['icon-negative']} !important`
        : theme.tokens.colors.icon['icon-secondary-3']};
      transition: all 0.3s;
    }

    &:hover {
      & svg {
        color: ${isActive
          ? `${theme.tokens.colors.icon['icon-negativeDarker']} !important`
          : theme.tokens.colors.icon['icon-secondary']};
      }
    }
  `}
`
