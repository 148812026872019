import { useSelector } from 'react-redux'
import { selectConfig } from 'astra-core/containers/ConfigProvider'

import { BalanceOperationsTypes } from '../../model'

export const useGetIframeUrl = (
  paymentSystem: string,
  balanceOperationType: BalanceOperationsTypes
) => {
  const { API_URL } = useSelector(selectConfig)

  return paymentSystem
    ? `${API_URL}/kzapi/pay/${
        balanceOperationType === BalanceOperationsTypes.TOP_UP ? 'in' : 'out'
      }/form?pay-sys=${paymentSystem}&session=__session__&platforma=NEWOLIMPBET`
    : ''
}
