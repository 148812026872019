import { useSelector } from 'react-redux'
import {
  selectHotProbabilitiesGroups,
  selectHotProbabilitiesGroupsSports
} from 'astra-core/containers/CommonDataProvider'
import { useRouteMatch } from 'react-router-dom'

import { useIsSmall } from 'shared/lib/styled'
import { ERoutes } from 'shared/types/routes'

import {
  HOT_PROBS_MARKETS_ROW_MAP,
  HOT_PROBS_MARKETS_SMALL_ROW_MAP
} from './row'
import {
  HotProbabilityMarketHeaderConfig,
  HotProbabilityMarketRowConfig
} from './types'
import {
  HOT_PROBS_MARKETS_HEADER_MAP,
  HOT_PROBS_MARKETS_SMALL_HEADER_MAP
} from './header'

export * from './types'

const DEFAULT_GROUP_ID = 1

interface Props {
  sportId: number | string | undefined
}

interface RowResult {
  rowConfig: HotProbabilityMarketRowConfig[]
  groupId: number
}

interface HeaderResult {
  headerConfig: HotProbabilityMarketHeaderConfig[]
  groupId: number
}

export const useHotProbsMarketRowConfig = ({ sportId }: Props): RowResult => {
  const sportsGroups = useSelector(selectHotProbabilitiesGroupsSports)
  const groups = useSelector(selectHotProbabilitiesGroups)
  const isSmall = useIsSmall()

  const isCommonConfig = useRouteMatch(ERoutes.FavouriteEvents)

  if (!groups || !sportsGroups)
    return {
      rowConfig: [],
      groupId: 0
    }

  const rowMap = isSmall
    ? HOT_PROBS_MARKETS_SMALL_ROW_MAP
    : HOT_PROBS_MARKETS_ROW_MAP

  const { groupId } = sportsGroups.find(
    (item) => item.sportId === (isCommonConfig ? 100 : sportId) && item.desktop
  ) || { groupId: DEFAULT_GROUP_ID }

  const groupMarkets = groups[groupId]?.markets

  const rowConfig = groupMarkets?.map((name) => rowMap[name]) ?? []

  return { rowConfig, groupId }
}

export const useHotProbsMarketHeaderConfig = ({
  sportId
}: Props): HeaderResult => {
  const sportsGroups = useSelector(selectHotProbabilitiesGroupsSports)
  const groups = useSelector(selectHotProbabilitiesGroups)
  const isSmall = useIsSmall()

  const isCommonConfig = useRouteMatch(ERoutes.FavouriteEvents)

  if (!groups || !sportsGroups)
    return {
      headerConfig: [],
      groupId: 0
    }

  const headerMap = isSmall
    ? HOT_PROBS_MARKETS_SMALL_HEADER_MAP
    : HOT_PROBS_MARKETS_HEADER_MAP

  const { groupId } = sportsGroups.find(
    (item) => item.sportId === (isCommonConfig ? 100 : sportId) && item.desktop
  ) || { groupId: DEFAULT_GROUP_ID }

  const groupMarkets = groups[groupId]?.markets

  const headerConfig = groupMarkets?.map((name) => headerMap[name]) ?? []

  return { headerConfig, groupId }
}
