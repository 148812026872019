import {
  TTypeAuthModal,
  accountProviderActions,
  selectAccount
} from 'astra-core/containers/AccountProvider'
import { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { EAuthPagesAndModal } from 'astra-core'

import { IconLogout } from 'shared/ui/Icon/General/IconLogout'
import { Button } from 'shared/ui/Button'

import {
  StyledEmptyBasketContent,
  StyledEmptyBasketIcon,
  StyledEmptyBasketStatus,
  StyledEmptyBasketText,
  StyledEmptyBasketTextContainer,
  StyledEmptyBasketTitle,
  StyledEmptyBasketWrapper,
  StyledLink
} from './BasketEmptyScreen.styled'

export const BasketEmptyScreen = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const account = useSelector(selectAccount)

  const handleClickButton = useCallback(
    (types: TTypeAuthModal) => () =>
      dispatch(accountProviderActions.setTypeAuthModal(types)),
    [dispatch]
  )

  return (
    <StyledEmptyBasketWrapper>
      <StyledEmptyBasketStatus>
        {!account && (
          <StyledEmptyBasketIcon>
            <IconLogout size={40} />
          </StyledEmptyBasketIcon>
        )}

        <StyledEmptyBasketContent>
          {account ? (
            <StyledEmptyBasketTitle>{t('coupon empty')}</StyledEmptyBasketTitle>
          ) : (
            <StyledEmptyBasketTitle>
              {t('you are not authorized')}
            </StyledEmptyBasketTitle>
          )}

          <StyledEmptyBasketText>
            {account ? (
              <StyledEmptyBasketTextContainer>
                <Trans
                  components={[
                    <StyledLink to="/live" />,
                    <StyledLink to="/line" />
                  ]}
                  i18nKey="add an event by selecting it in live or line"
                  t={t}
                />
              </StyledEmptyBasketTextContainer>
            ) : (
              t('bets require a registered account')
            )}
          </StyledEmptyBasketText>
        </StyledEmptyBasketContent>
      </StyledEmptyBasketStatus>
      {!account && (
        <Button onClick={handleClickButton(EAuthPagesAndModal.SignIn)}>
          {t('sign in')}
        </Button>
      )}
    </StyledEmptyBasketWrapper>
  )
}
