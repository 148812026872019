import React, { FC, memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ESportsCodes } from 'astra-core/containers/CommonDataProvider'
import range from 'lodash/range'

import { ContentLoader } from 'shared/ui/content-loader/content-loader'
import { Skeleton } from 'shared/ui/skeleton'
import { SportIcon } from 'shared/ui/Icon/sport-icon'
import {
  getCalendarEventsFetchData,
  selectCalendarCountersList
} from 'containers/CalendarEventsContainer/selectors'
import { actions as calendarEventsContainerActions } from 'containers/CalendarEventsContainer/slice'

import { ResultsMenuItemProps } from './CalendarMenuList.types.ts'
import {
  StyledRow,
  StyledSportsMenuItem,
  StyledSportsMenuItemCount,
  StyledSportsMenuItemText,
  StyledSportsMenuList
} from './CalendarMenuList.styled'

const MenuItem = () => (
  <StyledRow>
    <ContentLoader borderRadius={8} height={24} width={24} />
    <ContentLoader borderRadius={4} height={16} width={74} />
    <ContentLoader borderRadius={4} height={16} width={16} />
  </StyledRow>
)

export const SportMenuListLoader = () => {
  return (
    <StyledSportsMenuList>
      {range(30).map((idx) => (
        <MenuItem key={idx} />
      ))}
    </StyledSportsMenuList>
  )
}

export const CalendarMenuList = () => {
  const calendarCountersList = useSelector(selectCalendarCountersList)
  const calendarEventsFetchData = useSelector(getCalendarEventsFetchData)

  return (
    <StyledSportsMenuList>
      <Skeleton
        isLoading={!!calendarEventsFetchData.loaded}
        styledFallback={<SportMenuListLoader />}
      >
        {calendarCountersList.map((sport) => (
          // @ts-ignore
          <ResultsMenuItem key={sport.id} sport={sport}>
            {sport.name}
          </ResultsMenuItem>
        ))}
      </Skeleton>
    </StyledSportsMenuList>
  )
}

const ResultsMenuItem: FC<ResultsMenuItemProps> = memo(({ sport }) => {
  const dispatch = useDispatch()

  const changeCurrentSportId = useCallback(() => {
    dispatch(
      calendarEventsContainerActions.setCurrentSportIdFilter({
        sportId: sport.id
      })
    )
  }, [dispatch, sport.id])

  return (
    <StyledSportsMenuItem
      // @ts-ignore
      $isActive={sport?.isActive}
      sport={sport.code as ESportsCodes}
      onClick={changeCurrentSportId}
    >
      <SportIcon
        // @ts-ignore
        isActive={sport.top}
        sport={sport.code as ESportsCodes}
      />

      <StyledSportsMenuItemText>{sport.name}</StyledSportsMenuItemText>

      <StyledSportsMenuItemCount>{sport.count}</StyledSportsMenuItemCount>
    </StyledSportsMenuItem>
  )
})
