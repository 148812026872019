import { useEffect } from 'react'
import { EMakeBetStatus } from 'astra-core/containers/BasketProvider'
import { useDispatch, useSelector } from 'react-redux'

import { basketModel } from 'entities/basket'

const { rightBasketContainerActions, selectIsShowBasketOnSmallWindow } =
  basketModel

// if we have a small window and current making bet status is a success, we should close a basket window
export const useCollapseBasket = ({
  currentBetStatus,
  delayToClose
}: {
  currentBetStatus: EMakeBetStatus
  delayToClose: number
}) => {
  const dispatch = useDispatch()
  const isShowBasketOnSmallWindow = useSelector(selectIsShowBasketOnSmallWindow)

  useEffect(() => {
    if (
      isShowBasketOnSmallWindow &&
      currentBetStatus === EMakeBetStatus.success
    ) {
      const timerId = setTimeout(
        () =>
          dispatch(
            rightBasketContainerActions.toggleBasketOnSmallWindow({
              isShow: false
            })
          ),
        delayToClose
      )

      return () => clearTimeout(timerId)
    }

    return () => null
  }, [dispatch, isShowBasketOnSmallWindow, currentBetStatus, delayToClose])
}
