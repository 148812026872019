import { FC, useContext, useEffect } from 'react'
import { LogoEntityType } from 'betweb-openapi-axios'
import { useDispatch } from 'react-redux'
import useMeasure from 'react-use-measure'

import { formatEventBannerDate, formatEventBannerTime } from 'shared/lib/events'
import { EventContext, IEventContext } from 'widgets/Event/Event.context'
import { EntityLogo } from 'features/entity-logo'
import { getLogos } from 'entities/logos/actions'

import { useEventBannerData } from '../../libs'

import {
  CompetitorName,
  StyledBannerCompact,
  StyledBannerCompactDate,
  StyledBannerCompactInfo,
  StyledBannerCompactTeams,
  StyledBannerCompactTime,
  StyledBannerContentLine,
  StyledBannerEventDate,
  StyledBannerEventDateWrapper,
  StyledBannerEventTime,
  StyledBannerTeamLine
} from './BannerContent.styled'

export const BannerLineContent: FC<{
  setMaxHeight: (height: number) => void
}> = ({ setMaxHeight }) => {
  const dispatch = useDispatch()
  const { event } = useContext(EventContext) as IEventContext
  const { competitors } = useEventBannerData()

  useEffect(() => {
    dispatch(
      getLogos({
        entityType: LogoEntityType.Competitor,
        ids: event.competitors.map((c) => c.id)
      })
    )
  }, [dispatch, event.competitors])

  const [ref, { height }] = useMeasure()

  useEffect(() => {
    setMaxHeight(height)
  }, [height, setMaxHeight])

  return (
    <>
      <StyledBannerCompact>
        <StyledBannerCompactTeams>
          {competitors.homeCompetitors[0]?.name} -{' '}
          {competitors.awayCompetitors[0]?.name}
        </StyledBannerCompactTeams>
        <StyledBannerCompactInfo>
          <StyledBannerCompactDate>
            {formatEventBannerDate(event.eventDate)}
          </StyledBannerCompactDate>
          <StyledBannerCompactTime>
            {formatEventBannerTime(event.eventDate)}
          </StyledBannerCompactTime>
        </StyledBannerCompactInfo>
      </StyledBannerCompact>

      <StyledBannerContentLine ref={ref}>
        <StyledBannerTeamLine isLeft>
          <CompetitorName>
            {competitors.homeCompetitors[0]?.name}
          </CompetitorName>

          {competitors.homeCompetitors[0] && (
            <EntityLogo
              entityId={competitors.homeCompetitors[0]?.id}
              entityType={LogoEntityType.Competitor}
              size={48}
            />
          )}
        </StyledBannerTeamLine>

        <StyledBannerEventDateWrapper>
          <StyledBannerEventDate>
            {formatEventBannerDate(event.eventDate)}
          </StyledBannerEventDate>

          <StyledBannerEventTime>
            {formatEventBannerTime(event.eventDate)}
          </StyledBannerEventTime>
        </StyledBannerEventDateWrapper>

        <StyledBannerTeamLine>
          {competitors.awayCompetitors[0] && (
            <EntityLogo
              entityId={competitors.awayCompetitors[0]?.id}
              entityType={LogoEntityType.Competitor}
              size={48}
            />
          )}
          <CompetitorName>
            {competitors.awayCompetitors[0]?.name}
          </CompetitorName>
        </StyledBannerTeamLine>
      </StyledBannerContentLine>
    </>
  )
}
