import React, { FC, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { selectInputSearchLine } from 'astra-core/containers/SearchProvider'
import { useGroupedTournamentsBySport } from 'astra-core'
import { SportTournamentsGroup } from 'astra-core/hooks/useEvents/types'
import {
  ESportsCodes,
  selectIsGenericSport,
  selectSport
} from 'astra-core/containers/CommonDataProvider'

import { RootState } from 'shared/types/store'
import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import {
  EventsPageHeader,
  EventsPageHeaderTypes
} from 'widgets/events-page-header'
import { EmptyPageFilter } from 'features/empty-page-filter'
import {
  selectIsLiveFilterUsed,
  selectLayoutIsLoading,
  selectLiveEventsBySearch
} from 'pages/page-live-events/LiveContainer/selectors'
import { LiveEventsParams } from 'pages/page-live-events/LiveContainer/types'
import { liveContainerActions } from 'pages/page-live-events/LiveContainer/slice'
import { ClearSearchResults } from 'features/clear-search-results'
import { EventTournamentsTable } from 'widgets/events-table'
import { SportIcon } from 'shared/ui/Icon/sport-icon'

import * as S from './live.styled'

export const LiveTable: FC = () => {
  const searchText = useSelector(selectInputSearchLine)
  const { sportId } = useParams<LiveEventsParams>()
  const dispatch = useDispatch()
  const isLiveFilterUsed = useSelector(selectIsLiveFilterUsed)

  const events = useSelector((state: RootState) =>
    selectLiveEventsBySearch(state, { sportId })
  )
  const sportGroups = useGroupedTournamentsBySport(events)

  const isLayoutLoading = useSelector(selectLayoutIsLoading)

  const resetLiveFilter = useCallback(() => {
    dispatch(liveContainerActions.resetLiveFilter({ isResetFilter: true }))
  }, [dispatch])

  const showSportHeader = useSelector((s) => selectIsGenericSport(s, +sportId))
  const isShownLoaderSpinner = useMemo(
    () => isLayoutLoading && !sportGroups.length,
    [isLayoutLoading, sportGroups.length]
  )

  return (
    <S.LiveTableWrapper>
      <EventsPageHeader type={EventsPageHeaderTypes.Live} />

      {isShownLoaderSpinner ? (
        <LoaderSpinner />
      ) : sportGroups.length ? (
        <>
          {sportGroups.map((group) => (
            <LiveSportGroup
              group={group}
              key={group.sportId}
              showSportHeader={showSportHeader}
            />
          ))}
        </>
      ) : searchText ? (
        <ClearSearchResults isLive />
      ) : isLiveFilterUsed ? (
        <EmptyPageFilter onButtonClickResetFilter={resetLiveFilter} />
      ) : null}
    </S.LiveTableWrapper>
  )
}

const LiveSportGroup: FC<{
  group: SportTournamentsGroup
  showSportHeader: boolean
}> = ({ group, showSportHeader }) => {
  const sport = useSelector((state) => selectSport(state, group.sportId))
  return (
    <div>
      {showSportHeader && (
        <S.LiveSportHeader>
          <SportIcon sport={sport?.code as ESportsCodes} />
          {sport.name}
        </S.LiveSportHeader>
      )}
      <EventTournamentsTable group={group.eventGroups} />
    </div>
  )
}
