import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

import {
  StyledBetTypeItemTextProps,
  StyledBetTypeItemWrapperProps
} from './BasketNavItem.types'

export const StyledBetTypeItemWrapper = styled.button<StyledBetTypeItemWrapperProps>`
  ${({ theme, active, disabled }) => css`
    align-items: center;
    background: ${active
      ? `${theme.tokens.colors.surface['surface-active2']}`
      : 'transparent'};
    border: none;
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-s-6'
    ]}px;
    cursor: pointer;
    display: flex;
    height: 28px;
    justify-content: center;
    transition: all 0.3s;
    width: 80px;

    ${disabled &&
    css`
      cursor: default;

      &:hover {
        background: ${theme.tokens.colors.surface['surface-hover']};
      }

      & ${StyledBetTypeItemText} {
        color: ${theme.tokens.colors.text['text-disabled']};
      }
    `}
  `}
`

export const StyledBetTypeItemText = styled.div<StyledBetTypeItemTextProps>`
  ${typography['Hint / 11 BOLD']}
  ${({ theme, active }) => css`
    color: ${active
      ? theme.tokens.colors.text['text-invert']
      : theme.tokens.colors.text['text-primary']};
    line-height: 16px;
    text-align: center;
  `}
`
