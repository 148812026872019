import { useSelector } from 'react-redux'

import {
  BalanceOperationsTypes,
  selectActivePaymentTabValue,
  selectPaymentMethodTitle
} from '../../../model'

export const useGetPaymentMethodTitle = (
  balanceOperationType: BalanceOperationsTypes
) => {
  const activePaymentTabValue = useSelector((state) =>
    selectActivePaymentTabValue(state, balanceOperationType)
  )

  return useSelector((state) =>
    selectPaymentMethodTitle(state, activePaymentTabValue, balanceOperationType)
  )
}
