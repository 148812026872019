import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'

import {
  getCalendarEventsFetchData,
  selectCalendarEventsDataEntriesGroupedByTime,
  selectCalendarEventsIsEmpty,
  selectCalendarEventsPagination,
  selectFilterSelectInputPeriod
} from 'containers/CalendarEventsContainer/selectors'
import { actions as calendarEventsContainerActions } from 'containers/CalendarEventsContainer/slice'
import { EventsTable } from 'widgets/events-table'
import { useDateToString } from 'hooks'

import {
  StyledDataList,
  StyledCalendarEvents,
  StyledCalendarEventsEmptyText,
  StyledEventsTableHeaderDate,
  StyledEventsTableHeaderTime,
  StyledEventsTableHeaderWrapper
} from './CalendarEvents.styled'
import { PAGINATION_SETTINGS } from './constants'

export const CalendarEvents = () => {
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const filterPeriod = useSelector(selectFilterSelectInputPeriod)
  const isCalendarEventsEmpty = useSelector(selectCalendarEventsIsEmpty)
  const eventsByTime = useSelector(selectCalendarEventsDataEntriesGroupedByTime)
  const calendarEventsFetchData = useSelector(getCalendarEventsFetchData)

  const pagination = useSelector(selectCalendarEventsPagination)

  useEffect(() => {
    dispatch(
      calendarEventsContainerActions.fetchScheduledEvents({
        ...filterPeriod,
        limit: pagination.limit,
        offset: pagination.offset
      })
    )
  }, [dispatch, filterPeriod, pagination.limit, pagination.offset])

  return (
    <StyledCalendarEvents>
      <StyledDataList pagination={PAGINATION_SETTINGS}>
        {isCalendarEventsEmpty ? (
          <StyledCalendarEventsEmptyText>
            {t('events coming soon')}
          </StyledCalendarEventsEmptyText>
        ) : (
          Object.values(eventsByTime).map((events) => (
            <React.Fragment key={events[0]?.eventDate}>
              <EventsTableHeader eventDate={events[0]?.eventDate} />
              <EventsTable
                events={events}
                headerProps={{ isHideHeader: true }}
                isBorderRadius
              />
            </React.Fragment>
          ))
        )}
      </StyledDataList>
    </StyledCalendarEvents>
  )
}

export const EventsTableHeader = ({ eventDate }) => {
  const { checkDate } = useDateToString()

  return (
    <StyledEventsTableHeaderWrapper>
      <StyledEventsTableHeaderTime>
        {dayjs(eventDate).format('HH:mm')}
      </StyledEventsTableHeaderTime>
      <StyledEventsTableHeaderDate>
        {`${checkDate(eventDate)}${dayjs(eventDate).format('DD MMMM')}`}
      </StyledEventsTableHeaderDate>
    </StyledEventsTableHeaderWrapper>
  )
}
