import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { Button } from 'shared/ui/Button'
import { IconTop } from 'shared/ui/Icon/General/IconTop'
import {
  selectFilterIsTop,
  selectLayoutIsLoading
} from 'pages/page-line-tournaments/LineTournamentsContainer/selectors'
import { lineTournamentsContainerActions } from 'pages/page-line-tournaments/LineTournamentsContainer/slice'
import { useIsSmall, useIsTabletOrMobile } from 'shared/lib/styled'
import { BasketButtonSmallWindow } from 'features/basket/open-basket-small-window'
import { LineTournamentsParams } from 'pages/page-line-tournaments/LineTournamentsContainer/types'
import { useTournamentsGroupedBySubSport } from 'pages/page-line-tournaments/LineTournamentsContainer/hooks'
import { LineSearch } from 'features/line-search'
import { useIsEventListHasTop } from 'entities/event'

import { SelectFilterPeriod } from '../select-filter-period'

import {
  StyledButtonsWrapper,
  StyledSearchWrapper,
  StyledSearchDivider
} from './line-tournaments-content.styled'

export const LineTournamentsContent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isTop = useSelector(selectFilterIsTop)
  const layoutIsLoading = useSelector(selectLayoutIsLoading)
  const { sportId } = useParams<LineTournamentsParams>()

  const { groupedTournaments } = useTournamentsGroupedBySubSport({
    sportId: +sportId
  })

  const lineTournaments = useMemo(() => {
    return groupedTournaments[0]?.tournaments || []
  }, [groupedTournaments])

  const { isEventListHasTop } = useIsEventListHasTop(lineTournaments)

  const toggleFilterTop = useCallback(
    () => dispatch(lineTournamentsContainerActions.toggleFilterIsTop()),
    [dispatch]
  )

  const isTabletOrMobile = useIsTabletOrMobile()
  const isSmall = useIsSmall()

  return (
    <StyledButtonsWrapper>
      <StyledSearchWrapper>
        <LineSearch placeholder={t('search in line')} isTournaments />
      </StyledSearchWrapper>
      <StyledSearchDivider />
      <SelectFilterPeriod />

      <Button
        disabled={!isEventListHasTop || layoutIsLoading}
        icon={IconTop}
        isActive={isTop}
        view="filter"
        onClick={toggleFilterTop}
      >
        {t('only top')}
      </Button>

      {(isTabletOrMobile || isSmall) && <BasketButtonSmallWindow isSmall />}
    </StyledButtonsWrapper>
  )
}
