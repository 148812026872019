import { EMakeBetStatus } from 'astra-core/containers/BasketProvider'
import { ReactElement } from 'react'

import {
  BasketStatusError,
  BasketStatusSuccess,
  BasketStatusWait
} from 'entities/basket'

import { EBasketHeaderTypes } from '../../ui/content/content.types'
import { BasketBody as BasketBets } from '../../basket-body'
import { BasketHistoryFacade } from '../../basket-history/ui/BasketHistoryFacade'

export const BASKET_CONTENT_MAP: Record<
  EBasketHeaderTypes,
  {
    status: Partial<Record<EMakeBetStatus, ReactElement>>
    content: ReactElement
  }
> = {
  [EBasketHeaderTypes.Coupon]: {
    status: {
      [EMakeBetStatus.loading]: <BasketStatusWait />,
      [EMakeBetStatus.success]: <BasketStatusSuccess />,
      [EMakeBetStatus.error]: <BasketStatusError />
    },
    content: <BasketBets />
  },
  [EBasketHeaderTypes.History]: {
    status: {},
    content: <BasketHistoryFacade />
  }
}
