import { useTranslation } from 'react-i18next'
import { MouseEvent } from 'react'
import { useDispatch } from 'react-redux'

import { Typography } from 'shared/ui/typography'
import { Button } from 'shared/ui/Button'
import { useGlobalSearchValueContext } from 'features/global-search'

import {
  PopularRequestsItems,
  PopularRequestsWrapper
} from './popular-queries.styled'

const popularQueryItems = [
  'Премьер Лига',
  'Манчестер Юнайтед',
  'Лига чемпионов',
  'КХЛ',
  'НХЛ',
  'НБА',
  'Ньюкасл',
  'Брайтон'
]

export const PopularQueries = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { setValue } = useGlobalSearchValueContext()

  const handleItemClick = (item: string) => (event: MouseEvent) => {
    event.stopPropagation()
    setValue(item)
  }

  return (
    <PopularRequestsWrapper>
      <Typography color="text-secondary-2" font="Hint / 10 BOLD">
        {t('popular requests')}
      </Typography>
      <PopularRequestsItems>
        {popularQueryItems.map((item) => (
          <Button
            key={item}
            view="secondary-large"
            onClick={handleItemClick(item)}
          >
            <Typography color="text-secondary-3" font="Hint / 11 BOLD">
              {item}
            </Typography>
          </Button>
        ))}
      </PopularRequestsItems>
    </PopularRequestsWrapper>
  )
}
