import isEmpty from 'lodash/isEmpty'
import { createSelector } from '@reduxjs/toolkit'
import { getEvents, getTournaments } from 'astra-core/containers/EventsProvider'
import {
  getInputSearchLine,
  getSearchEventsEntries,
  selectInputSearchLine,
  selectSearchEventsItems
} from 'astra-core/containers/SearchProvider'
import { getEventsBySearch, getLineTournamentsBySearch } from 'astra-core/utils'

import { RootState } from 'shared/types/store'
import { getValues } from 'shared/lib/@reduxjs'

import { initialState } from './slice'
import { SelectLineEventsParams } from './types'

type SelectLineTournamentsParams = {
  sportId: string
}

const getLineTournamentsSubstate = (state: RootState) =>
  state.lineTournamentsContainer || initialState

export const getLineTournamentsIdsBySport = (
  state: RootState,
  params: SelectLineTournamentsParams
) => {
  return (
    getLineTournamentsSubstate(state).tournamentsIdsBySport[
      +params.sportId // TODO either add sportIdPrev here or add sceleton preloader to UI
    ] || []
  )
}

export const getLineTournamentsFetchItems = (state: RootState) =>
  getLineTournamentsSubstate(state).fetchItems

export const getFilterIsTop = (state: RootState) =>
  getLineTournamentsSubstate(state).filters.isTop

export const getFilterSearch = (state: RootState) =>
  getLineTournamentsSubstate(state).filters.search

export const getFilterEventsSearch = (state: RootState) =>
  getLineTournamentsSubstate(state).filters.eventsSearch

export const getFilterPeriod = (state: RootState) =>
  getLineTournamentsSubstate(state).filters.period

export const selectFilterSportId = (state: RootState) =>
  getLineTournamentsSubstate(state).filters.sportId

export const getLayoutIsLoading = (state: RootState) =>
  getLineTournamentsSubstate(state).layouts.isLoading

export const getEventsLayoutIsLoading = (state: RootState) =>
  getLineTournamentsSubstate(state).layouts.isEventsLoading

export const getLineFetchItems = (state: RootState) =>
  getLineTournamentsSubstate(state).fetchEventsItems
export const getLineTournamentsFilters = (state: RootState) =>
  getLineTournamentsSubstate(state).filters

export const getLineEventsIdsByTournament = (
  state: RootState,
  params: SelectLineEventsParams
) =>
  getLineTournamentsSubstate(state).eventsIdsByTournament[
    +params.tournamentId // TODO either add tournamentIdPrev here or add skeleton preloader to UI
  ] || []

export const selectLineTournaments = createSelector(
  [
    getLineTournamentsIdsBySport,
    getTournaments,
    selectInputSearchLine,
    selectSearchEventsItems
  ],
  (lineTournamentsIdsBySport, tournaments, searchText, events) =>
    getLineTournamentsBySearch({
      tournaments: getValues(lineTournamentsIdsBySport, tournaments),
      searchText,
      events
    })
)

export const selectLineTournamentsIsEmpty = createSelector(
  [getTournaments],
  (tournaments) => isEmpty(tournaments)
)

export const selectLineTournamentsFetchItems = createSelector(
  [getLineTournamentsFetchItems],
  (lineTournamentsFetchItems) => lineTournamentsFetchItems
)

export const selectFilterIsTop = createSelector(
  [getFilterIsTop],
  (isTop) => isTop
)

export const selectLayoutIsLoading = createSelector(
  [getLayoutIsLoading],
  (isLoading) => isLoading
)

export const selectEventsLayoutIsLoading = createSelector(
  [getEventsLayoutIsLoading],
  (isLoading) => isLoading
)

export const selectLineIsEmpty = createSelector([getEvents], (events) =>
  isEmpty(events)
)

export const selectLineFetchItems = createSelector(
  [getLineFetchItems],
  (lineFetchItems) => lineFetchItems
)

export const selectLineEvents = createSelector(
  [getLineEventsIdsByTournament, getEvents, selectInputSearchLine],
  (eventsIdsByTournament, events, searchText) =>
    getEventsBySearch({
      events: getValues(eventsIdsByTournament, events),
      searchText
    })
)

export const selectLineTournamentsFilters = createSelector(
  [getLineTournamentsFilters],
  (filters) => filters
)

export const selectLineTournamentsLength = createSelector(
  [
    getLineTournamentsIdsBySport,
    getTournaments,
    getInputSearchLine,
    getSearchEventsEntries
  ],
  (lineTournamentsIdsBySport, tournaments, searchText, events) =>
    getLineTournamentsBySearch({
      tournaments: getValues(lineTournamentsIdsBySport, tournaments),
      searchText,
      events
    }).length
)
