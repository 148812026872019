import styled, { css, keyframes } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

export const StyledStatisticsWrapper = styled.div`
  align-items: center;
  display: grid;
  gap: 4px;
  grid-template-columns: minmax(0, auto) auto;
`

export const ScoresByPeriodsWrapper = styled.span`
  display: grid;
  grid-template-columns: 1fr 16px;
  position: relative;
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary-3']};
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
  `}
`

export const ScoresByPeriodText = styled.div`
  max-height: 16px;
  overflow: hidden;
  white-space: initial;
`

export const ShowMoreButton = styled.div<{ isVisible: boolean }>`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 12px;
  justify-content: center;
  transition: background-color 0.25s ease;
  width: 16px;
  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.surface['surface-default']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-xs-4'
    ]}px;
    gap: ${theme.tokens.alias.space['space-xxxxs-2']}px;
    margin: ${theme.tokens.alias.space['space-xxxxs-2']}px 0;

    &:hover {
      background-color: ${theme.tokens.colors.surface['surface-hover']};
    }
  `}
  ${({ isVisible }) =>
    !isVisible &&
    css`
      visibility: hidden;
    `}
`

export const ShowMoreDot = styled.div`
  border-radius: 1px;
  height: 2px;
  width: 2px;
  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.text['text-secondary-3']};
  `}
`

export const ScoresByPeriodsModal = styled.div`
  box-shadow: 0 2px 4px 0 #6c799314, 0 4px 8px 2px #6c79931f;
  display: grid;
  grid-template-columns: 1fr 16px;
  position: absolute;
  white-space: initial;
  z-index: 1;
  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.surface['surface-modal']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-xs-4'
    ]}px;
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
    left: -${theme.tokens.alias.space['space-xxxs-4']}px;
    padding: ${theme.tokens.alias.space['space-xxxxs-2']}px
      ${theme.tokens.alias.space['space-xxxs-4']}px;
    right: -${theme.tokens.alias.space['space-xxxs-4']}px;
    top: -${theme.tokens.alias.space['space-xxxxs-2']}px;
  `}
`

export const IconWrap = styled.div<{ isLoading?: boolean }>`
  display: flex;
  padding: 2px;

  ${({ isLoading }) =>
    isLoading &&
    css`
      animation: ${rotate} 1s linear infinite reverse;
    `}
`

export const GetLegacyStatistics = styled.div`
  align-items: center;
  color: ${(p) => p.theme.tokens.colors.text['text-linkAccent-default']};
  ${typography['Body / 12 Medium']};
  cursor: pointer;
  display: grid;
  gap: 2px;
  grid-template-columns: auto 1fr;
`
