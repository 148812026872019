import React, { FC, KeyboardEvent, memo, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  basketProviderActions,
  selectBasketBonusWallet,
  selectDisabledButtonOrdinar,
  selectOrdinarOutcomeErrors,
  selectOrdinarOutcomesAmounts,
  selectOrdinarSingleAmount,
  selectOrdinarTotalAmount,
  selectOutcomeStakeAmount
} from 'astra-core/containers/BasketProvider'
import { activeEventStatus } from 'astra-core/containers/BasketProvider/constants'
import { formatAmount as formatAmountCore } from 'astra-core'

import { RootState } from 'shared/types/store'
import { SumInput } from 'features/input-sum'
import { validateBasketInputValue } from 'shared/lib/basket/validateBasketInputValue'
import { BetCardCoupon } from 'features/basket/basket-outcomes'
import { BasketBottom } from 'features/basket/basket-bottom'
import { BasketTooltipProvider } from 'shared/ui/Tooltip/basket-tooltip'

import {
  StyledBasketBodyList,
  StyledBetCardList,
  StyledBetGroupsList
} from '../BasketBody.styled'

import {
  StyledBetCardElementWrapper,
  StyledSingleBetAmountInput,
  StyledSingleBetAmountWrapper
} from './Ordinar.styled'
import {
  BetCardElementProps,
  OrdinarProps,
  SingleBetAmountProps
} from './Ordinar.types'

export const Ordinar: FC<OrdinarProps> = ({ outcomes }) => {
  const disabledButton = useSelector(selectDisabledButtonOrdinar)
  const totalAmount = useSelector(selectOrdinarTotalAmount)

  return (
    <>
      <StyledBasketBodyList>
        <StyledBetGroupsList>
          <BasketTooltipProvider>
            <StyledBetCardList>
              {outcomes.map((outcome) => (
                <BetCardElement key={outcome.id} outcome={outcome} />
              ))}
            </StyledBetCardList>
          </BasketTooltipProvider>
        </StyledBetGroupsList>
      </StyledBasketBodyList>

      <BasketBottom disabledButton={disabledButton || !totalAmount} />
    </>
  )
}

const BetCardElement: FC<BetCardElementProps> = ({ outcome }) => {
  const {
    probability: { odd },
    id: outcomeId,
    event: { status: eventStatus },
    maxBet
  } = outcome
  const dispatch = useDispatch()
  const outcomeErrors = useSelector(selectOrdinarOutcomeErrors)
  const stakeAmount = useSelector((state: RootState) =>
    selectOutcomeStakeAmount(state, outcomeId)
  )
  const isBonusWallet = useSelector(selectBasketBonusWallet)

  const isEventActive = useMemo(
    () => activeEventStatus.includes(eventStatus),
    [eventStatus]
  )

  const ordinarOutcomesAmounts = useSelector(selectOrdinarOutcomesAmounts)

  const winSum = useMemo(
    () => (stakeAmount !== '' && +stakeAmount * (odd - +isBonusWallet)) || 0,
    [stakeAmount, odd, isBonusWallet]
  )

  const setOutcomeAmount = useCallback(
    (value: string) => {
      dispatch(
        basketProviderActions.setOrdinarAmount({
          outcomeId,
          amount: validateBasketInputValue(value),
          maxBet
        })
      )
    },
    [dispatch, outcomeId, maxBet]
  )

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        dispatch(basketProviderActions.makeBet())
      }
    },
    [dispatch, basketProviderActions]
  )

  const outcomeError = useMemo(
    () => outcomeErrors[outcomeId]?.message,
    [outcomeErrors, outcomeId]
  )

  return (
    <StyledBetCardElementWrapper>
      <BetCardCoupon outcome={outcome}>
        <SumInput
          background="gray"
          defaultValue={ordinarOutcomesAmounts[outcomeId]}
          disabled={!isEventActive}
          errorMessage={outcomeError}
          maxValue={outcome.maxBet}
          size="s"
          winSum={winSum}
          isOrdinar
          onChange={setOutcomeAmount}
          onKeyDown={handleKeyDown}
        />
      </BetCardCoupon>
    </StyledBetCardElementWrapper>
  )
}

export const SingleBetAmount: FC<SingleBetAmountProps> = memo(() => {
  const dispatch = useDispatch()
  const singleAmount = useSelector(selectOrdinarSingleAmount)

  const setSingleAmountForEachOutcome = useCallback(
    (e) => {
      dispatch(
        basketProviderActions.setSingleAmountForEachOutcome({
          amount: validateBasketInputValue(e)
        })
      )
    },
    [dispatch]
  )

  return (
    <StyledSingleBetAmountWrapper>
      <StyledSingleBetAmountInput
        value={formatAmountCore({ value: singleAmount })}
        onChange={setSingleAmountForEachOutcome}
      />
    </StyledSingleBetAmountWrapper>
  )
})
