import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  betsHistoryProviderActions,
  selectCashoutByBetId,
  selectCashoutError,
  selectCurrentCashout,
  selectIsMakeCashoutLoading
} from 'astra-core/containers/BetsHistoryProvider'
import { useGetCurrencyIcon, formatAmountLocaleString } from 'astra-core'

import { DISABLE_HOVER_ON_CHILD } from 'widgets/bets-history/components/constants'
import { RootState } from 'shared/types/store'
import { Tooltip } from 'shared/ui/Tooltip'
import { MakeCashoutLoading } from 'features/basket/basket-history/ui/BasketHistoryCashout/components/CurrentCashoutModal/constants'

import { CurrentCashoutModal } from '../CurrentCashoutModal'
import { StyledMakeCashoutLoadingWrapper } from '../CurrentCashoutModal/CurrentCashoutModal.styled'

import {
  StyledCellEventCashoutText,
  StyledCellEventCashoutWrapper,
  CurrentCashoutModalWholeBackground,
  StyledCellEventCashoutCurrency
} from './CellEventCashout.styled'
import { ICellEventCashoutText } from './CellEventCashout.types'

const CellEventCashoutText: FC<ICellEventCashoutText> = ({ amount }) => {
  const [t] = useTranslation()
  const currencyIcon = useGetCurrencyIcon()
  const isMakeCashoutLoading = useSelector(selectIsMakeCashoutLoading)

  const text = `${t('sell for')} ${formatAmountLocaleString({
    value: amount
  })}`

  return (
    <Tooltip
      isCommon={!isMakeCashoutLoading}
      title={t('currently impossible to sell a bet')}
    >
      <StyledCellEventCashoutText
        className={DISABLE_HOVER_ON_CHILD}
        disabled={isMakeCashoutLoading}
        isLoading={isMakeCashoutLoading}
        view="secondary-large"
      >
        {text}
        <StyledCellEventCashoutCurrency>
          {currencyIcon}
        </StyledCellEventCashoutCurrency>
      </StyledCellEventCashoutText>
    </Tooltip>
  )
}

export const CellEventCashout = ({ id }) => {
  const dispatch = useDispatch()
  const cashout = useSelector((state: RootState) =>
    selectCashoutByBetId(state, id)
  )
  const currentCashout = useSelector(selectCurrentCashout)
  const isMakeCashoutLoading = useSelector(selectIsMakeCashoutLoading)
  const isShowCurrentCashout = !!(currentCashout && currentCashout.betId === id)
  const isCashoutExist = !!(cashout && cashout.amount)
  const cashoutError = useSelector(selectCashoutError)
  const isShowCurrentCashoutModal =
    isShowCurrentCashout && !isMakeCashoutLoading

  const unsetCurrentCashout = (event) => {
    event.stopPropagation()
    dispatch(betsHistoryProviderActions.unsetCurrentCashout())
  }

  const setCurrentCashout = (event) => {
    event.stopPropagation()

    if (isCashoutExist && !isShowCurrentCashout && !isMakeCashoutLoading) {
      dispatch(
        betsHistoryProviderActions.setCurrentCashout({ betId: cashout.betId })
      )
    }
  }

  return isCashoutExist || !!cashoutError ? (
    <StyledCellEventCashoutWrapper onClick={setCurrentCashout}>
      {isMakeCashoutLoading && isShowCurrentCashout ? (
        <StyledMakeCashoutLoadingWrapper>
          <MakeCashoutLoading />
        </StyledMakeCashoutLoadingWrapper>
      ) : isCashoutExist ? (
        <CellEventCashoutText {...cashout} />
      ) : null}

      {isShowCurrentCashoutModal && (
        <>
          <CurrentCashoutModal />
          <CurrentCashoutModalWholeBackground onClick={unsetCurrentCashout} />
        </>
      )}
    </StyledCellEventCashoutWrapper>
  ) : null
}
