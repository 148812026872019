import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  basketProviderActions,
  selectActiveLoyaltyByAppliedCouponIdsByBetType,
  selectBasketAppliedCoupon,
  selectBasketBetTypeTab
} from 'astra-core/containers/BasketProvider'
import { formatAmount, useGetCurrencyIcon } from 'astra-core'
import { ELoyaltyCoupons } from 'astra-core/containers/AccountProvider'

import { RootState } from 'shared/types/store'
import { IconTicketNo } from 'shared/ui/Icon/General/IconTicketNo'
import { Typography } from 'shared/ui/typography'
import { RadioBonus } from 'shared/ui/RadioBonus'
import { Alert, EAlertViev } from 'shared/ui/alert'

import { IBasketBonusCouponProps } from './BasketSuitableCoupons.types'
import {
  StyledAlertWrapper,
  StyledBasketBonusCouponWrapper,
  StyledBasketBonusCouponsWrapper,
  StyledBonusText
} from './BasketSuitableCoupons.styled'

// List of bonus coupons that have passed the terms of use and can be used
export const BasketSuitableCoupons = () => {
  const [t] = useTranslation()
  const betTypeTab = useSelector(selectBasketBetTypeTab)
  const appliedCoupon = useSelector(selectBasketAppliedCoupon)
  const isAppliedCoupon = localStorage.getItem('appliedCoupon')
  const suitableCoupons = useSelector((state: RootState) =>
    selectActiveLoyaltyByAppliedCouponIdsByBetType(
      state,
      ELoyaltyCoupons.SUITABLE
    )
  )

  return (
    <>
      <StyledBasketBonusCouponsWrapper betTypeTab={betTypeTab}>
        {suitableCoupons[betTypeTab].map((coupon) => (
          <div>
            <BasketSuitableCoupon key={coupon.id} {...coupon} />
          </div>
        ))}
      </StyledBasketBonusCouponsWrapper>

      {!!suitableCoupons[betTypeTab].length &&
        !appliedCoupon &&
        !isAppliedCoupon && (
          <StyledAlertWrapper>
            <Alert size="s" view={EAlertViev.INFO}>
              {t('you can apply the bonus coupon available to you')}
            </Alert>
          </StyledAlertWrapper>
        )}
    </>
  )
}

const BasketSuitableCoupon: FC<IBasketBonusCouponProps> = ({
  id,
  possibleAmount,
  coupon: { amount }
}) => {
  const dispatch = useDispatch()
  const currencyIcon = useGetCurrencyIcon()
  const appliedCoupon = useSelector(selectBasketAppliedCoupon)

  const setAppliedCoupon = () => {
    localStorage.setItem('appliedCoupon', 'true')

    if (appliedCoupon === id) {
      dispatch(basketProviderActions.setAppliedCoupon(null))
    } else {
      dispatch(basketProviderActions.setAppliedCoupon(id))
    }
  }

  const isRadioChecked = appliedCoupon === id

  const couponValue = formatAmount({
    value: possibleAmount ?? amount,
    currency: currencyIcon
  })

  return (
    <StyledBasketBonusCouponWrapper>
      <IconTicketNo />

      <StyledBonusText>
        <Trans
          components={{
            bold: <Typography color="text-primary-2" font="Body / 12 Bold" />
          }}
          i18nKey="apply coupon to"
          values={{ coupon: couponValue }}
        />
      </StyledBonusText>

      <RadioBonus
        checked={isRadioChecked}
        onChange={() => setAppliedCoupon()}
      />
    </StyledBasketBonusCouponWrapper>
  )
}
