import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSetUserSetting } from 'astra-core/containers/SettingsProvider/utils'
import { createPortal } from 'react-dom'

import { layoutContainerActions } from 'containers/LayoutContainer/slice'
import {
  getOnboardingIsDisplay,
  getOnboardingType
} from 'containers/LayoutContainer/selectors'
import { useHydrated } from 'shared/lib/hydrated/use-hydrated'
import { ColumnLayoutContent } from 'shared/lib/layout'

import { useStepGenerator } from './utils/stepGenerator/useStepGenerator'
import {
  OnboardingItemContext,
  TOnboardingItemContext
} from './Onboarding.context'
import { OnboardingMask } from './components'
import { ONBOARDINGS_MAP } from './constants'
import { StyledOnboarding } from './Onboarding.styled'

export const Onboarding = () => {
  const dispatch = useDispatch()
  const type = useSelector(getOnboardingType)
  const { onSetUserSetting } = useSetUserSetting()

  const {
    onNextOnboardingItem,
    itemNodeRectProps,
    item,
    onStartOnboarding,
    isEndedGenerator,
    isHaveNext
  } = useStepGenerator(ONBOARDINGS_MAP[type])

  useEffect(() => {
    const node = document.querySelector(ColumnLayoutContent.toString())

    if (node) {
      node.scrollTo({ top: 0 })
    }

    onStartOnboarding()
  }, [onStartOnboarding])

  useEffect(() => {
    if (isEndedGenerator) {
      dispatch(layoutContainerActions.resetOnboarding())
    }
  }, [dispatch, isEndedGenerator, onStartOnboarding])

  const contextValue: TOnboardingItemContext = {
    itemNodeRectProps,
    positionContent: item?.positionContent,
    onNextOnboardingItem,
    onCloseOnboarding: () => {
      onSetUserSetting({ onboardPassed: true })
      dispatch(layoutContainerActions.resetOnboarding())
    },
    isHaveNext
  }

  const Content = item?.content

  return (
    <OnboardingItemContext.Provider value={contextValue}>
      <StyledOnboarding>
        {Content && <Content />}
        <OnboardingMask />
      </StyledOnboarding>
    </OnboardingItemContext.Provider>
  )
}

export const OnboardingPortal: FC = () => {
  const isDisplayOnboarding = useSelector(getOnboardingIsDisplay)
  const hydrated = useHydrated()

  return hydrated && isDisplayOnboarding
    ? createPortal(<Onboarding />, document.body as Element)
    : null
}
