import { all, put, select, takeLatest } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { eventsProviderActions } from 'astra-core/containers/EventsProvider'
import { EntityTag, TagFilterOperation } from 'betweb-openapi-axios'

import { eventsProviderSideEffects } from 'shared/lib/eventsProviderSideEffects'
import {
  getItemLocalStorage,
  removeItemLocalStorage,
  setItemLocalStorage
} from 'shared/lib/@system'
import { getFetchLineReqData } from 'pages/page-line-events/LineContainer/utils'
import { eventModel } from 'entities/event'

import {
  lineTournamentsContainerActions,
  LINE_TOURNAMENT_FILTER_LS
} from './slice'
import {
  FetchLinePayload,
  FetchLineTournamentsPayload,
  TFormatLineTournamentsFiltersParams
} from './types'
import { selectLineTournamentsFilters } from './selectors'
import {
  formatLineTournamentsFiltersData,
  getFetchLineTournamentsReqData
} from './utils'

const SOURCE_KEY = 'lineTournaments'
const SOURCE_KEY_LINE_EVENTS = 'lineEvents'

const { getTopTagIds, selectEntityTags } = eventModel

export function* fetchLineTournamentsSaga(
  action: PayloadAction<FetchLineTournamentsPayload>
) {
  const { sportId } = action.payload
  const filters = yield select(selectLineTournamentsFilters)
  const entityTags: Dictionary<EntityTag> = yield select(selectEntityTags)

  const savedLSFilter = getItemLocalStorage(
    LINE_TOURNAMENT_FILTER_LS
  ) as TFormatLineTournamentsFiltersParams

  const lineTournamentsFilter = savedLSFilter
    ? formatLineTournamentsFiltersData(savedLSFilter)
    : formatLineTournamentsFiltersData(filters)

  const reqData = getFetchLineTournamentsReqData({
    sportId,
    ...lineTournamentsFilter
  })

  yield put(
    eventsProviderActions.fetchTournaments({
      reqData: {
        ...reqData,
        ...(filters.isTop && {
          tagIds: getTopTagIds(entityTags),
          tagFilterOperation: TagFilterOperation.Loose
        })
      },
      sideEffects: eventsProviderSideEffects[SOURCE_KEY]
    })
  )
}

export function* fetchLineSaga(action: PayloadAction<FetchLinePayload>) {
  const { tournamentId } = action.payload
  const filters = yield select(selectLineTournamentsFilters)
  const entityTags: Dictionary<EntityTag> = yield select(selectEntityTags)

  const savedLSFilter = getItemLocalStorage(
    LINE_TOURNAMENT_FILTER_LS
  ) as TFormatLineTournamentsFiltersParams

  const lineTournamentsFilter = savedLSFilter
    ? formatLineTournamentsFiltersData(savedLSFilter)
    : formatLineTournamentsFiltersData(filters)

  const tagIds = filters.isTop ? getTopTagIds(entityTags) : []

  const reqData = getFetchLineReqData({
    tournamentId: String(tournamentId),
    tagIds,
    filterPeriod: lineTournamentsFilter.filterPeriod
  })

  yield put(
    eventsProviderActions.fetchEvents({
      reqData,
      sideEffects: eventsProviderSideEffects[SOURCE_KEY_LINE_EVENTS]
    })
  )
}

export function* changeLineFilterSaga(_action) {
  const filters = yield select(selectLineTournamentsFilters)

  setItemLocalStorage(LINE_TOURNAMENT_FILTER_LS, filters)
}

export function* removeLineFilterSaga(_action) {
  return yield removeItemLocalStorage(LINE_TOURNAMENT_FILTER_LS)
}

export function* lineTournamentsContainerSaga() {
  yield all([
    takeLatest(
      lineTournamentsContainerActions.fetchLineTournaments.type,
      fetchLineTournamentsSaga
    ),
    takeLatest(lineTournamentsContainerActions.fetchLine.type, fetchLineSaga),
    takeLatest(
      lineTournamentsContainerActions.toggleFilterIsTop.type,
      changeLineFilterSaga
    ),
    takeLatest(
      lineTournamentsContainerActions.setFilterPeriod.type,
      changeLineFilterSaga
    ),
    takeLatest(
      lineTournamentsContainerActions.resetFilters.type,
      removeLineFilterSaga
    )
  ])
}
