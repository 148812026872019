import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectSport } from 'astra-core/containers/CommonDataProvider'
import { FC } from 'react'

import { RootState } from 'shared/types/store'
import { EventsPageTitle } from 'features/events-page-title/events-page-title'

import { LiveContent } from './ui/live-content'
import { LineContent } from './ui/line-content'
import { LineTournamentsContent } from './ui/line-tournaments-content'
import {
  EventsPageHeaderProps,
  EventsPageHeaderTypes,
  EventsPageParams
} from './events-page-header.types'

const headerRight = {
  [EventsPageHeaderTypes.Live]: <LiveContent />,
  [EventsPageHeaderTypes.Line]: <LineContent />,
  [EventsPageHeaderTypes.LineTournaments]: <LineTournamentsContent />
}

export const EventsPageHeader: FC<EventsPageHeaderProps> = ({ type }) => {
  const { sportId } = useParams<EventsPageParams>()
  const sport = useSelector((state: RootState) => selectSport(state, +sportId))

  return <EventsPageTitle renderRight={headerRight[type]} title={sport?.name} />
}
