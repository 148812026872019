import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useRef, KeyboardEvent, FC } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import {
  useGlobalSearchContext,
  useGlobalSearchValueContext
} from 'features/global-search'
import { TextInput } from 'shared/ui/text-input'
import { ERoutes } from 'shared/types/routes'
import { IconClearSearch } from 'shared/ui/Icon/General/icon-clear-search'
import { Tooltip } from 'shared/ui/Tooltip'

import {
  ClearTooltipBody,
  HotkeyWrap,
  StyledClearIconWrapper,
  StyledSearchIcon
} from './global-search-input.styled'

interface Props {
  scrollTopOnFocus?: boolean
  isOverlaySearch?: boolean
}

export const GlobalSearchInput: FC<Props> = ({
  scrollTopOnFocus = true,
  isOverlaySearch
}) => {
  const searchPageMatches = useRouteMatch(ERoutes.Search)
  const { t } = useTranslation()
  const { isOpen, setIsOpen, isFocused, setIsFocused } =
    useGlobalSearchContext()
  const { value, handleInputChange, setValue } = useGlobalSearchValueContext()
  const ref = useRef<HTMLInputElement>(null)
  const history = useHistory()
  const match = useRouteMatch(ERoutes.Root)

  const clearValue = useCallback(
    (event) => {
      setValue('')
      setIsOpen(false)
      event.stopPropagation()
    },
    [setIsOpen, setValue]
  )

  const handleFocus = () => {
    setIsOpen(true)
    setIsFocused(true)
    if (scrollTopOnFocus) {
      window.scrollTo({ top: 0, behavior: 'auto' })
    }
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      history.push(ERoutes.Search, { search: value })
    }
  }

  useEffect(() => {
    if (isOpen) {
      ref.current?.focus()
    }
  }, [isOpen])

  const handleOverlayKeys = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        ref.current?.blur()
        setIsOpen(false)
        setValue('')
      }
      if (event.key === '/') {
        ref.current?.focus()
        event.preventDefault()
      }
    },
    [setIsOpen, setValue]
  )

  useEffect(() => {
    if (isOverlaySearch && match?.isExact) {
      document.addEventListener('keydown', handleOverlayKeys)
    }
    return () => document.removeEventListener('keydown', handleOverlayKeys)
  }, [handleOverlayKeys, isOverlaySearch, match?.isExact])

  return (
    <TextInput
      endAdornment={
        isFocused || !!value ? (
          <StyledClearIconWrapper onClick={clearValue}>
            <Tooltip
              title={
                <ClearTooltipBody>
                  {t('clear')}
                  <HotkeyWrap>Esc</HotkeyWrap>
                </ClearTooltipBody>
              }
            >
              <IconClearSearch
                colorToken={isFocused ? 'icon-secondary-2' : 'icon-secondary-3'}
                size={16}
              />
            </Tooltip>
          </StyledClearIconWrapper>
        ) : undefined
      }
      placeholder={
        !isFocused
          ? t(
              isOverlaySearch
                ? 'search on site'
                : 'search events and tournaments'
            )
          : undefined
      }
      startAdornment={
        <StyledSearchIcon colorToken="icon-secondary-2" size={16} />
      }
      tooltipTitle={
        <ClearTooltipBody>
          {t('search events and tournaments')}
          <HotkeyWrap>/</HotkeyWrap>
        </ClearTooltipBody>
      }
      background="white"
      disabled={isOverlaySearch && !!searchPageMatches}
      ref={ref}
      style={{ pointerEvents: 'all' }}
      value={value}
      onBlur={handleBlur}
      onChange={handleInputChange}
      onFocus={handleFocus}
      onKeyDown={handleKeyDown}
    />
  )
}
