import { forwardRef } from 'react'
import { useSelector } from 'react-redux'
import { selectConfig } from 'astra-core/containers/ConfigProvider'
import { useLocation } from 'react-router-dom'

import { ERoutes } from 'shared/types/routes'

import { getIOverflowHidden } from '../../lib'

import { ContentProps } from './content.types'
import * as S from './content.styled'

export const Content = forwardRef<HTMLDivElement, ContentProps>(
  ({ children, className, isHideHeader }, ref) => {
    const config = useSelector(selectConfig)

    const { pathname } = useLocation()

    const isOverflowHidden = getIOverflowHidden(pathname as ERoutes)

    return (
      <S.Content
        className={className}
        hideHeader={config.IS_IFRAME || !!isHideHeader}
        isOverflowHidden={isOverflowHidden}
        ref={ref}
      >
        {children}
      </S.Content>
    )
  }
)
