import { createSelector } from '@reduxjs/toolkit'
import {
  getSports,
  getSportsIdsOrder
} from 'astra-core/containers/CommonDataProvider'
import { getEvents } from 'astra-core/containers/EventsProvider'
import { selectSportsCountersList } from 'astra-core/containers/SportCountersProvider'
import { selectBroadcastsAvailabilityMapping } from 'astra-core/containers/BroadcastProvider'
import { Event } from 'betweb-openapi-axios'

import { RootState } from 'shared/types/store'
import { getMenuTabSportsCounterType } from 'widgets/sports-menu/components/sports-menu-list/utils'
import { ESportsMenuTabTypes } from 'widgets/sports-menu'
import { getFilteredEventsBroadcast } from 'pages/page-live-events/LiveContainer/utils'
import { getValues } from 'shared/lib/@reduxjs'
import { getIsBroadcastFilterButtonEnabled } from 'shared/lib/events'

import { initialState } from './slice'

const EMPTY_ARRAY = []

const getMainPageContainer = (state: RootState) =>
  state.mainPageContainer ?? initialState

/**
 * Banners
 */

const getBannersState = (state: RootState) =>
  getMainPageContainer(state).banners

const getBannerItems = (state: RootState) => {
  const currentDate = new Date()

  return getBannersState(state).items.filter((item) => {
    if (item.activeFrom) {
      const activeFrom = new Date(item.activeFrom)

      if (activeFrom > currentDate) {
        return false
      }
    }

    if (item.activeTo) {
      const activeTo = new Date(item.activeTo)

      if (activeTo < currentDate) {
        return false
      }
    }

    return true
  })
}

export const selectBannerItems = createSelector(
  [getBannerItems],
  (items) => items
)

export const selectBannersLoading = createSelector(
  [getBannersState],
  (state) => state.fetchItems.loading
)

export const selectBannersHost = createSelector(
  [getBannersState],
  (state) => state.host
)

/**
 * Top Events
 */
const getTopEventsIdsBySport = (state: RootState) =>
  getMainPageContainer(state).topEvents.eventsIdsBySport

const getTopEventsCurrentSportId = (state: RootState) =>
  getMainPageContainer(state).topEvents.currentSportId

const getTopEventsIds = (state: RootState) =>
  getTopEventsIdsBySport(state)[getTopEventsCurrentSportId(state)] ??
  EMPTY_ARRAY

export const selectTopEvents = createSelector(
  [getTopEventsIds, getEvents],
  (topEventsIds, events) => {
    return topEventsIds.map((eventId) => events[eventId])
  }
)

const getTopEventsLoading = (state: RootState) =>
  getMainPageContainer(state).topEvents.fetchItems.loading

export const selectTopEventsLoading = createSelector(
  [getTopEventsLoading],
  (loading) => loading
)

export const selectTopEventsLoaded = (state: RootState) =>
  getMainPageContainer(state).topEvents.fetchItems.loaded

export const selectTopEventsSports = createSelector(
  [getTopEventsIdsBySport, getSports, getSportsIdsOrder],
  (topEventsIdsBySport, sports, orderedIds) => {
    const topEventsSports = Object.keys(topEventsIdsBySport)
      .map(Number)
      .map((sId) => sports[sId]!)
      .filter(Boolean)
    topEventsSports.sort(
      (a, b) => orderedIds.indexOf(a.id) - orderedIds.indexOf(b.id)
    )
    return topEventsSports
  }
)

export const selectTopEventsCurrentSportId = createSelector(
  [getTopEventsCurrentSportId],
  (sportId) => sportId
)

/**
 * Live Events
 */

const getLiveEventsFilters = (state: RootState) =>
  getMainPageContainer(state).liveEvents.filters

export const selectLiveEventsFilters = createSelector(
  [getLiveEventsFilters],
  (filters) => filters
)

export const getFilterIsBroadcast = (state: RootState) =>
  getMainPageContainer(state).liveEvents.filters.isBroadcast

const getLiveEventsIdsBySport = (state: RootState) =>
  getMainPageContainer(state).liveEvents.eventsIdsBySport

const getLiveEventsCurrentSportId = (state: RootState) =>
  getMainPageContainer(state).liveEvents.currentSportId

export const getMainPageLiveEventsIds = (state: RootState) =>
  getLiveEventsIdsBySport(state)[getLiveEventsCurrentSportId(state)] ??
  EMPTY_ARRAY

export const selectMainPageLiveEvents = createSelector(
  [
    getMainPageLiveEventsIds,
    getEvents,
    getFilterIsBroadcast,
    selectBroadcastsAvailabilityMapping
  ],
  (liveEventsIds, events, isBroadcast, broadcasts) => {
    const filteredEvents: Dictionary<Event> = isBroadcast
      ? getFilteredEventsBroadcast(events, broadcasts)
      : events

    return getValues(liveEventsIds, filteredEvents)
  }
)

export const selectLiveEventsLoaded = (state: RootState) =>
  getMainPageContainer(state).liveEvents.fetchItems.loaded

export const selectLiveEventsCurrentSportId = createSelector(
  [getLiveEventsCurrentSportId],
  (sportId) => sportId
)

const getLiveSportsCountersList = (state: RootState) =>
  selectSportsCountersList(
    state,
    getMenuTabSportsCounterType(ESportsMenuTabTypes.LIVE)
  )

export const selectLiveSportsCounters = createSelector(
  [
    getTopEventsIdsBySport,
    getEvents,
    getLiveEventsFilters,
    getLiveSportsCountersList
  ],
  (topEventsIdsBySport, events, filters, sportsCountersList) => {
    const sportIds = Object.keys(topEventsIdsBySport).filter((sportId) =>
      topEventsIdsBySport[sportId].some((id) => events[id]?.live === true)
    )
    return !filters.isTop
      ? sportsCountersList
      : sportsCountersList.filter((sportCounter) =>
          sportIds.map(Number).includes(sportCounter.id)
        )
  }
)

export const selectIsExistBroadcastForCurrentEvents = createSelector(
  [getMainPageLiveEventsIds, selectBroadcastsAvailabilityMapping],
  (eventsIdsBySport, broadcastsMap) =>
    getIsBroadcastFilterButtonEnabled({ eventsIdsBySport, broadcastsMap })
)
