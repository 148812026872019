import { FC } from 'react'
import { Tournament } from 'betweb-openapi-axios'
import { useDispatch, useSelector } from 'react-redux'
import {
  ESportsCodes,
  selectSport
} from 'astra-core/containers/CommonDataProvider'
import { useTranslation } from 'react-i18next'
import { searchProviderActions } from 'astra-core/containers/SearchProvider'

import { Typography } from 'shared/ui/typography'
import { getSearchTournamentLink } from 'shared/lib/events'
import { SportIcon } from 'shared/ui/Icon/sport-icon/sport-icon-loadable'
import { Hotkey } from 'features/global-search/ui/hotkey'
import { IconChevronRightOutline16 } from 'shared/ui/Icon/General/icon-chevron-right-outline-16'
import { useGlobalSearchContext } from 'features/global-search/lib'
import { SearchHighlighter } from 'features/global-search/ui/search-highlighter'

import {
  MainInfo,
  SearchTournamentItemWrapper,
  StyledRowIconsWrapper
} from './search-tournament-item.styled'

interface Props {
  tournament: Tournament
}

export const SearchTournamentItem: FC<Props> = ({ tournament }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const sport = useSelector((state) => selectSport(state, tournament.sportId))
  const link = getSearchTournamentLink(tournament)
  const { setIsOpen } = useGlobalSearchContext()

  const handleLinkClick = () => {
    setIsOpen(false)
    dispatch(searchProviderActions.setSearch({ valueSearch: '' }))
  }

  return (
    <SearchTournamentItemWrapper
      to={link}
      data-focusable
      onClick={handleLinkClick}
    >
      <SportIcon
        numericSize={14}
        size="s"
        sport={sport?.code as ESportsCodes}
      />
      <MainInfo>
        <SearchHighlighter
          font="Body / 12 SemiBold"
          lineClamp={1}
          text={tournament.name}
        />
        <Typography color="text-secondary-2" font="Hint / 10 SemiBold">
          {tournament.totalEventCount} {t('events count')}
        </Typography>
      </MainInfo>
      <StyledRowIconsWrapper>
        <Hotkey />
        <IconChevronRightOutline16 colorToken="icon-secondary-3" />
      </StyledRowIconsWrapper>
    </SearchTournamentItemWrapper>
  )
}
