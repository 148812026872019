import React, { FC } from 'react'

import { TableHeaderProps } from './table-header.types'
import * as S from './table-header.styled'

export const TableHeader: FC<TableHeaderProps> = ({
  date,
  // status,
  id,
  amount,
  method,
  operationType
}) => (
  <S.TableHeaderStyled>
    <S.Item>{date}</S.Item>
    <S.Item>{id}</S.Item>
    <S.Item>{operationType}</S.Item>
    <S.Item>{method}</S.Item>
    <S.Item>{amount}</S.Item>
    {/* <S.Item>{status}</S.Item> */}
  </S.TableHeaderStyled>
)
