import styled, { css, keyframes } from 'styled-components'
import { CSSProperties } from 'react'

const skeletonLoading = keyframes`
  100% {
    transform: translateX(300%);
  }
`

export const StyledContentLoader = styled.div<{
  baseColor: string
  highlightColor: string
  animationDuration: CSSProperties['animationDuration']
  animationDirection?: CSSProperties['animationDirection']
  borderRadius: CSSProperties['borderRadius']
}>`
  ${({
    baseColor,
    highlightColor,
    animationDirection = 'normal',
    animationDuration,
    borderRadius
  }) => css`
    background: ${baseColor};
    clip-path: inset(
      0 0 0 0 round
        ${typeof borderRadius === 'string' ? borderRadius : `${borderRadius}px`}
    );
    flex-shrink: 0;
    overflow: hidden;

    &::after {
      animation-direction: ${animationDirection};
      animation-duration: ${animationDuration};
      animation-iteration-count: infinite;
      animation-name: ${skeletonLoading};
      animation-timing-function: ease-in-out;
      background: linear-gradient(
          90deg,
          transparent,
          ${highlightColor},
          transparent
        )
        no-repeat;
      bottom: 0;
      content: ' ';
      height: 100%;
      left: 0;
      pointer-events: none;
      position: fixed;
      top: 0;
      transform: translateX(-100%);
      width: 25%;
    }
  `};

  overflow: hidden;
  position: relative;
`
