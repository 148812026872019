import React, { FC, useCallback, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import iframeResize from 'iframe-resizer/js/iframeResizer.min'
import { accountProviderActions } from 'astra-core/containers/AccountProvider'
import {
  EPostMessageIdEvent,
  useGetPostAuthServiceMessage
} from 'astra-core/containers/AuthMessageProvider'

import { UnauthorizedModalLoader } from 'widgets/auth/components/unauthorized-modal/unauthorized-modal.loader'
import { IIFrame } from 'widgets/auth/auth.types'
import {
  AuthWindow,
  Iframe,
  IframeVisibilityControl,
  IframeWrapper
} from 'widgets/auth/components/unauthorized-modal/unauthorized-modal.styled'
import { useGetIframeData } from 'widgets/auth/hooks'

export const UnauthorizedModal: FC = () => {
  const dispatch = useDispatch()
  const modalWrapperRef = useRef<HTMLDivElement>(null)
  const [isLoading, setIsLoading] = useState(true)

  const iframeData = useGetIframeData()

  const hideModalWindow = useCallback(() => {
    dispatch(accountProviderActions.setTypeAuthModal(null))
  }, [dispatch])

  const setSuccessfulLoading = useCallback(() => {
    setIsLoading(false)
    iframeResize(
      {
        log: false,
        checkOrigin: false
      },
      `#${iframeData.id}`
    )
  }, [iframeData])

  const backDropHandler = useCallback(
    (e) => {
      if (modalWrapperRef?.current === e.target) {
        hideModalWindow()
      }
    },
    [hideModalWindow]
  )

  useGetPostAuthServiceMessage(EPostMessageIdEvent.close, hideModalWindow)

  return (
    <AuthWindow ref={modalWrapperRef} onClick={backDropHandler}>
      <IframeWrapper>
        {isLoading && <UnauthorizedModalLoader />}
        <IframeVisibilityControl isLoading={isLoading}>
          <IFrame setSuccessfulLoading={setSuccessfulLoading} />
        </IframeVisibilityControl>
      </IframeWrapper>
    </AuthWindow>
  )
}

const IFrame: FC<IIFrame> = ({ setSuccessfulLoading }) => {
  const { id, src, styles } = useGetIframeData()

  const handleAuthIFrameLoad = useCallback(() => {
    setSuccessfulLoading()
  }, [setSuccessfulLoading])

  return (
    <Iframe
      id={id}
      src={src}
      style={styles}
      title="iframeAuth"
      onLoad={handleAuthIFrameLoad}
    />
  )
}
