import { FC, useCallback } from 'react'
import { selectAccountBonus } from 'astra-core/containers/AccountProvider'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  basketProviderActions,
  selectBasketBonusWallet
} from 'astra-core/containers/BasketProvider'

import { IconBonus } from 'shared/ui/Icon/General/IconBonus'
import { Switch } from 'shared/ui/Switch'
import { Typography } from 'shared/ui/typography'
import { ESwitchView } from 'shared/ui/Switch/types'
import { formatMonetaryAmount } from 'shared/lib/format/formatMonetaryAmount'

import { StyledBasketBonusWrapper, StyledGapRow } from './BasketBonus.styled'

export const BasketBonus: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const bonus = useSelector(selectAccountBonus)
  const bonusWallet = useSelector(selectBasketBonusWallet)

  const handleChange = useCallback(() => {
    dispatch(basketProviderActions.setBonusWallet(!bonusWallet))
  }, [bonusWallet, dispatch])

  return (
    <StyledBasketBonusWrapper>
      <StyledGapRow>
        <IconBonus />
        <Typography color="text-primary-2" font="Body / 12 Medium">
          {t('use bonuses')}
        </Typography>
        <Typography color="text-primary-2" font="Body / 12 Bold">
          {formatMonetaryAmount(bonus)}
        </Typography>
      </StyledGapRow>
      <Switch
        active={bonusWallet}
        view={ESwitchView.BONUS}
        onClick={handleChange}
      />
    </StyledBasketBonusWrapper>
  )
}
