import { useSelector } from 'react-redux'
import {
  selectSearchEventsItems,
  selectSearchTournamentsItems
} from 'astra-core/containers/SearchProvider'
import { FC, useEffect, useMemo } from 'react'
import { selectSportsList } from 'astra-core/containers/CommonDataProvider/selectors'
import { Sport } from 'betweb-openapi-axios'
import { useTranslation } from 'react-i18next'

import { EAdditionalSportsCodes } from 'shared/ui/Icon/sport-icon/sport-icon.types'
import { SportTabs } from 'features/sport-tabs'
import { GlobalSearchTabs } from 'features/global-search'

interface Props {
  activeSport: Sport['id']
  onChange: (sportId: Sport['id']) => void
  selectedTab: GlobalSearchTabs
}

export const GlobalSearchSports: FC<Props> = ({
  activeSport,
  onChange,
  selectedTab
}) => {
  const { t } = useTranslation()
  const events = useSelector(selectSearchEventsItems)
  const tournaments = useSelector(selectSearchTournamentsItems)
  const sportsList = useSelector(selectSportsList)

  const countsMap = useMemo<Record<Sport['id'], number>>(() => {
    const map: Record<Sport['id'], number> = {}

    if (selectedTab !== GlobalSearchTabs.Tournaments) {
      events.forEach((event) => {
        if (map[event.tournament.sportId]) {
          map[event.tournament.sportId] += 1
        } else {
          map[event.tournament.sportId] = 1
        }
      })
    }

    if (selectedTab !== GlobalSearchTabs.Events) {
      tournaments.forEach((tournament) => {
        if (map[tournament.sportId]) {
          map[tournament.sportId] += 1
        } else {
          map[tournament.sportId] = 1
        }
      })
    }

    map[0] =
      Number(selectedTab !== GlobalSearchTabs.Tournaments) * events.length +
      Number(selectedTab !== GlobalSearchTabs.Events) * tournaments.length

    return map
  }, [events, selectedTab, tournaments])

  const sports = useMemo(() => {
    const eventSportIds = events.map((e) => e.tournament.sportId)
    const tournamentSportIds = tournaments.map((t) => t.sportId)

    const sportIdsSet = new Set([...eventSportIds, ...tournamentSportIds])

    const presentSports = sportsList.filter((s) => sportIdsSet.has(s.id))

    presentSports.unshift({
      id: 0,
      name: t('whole sport'),
      top: false,
      code: EAdditionalSportsCodes.ALL_SPORTS
    })

    return presentSports.filter((s) => s.id === 0 || !!countsMap[s.id])
  }, [countsMap, events, sportsList, t, tournaments])

  useEffect(() => {
    if (
      selectedTab === GlobalSearchTabs.Events &&
      !events.filter((e) => e.tournament.sportId === activeSport).length
    ) {
      onChange(0)
    }

    if (
      selectedTab === GlobalSearchTabs.Tournaments &&
      !tournaments.filter((t) => t.sportId === activeSport).length
    ) {
      onChange(0)
    }
  }, [activeSport, events, onChange, selectedTab, tournaments])

  return (
    <SportTabs
      activeSport={activeSport}
      countsMap={countsMap}
      items={sports}
      leftOffset={0}
      rightOffset={0}
      withCount
      onChange={onChange}
    />
  )
}
