import { useSelector } from 'react-redux'

import {
  selectActivePaymentMethodKey,
  BalanceOperationsTypes
} from '../../../model'

export const useGetActivePaymentMethodVariantKey = (
  balanceOperationType: BalanceOperationsTypes
) => {
  return useSelector((state) =>
    selectActivePaymentMethodKey(state, balanceOperationType)
  )
}
