import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  betsHistoryProviderActions,
  selectBetsHistoryFiltersDates,
  selectBetsHistorySelect
} from 'astra-core/containers/BetsHistoryProvider'

import { DatePickerRange } from 'shared/ui/DatePicker'

import { CURRENT_DATE } from './constants'

export const PickerPeriod = () => {
  const dispatch = useDispatch()
  const dates = useSelector(selectBetsHistoryFiltersDates)
  const selectValue = useSelector(selectBetsHistorySelect)

  const onChangeDatePicker = useCallback(
    (dateRangeArray) => {
      dispatch(
        betsHistoryProviderActions.setDatePickerRangeValues(dateRangeArray)
      )
    },
    [dispatch]
  )

  const onChangeInputDatePicker = useCallback(
    (data) =>
      dispatch(betsHistoryProviderActions.setDatePickerRangeInputValue(data)),
    [dispatch]
  )

  const onChangeSelect = useCallback(
    (dateRangeArray, value) => {
      dispatch(
        betsHistoryProviderActions.setDatePickerRangeValues(dateRangeArray)
      )

      dispatch(betsHistoryProviderActions.setDatePickerSelectDatePeriod(value))
    },
    [dispatch]
  )

  const onCalendarClose = useCallback(
    (value) => dispatch(betsHistoryProviderActions.handleCalendarClose(value)),
    [dispatch]
  )

  return (
    <DatePickerRange
      maxDate={CURRENT_DATE}
      popperPlacement="bottom-end"
      rangeCalendarDates={dates}
      selectValue={selectValue}
      // @ts-ignore
      onCalendarClose={onCalendarClose}
      onChange={onChangeDatePicker}
      onChangeInputRange={onChangeInputDatePicker}
      onChangeSelect={onChangeSelect}
    />
  )
}
