import React from 'react'
import { useTranslation } from 'react-i18next'

import { useCalendarEventsContainer } from 'containers/CalendarEventsContainer'
import { actions as calendarEventsContainerActions } from 'containers/CalendarEventsContainer/slice'
import {
  ColumnLayout,
  ColumnLayoutContent,
  ColumnLayoutScroll,
  ColumnTypes
} from 'shared/lib/layout'
import { CalendarMenu } from 'widgets/calendar-menu'
import { NotificationsPanel } from 'widgets/notifications-panel'
import { TitleLinePage } from 'shared/ui/TitleLinePage'
import { ESportsMenuTypes } from 'widgets/events-table/ui/events-table/events-table.types'
import { Filters } from 'pages/page-calendar/CalendarEvents/components'
import { FooterOfPage } from 'widgets/footer-of-page'
import { RightBasketContainer } from 'widgets/basket-aside'
import { LayoutPrimary } from 'layouts/primary'

import { CalendarEvents } from './CalendarEvents'

export const PageCalendar = () => {
  const [t] = useTranslation()
  useCalendarEventsContainer()
  // TODO add broadcast
  // useFetchBroadcastsAvailability()

  return (
    <LayoutPrimary>
      <ColumnLayout type={ColumnTypes.THREE_COLUMN_WIDE_FILTER}>
        <CalendarMenu />

        <NotificationsPanel />

        <TitleLinePage
          title={t('live schedule')}
          type={ESportsMenuTypes.CALENDAR}
        >
          <Filters />
        </TitleLinePage>

        <ColumnLayoutScroll>
          <ColumnLayoutContent isHideHeader>
            <CalendarEvents />

            <FooterOfPage />
          </ColumnLayoutContent>
        </ColumnLayoutScroll>

        <RightBasketContainer />
      </ColumnLayout>
    </LayoutPrimary>
  )
}

export const pageCalendarPrefetch = ({ store }) => {
  store.dispatch(calendarEventsContainerActions.fetchScheduledEvents({}))
}

export default PageCalendar
