import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { BetStatus } from 'betweb-openapi-axios'
import { SetDatePickerRangeValuesPayload } from 'astra-core/containers/BetsHistoryProvider'
import { dayjsCore } from 'astra-core'

import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import { betsHistoryApi, resetBetsHistory } from 'entities/legacy-api'
import { BetsHistoryLegacyContextProvider } from 'entities/bet-history/lib/bet-history-legacy.context'

import { Filter, SectionSelect } from './components'
import {
  StyledColumn,
  StyledHistory,
  StyledListWrapper
} from './History.styled'
import { HistoryListLegacy } from './components/list'

const { useGetBetsHistoryQuery } = betsHistoryApi

const BETS_HISTORY_PAGE_SIZE = 10

export const HistoryLegacy = () => {
  const [pagination, setPagination] = useState({
    limit: BETS_HISTORY_PAGE_SIZE,
    offset: 0
  })
  const [betsStatus, setBetsStatus] = useState<BetStatus>(BetStatus.Unknown)
  const [betsDateRange, setBetsDateRange] =
    useState<SetDatePickerRangeValuesPayload>([null, null])

  const dateFilter = useMemo(() => {
    const [startDate, endDate] = betsDateRange

    if (!startDate || !endDate) return {}

    const date = dayjsCore(startDate).format('DD-MM-YYYY')
    const dateEnd = dayjsCore(endDate).format('DD-MM-YYYY')

    return { date, date_end: dateEnd }
  }, [betsDateRange])

  const dispatch = useDispatch()

  const filter = useMemo(() => {
    switch (betsStatus) {
      case BetStatus.Computed:
        return '10000'
      case BetStatus.Current:
        return '01000'
      case BetStatus.Won:
        return '00100'
      case BetStatus.Lost:
        return '00010'
      case BetStatus.Returned:
        return '00001'
      default:
        return '00000'
    }
  }, [betsStatus])

  const { data, isFetching } = useGetBetsHistoryQuery({
    time_shift: 0,
    filter,
    ...pagination,
    ...dateFilter,
    offset: pagination.offset / BETS_HISTORY_PAGE_SIZE
  })

  useEffect(() => {
    return () => {
      dispatch(resetBetsHistory())
    }
  }, [dispatch])

  const betsList = data?.data.bet_list ?? []
  const totalCount = data?.data.count ?? 0

  const hasMore = totalCount > betsList.length
  const betIds = useMemo(
    () => data?.data.bet_list.map((b) => b.bet_id) ?? [],
    [data]
  )

  const handleBetStatusChange = (newStatus: BetStatus) => {
    setBetsStatus(newStatus)
    setPagination({
      offset: 0,
      limit: BETS_HISTORY_PAGE_SIZE
    })
  }

  return (
    <BetsHistoryLegacyContextProvider betIds={betIds}>
      <StyledHistory>
        <SectionSelect
          betsStatus={betsStatus}
          setBetsStatus={handleBetStatusChange}
        />
        <Filter
          onChangeDatePicker={setBetsDateRange}
          onChangeSelect={setBetsDateRange}
        />

        <StyledColumn>
          {isFetching && pagination.offset === 0 ? (
            <LoaderSpinner />
          ) : (
            <StyledListWrapper
              pagination={{
                pagination,
                onSetPagination: setPagination,
                hasMore,
                isLoading: isFetching
              }}
            >
              <HistoryListLegacy bets={betsList} />
            </StyledListWrapper>
          )}
        </StyledColumn>
      </StyledHistory>
    </BetsHistoryLegacyContextProvider>
  )
}
