import { FunctionComponent, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  betsHistoryProviderActions,
  selectBetsHistoryFiltersDates,
  selectBetsHistorySelect,
  SetDatePickerRangeValuesPayload
} from 'astra-core/containers/BetsHistoryProvider'

import { DatePickerRange } from 'shared/ui/DatePicker'

import { CURRENT_DATE } from './constants'

export type PickerPeriodProps = {
  onChangeDatePicker?: (dateRangeArray: SetDatePickerRangeValuesPayload) => void
  onChangeSelect?: (dateRangeArray: SetDatePickerRangeValuesPayload) => void
}

export const PickerPeriod: FunctionComponent<PickerPeriodProps> = ({
  onChangeDatePicker: onChangeDatePickerProp,
  onChangeSelect: onChangeSelectProp
}) => {
  const dispatch = useDispatch()
  const dates = useSelector(selectBetsHistoryFiltersDates)
  const selectValue = useSelector(selectBetsHistorySelect)

  const onChangeDatePicker = (dateRangeArray) => {
    dispatch(
      betsHistoryProviderActions.setDatePickerRangeValues(dateRangeArray)
    )

    onChangeDatePickerProp?.(dateRangeArray)
  }

  const onChangeInputDatePicker = useCallback(
    (data) =>
      dispatch(betsHistoryProviderActions.setDatePickerRangeInputValue(data)),
    [dispatch]
  )

  const onChangeSelect = (dateRangeArray, value) => {
    dispatch(
      betsHistoryProviderActions.setDatePickerRangeValues(dateRangeArray)
    )

    dispatch(betsHistoryProviderActions.setDatePickerSelectDatePeriod(value))

    onChangeSelectProp?.(dateRangeArray)
  }

  const onCalendarClose = useCallback(
    (value) => dispatch(betsHistoryProviderActions.handleCalendarClose(value)),
    [dispatch]
  )

  return (
    <DatePickerRange
      maxDate={CURRENT_DATE}
      popperPlacement="bottom-end"
      rangeCalendarDates={dates}
      selectValue={selectValue}
      // @ts-ignore
      onCalendarClose={onCalendarClose}
      onChange={onChangeDatePicker}
      onChangeInputRange={onChangeInputDatePicker}
      onChangeSelect={onChangeSelect}
    />
  )
}
