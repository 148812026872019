import React from 'react'
import { sportCountersProviderActions } from 'astra-core/containers/SportCountersProvider'

import { getRouteParamAtIndex } from 'pages/lib/getRouteParamAtIndex'
import { LayoutEventsTable } from 'layouts/events-table'
import { LayoutPrimary } from 'layouts/primary'

import { lineTournamentsContainerActions } from './LineTournamentsContainer/slice'
import { LineTournamentsContainer } from './LineTournamentsContainer'
import { getFetchLineTournamentsReqData } from './LineTournamentsContainer/utils'

export const PageLineTournament = () => (
  <LayoutPrimary>
    <LayoutEventsTable>
      <LineTournamentsContainer />
    </LayoutEventsTable>
  </LayoutPrimary>
)

export const pageLineTournamentsPrefetch = ({ store, req }) => {
  store.dispatch(
    lineTournamentsContainerActions.fetchLineTournaments(
      getFetchLineTournamentsReqData({
        sportId: getRouteParamAtIndex(req.params, 1)
      })
    )
  )
  store.dispatch(sportCountersProviderActions.fetchLineSportCounters({}))
}

export default PageLineTournament
