import { MouseEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  searchProviderActions,
  selectRecentSearch
} from 'astra-core/containers/SearchProvider'
import { useTranslation } from 'react-i18next'

import {
  ClearButton,
  RecentSearchItem,
  RecentSearchList,
  RecentSearchTitle,
  RecentSearchWrapper
} from 'features/global-search/ui/recent-search/recent-search.styled'
import { Typography } from 'shared/ui/typography'
import { IconTrashFilled } from 'shared/ui/Icon/General/IconTrashFilled'
import { IconClockFilled24 } from 'shared/ui/Icon/General/icon-clock-filled-24'
import {
  useGlobalSearchContext,
  useGlobalSearchValueContext
} from 'features/global-search'

export const RecentSearch = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const recentSearch = useSelector(selectRecentSearch)
  const { setValue } = useGlobalSearchValueContext()
  const { setIsOpen } = useGlobalSearchContext()

  if (!recentSearch.length) {
    return null
  }

  const handleClearClick = (event: MouseEvent) => {
    event.stopPropagation()
    // @ts-ignore
    dispatch(searchProviderActions.setRecentSearch([]))
    setIsOpen(false)
  }

  const handleItemClick = (item: string) => (event: MouseEvent) => {
    event.stopPropagation()
    setValue(item)
  }

  return (
    <RecentSearchWrapper>
      <RecentSearchTitle>
        <Typography color="text-secondary-2" font="Hint / 10 BOLD">
          {t('you recently searched')}
        </Typography>
        <ClearButton onClick={handleClearClick}>
          <IconTrashFilled colorToken="icon-secondary-2" size={12} />
          <Typography color="text-secondary-3" font="Body / 12 Medium">
            {t('clear')}
          </Typography>
        </ClearButton>
      </RecentSearchTitle>
      <RecentSearchList>
        {recentSearch.slice(0, 3).map((searchItem) => (
          <RecentSearchItem
            key={searchItem}
            onClick={handleItemClick(searchItem)}
          >
            <IconClockFilled24 colorToken="icon-secondary-3" size={16} />
            <Typography color="text-description" font="Body / 12 SemiBold">
              {searchItem}
            </Typography>
          </RecentSearchItem>
        ))}
      </RecentSearchList>
    </RecentSearchWrapper>
  )
}
