import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import {
  accountProviderActions,
  selectTypeAuthModal
} from 'astra-core/containers/AccountProvider'
import Cookies from 'js-cookie'

const AUTH_COOKIE_NAMES = ['ussid', 'BWSESSION']

const hasAuthCookies = () => {
  const CHECK_AUTH_TRANSIT_COOKIE_NAME = 'ussid'
  const CHECK_AUTH_CLEAN_COOKIE_NAME = 'BWSESSION'

  return !!(
    Cookies.get(CHECK_AUTH_TRANSIT_COOKIE_NAME) ??
    Cookies.get(CHECK_AUTH_CLEAN_COOKIE_NAME)
  )
}

export const useAuth = () => {
  const location = useLocation<{ showAuthModal: boolean }>()
  const history = useHistory()
  const dispatch = useDispatch()

  const typeAuthModal = useSelector(selectTypeAuthModal)

  const hideAuthModal = useCallback(() => {
    if (location.state?.showAuthModal) {
      history.replace({
        state: {
          showAuthModal: false
        }
      })
    }
  }, [history, location.state?.showAuthModal])

  const showAuthModal = useCallback(() => {
    dispatch(accountProviderActions.setTypeAuthModal(null))
  }, [dispatch])

  useEffect(() => {
    if (location.state?.showAuthModal) {
      showAuthModal()
    }
  }, [location.state?.showAuthModal, showAuthModal])

  useEffect(() => {
    if (typeAuthModal) {
      hideAuthModal()
    }
  }, [typeAuthModal, hideAuthModal])

  useEffect(() => {
    const onCookieChange = () => {
      if (hasAuthCookies()) {
        dispatch(accountProviderActions.getUser({}))
      }
    }

    if (window.cookieStore) {
      cookieStore.addEventListener('change', (event) => {
        if (
          event.changed.some(
            (changedCookie) =>
              AUTH_COOKIE_NAMES.includes(changedCookie.name ?? '') &&
              changedCookie.value
          )
        ) {
          onCookieChange()
        }
      })

      return () => {
        cookieStore.removeEventListener('change', onCookieChange)
      }
    } else {
      let lastCookie = AUTH_COOKIE_NAMES.map((authCookieName) =>
        Cookies.get(authCookieName)
      )
      const CHECK_COOKIE_TIMEOUT = 100
      const intervalId = window.setInterval(() => {
        const cookie = AUTH_COOKIE_NAMES.map((authCookieName) =>
          Cookies.get(authCookieName)
        )
        if (
          cookie.some(
            (_, cookieIndex) => cookie[cookieIndex] !== lastCookie[cookieIndex]
          )
        ) {
          onCookieChange()
          lastCookie = cookie
        }
      }, CHECK_COOKIE_TIMEOUT)

      return () => {
        window.clearInterval(intervalId)
      }
    }
  }, [dispatch])
}
