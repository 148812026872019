import styled, { css } from 'styled-components'

import { STYLE_MAIN_MAX_SCREEN_WIDTH } from 'constants/styles'
import { IconButton } from 'shared/ui/buttons/IconButton'

export const CarouselContainer = styled.div`
  ${({ theme }) => css`
    container: carousel / inline-size;
    display: flex;
    flex-direction: column;
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const CarouselWrapper = styled.div`
  display: grid;
  position: relative;

  &:hover button {
    display: block;
  }
`

export const CarouselTrackWrapper = styled.div`
  overflow: hidden;
`

export const CarouselTrack = styled.div<{
  leftOffset: number
  rightOffset: number
  childMinWidth: number
  childMaxWidth: number
  fillPage: boolean
  slidesGap: number
  itemsLength: number
  isCommonSize: boolean
}>`
  ${({
    leftOffset,
    childMinWidth,
    fillPage,
    childMaxWidth,
    slidesGap,
    itemsLength,
    isCommonSize
  }) => css`
    -ms-overflow-style: none;
    cursor: pointer;
    display: grid;
    gap: ${slidesGap}px;

    /* Remove scrollbar */
    &::-webkit-scrollbar {
      display: none;
    }

    grid-auto-flow: column; /* IE and Edge */
    overflow: auto;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;

    width: 100%;
    ${isCommonSize
      ? css`
          grid-auto-columns: ${childMinWidth}px;
        `
      : getItemsLayout(
          childMinWidth,
          childMaxWidth,
          slidesGap,
          itemsLength,
          fillPage
        )};

    ${leftOffset &&
    css`
      padding-left: 16px;
      scroll-padding: ${leftOffset}px;
    `}
  `}
`

const getItemsLayout = (
  childMinWidth: number,
  childMaxWidth: number,
  slidesGap: number,
  itemsCount: number,
  fillPage: boolean
) => {
  const rangesCount = Math.floor(
    STYLE_MAIN_MAX_SCREEN_WIDTH / (childMinWidth + slidesGap)
  )
  const result = css``
  const limit = Math.max(rangesCount, itemsCount)

  for (let i = 1; i < limit; i += 1) {
    const curWidth = i * childMinWidth + (i - 1) * slidesGap
    const elemsCount = Math.min(
      Math.floor(curWidth / childMinWidth),
      itemsCount
    )
    const clampMaxWidth =
      childMaxWidth !== Infinity
        ? `${childMaxWidth}px`
        : `${STYLE_MAIN_MAX_SCREEN_WIDTH}px`

    if (fillPage) {
      result.push(css`
        @container carousel (width >= ${curWidth}px) {
          grid-template-columns: repeat(
            ${itemsCount + elemsCount - (itemsCount % elemsCount)},
            clamp(
              ${childMinWidth}px,
              calc(
                (100% - (${slidesGap}px * ${elemsCount - 1})) / ${elemsCount}
              ),
              ${clampMaxWidth}
            )
          );
        }
      `)
    } else {
      result.push(css`
        @container carousel (width >= ${curWidth}px) {
          grid-auto-columns: clamp(
            ${childMinWidth}px,
            calc((100% - (${slidesGap}px * ${elemsCount - 1})) / ${elemsCount}),
            ${clampMaxWidth}
          );
        }
      `)
    }
  }

  return result
}

export const CarouselItem = styled.div<{
  childHeight: number
  childAspectRatio?: number
  isSnapping?: boolean
}>`
  ${({ theme, childHeight, childAspectRatio, isSnapping }) => css`
    height: auto;
    max-height: ${childHeight}px;
    overflow: hidden;
    z-index: 0;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }

    > * {
      border-radius: ${theme.tokens.alias['border-radius'][
        'border-radius-m-8'
      ]}px;
    }
    ${childAspectRatio &&
    css`
      aspect-ratio: ${childAspectRatio};
    `}

    ${isSnapping &&
    css`
      scroll-snap-align: start;
    `};
  `};
`

export const CarouselDots = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.tokens.alias.space['space-xxs-6']}px;
    justify-content: center;
  `}
`
export const CarouselDot = styled.div<{ isActive: boolean }>`
  ${({ theme, isActive }) => css`
    background-color: ${theme.tokens.colors.border['border-settings-hover']};
    border-radius: 50%;
    height: 6px;
    width: 6px;

    ${isActive &&
    css`
      background-color: ${theme.tokens.colors.icon['icon-secondary-2']};
    `}
  `}
`

export const ControlButton = styled(IconButton)<{
  offset?: number
}>`
  display: none;
  font-size: 0;
  position: absolute;
  top: 50%;
  z-index: 10;

  ${({ offset }) => css`
    &[data-dir='prev'] {
      left: 0;
      transform: translate(-50%, -50%) translateX(${offset}px);
    }

    &[data-dir='next'] {
      right: 0;
      transform: translate(50%, -50%) translateX(-${offset}px);
    }
  `}
`
