import React from 'react'

import { getRouteParamAtIndex } from 'pages/lib/getRouteParamAtIndex'
import { getFetchLineReqData } from 'pages/page-line-events/LineContainer/utils'
import { searchTournamentContainerActions } from 'entities/search-tournament'
import { LayoutEventsTable } from 'layouts/events-table'
import { LayoutPrimary } from 'layouts/primary'

import { SearchTournamentEvents } from './ui/line'

export const PageSearchTournaments = () => (
  <LayoutPrimary>
    <LayoutEventsTable>
      <SearchTournamentEvents />
    </LayoutEventsTable>
  </LayoutPrimary>
)

export const pageLinePrefetch = ({ store, req }) => {
  store.dispatch(
    searchTournamentContainerActions.fetchSearchTournament(
      getFetchLineReqData({ tournamentId: getRouteParamAtIndex(req.params, 2) })
    )
  )
}

export default PageSearchTournaments
