import React, { FC, memo, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ESportsMenuTabTypes } from 'widgets/sports-menu'
import { SegmentedControl } from 'shared/ui/segmented-control'

import { ISportsMenuTabProps } from './sports-menu-tabs.types'
import { StyledTabsWrapper } from './sports-menu-tabs.styled'

export const SportsMenuTabs: FC<ISportsMenuTabProps> = memo(
  ({ currentTabMenu, setCurrentTabMenu }) => {
    const [t] = useTranslation()

    const handleTabChange = useCallback(
      (type: ESportsMenuTabTypes) => {
        setCurrentTabMenu(type)
      },
      [setCurrentTabMenu]
    )

    const items = useMemo(
      () => [
        {
          value: ESportsMenuTabTypes.LINE,
          label: t('line')
        },
        {
          value: ESportsMenuTabTypes.LIVE,
          label: t('live')
        }
      ],
      [t]
    )

    return (
      <StyledTabsWrapper>
        <SegmentedControl
          items={items}
          itemSize={28}
          value={currentTabMenu}
          equalItems
          fullWidth
          onChange={handleTabChange}
        />
      </StyledTabsWrapper>
    )
  }
)
