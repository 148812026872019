import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { IBasketError } from 'astra-core/containers/BasketProvider'
import { ALL_BASKET_ERROR_MESSAGES } from 'astra-core/containers/BasketProvider/constants'
import { useSelector } from 'react-redux'
import { selectConfig } from 'astra-core/containers/ConfigProvider'

import { IconWarningTriangle } from 'shared/ui/Icon/General/IconWarningTriangle'
import { BASKET_ERROR_ACTIONS, IFRAME_TOP_UP_URL } from 'entities/basket/lib'
import { ERoutes } from 'shared/types/routes'

import {
  StyledA,
  StyledBasketErrorAction,
  StyledBasketErrorText,
  StyledBasketErrorWrapper,
  StyledIconWrapper,
  StyledLink
} from './BasketError.styled'
import { BasketErrorProps } from './BasketError.types'

export const BasketError: FC<BasketErrorProps> = ({ error }) => {
  const [t] = useTranslation()
  const { IS_IFRAME } = useSelector(selectConfig)
  const { message } = error

  return (
    <StyledBasketErrorWrapper>
      <StyledIconWrapper>
        <IconWarningTriangle />
      </StyledIconWrapper>
      <StyledBasketErrorText>
        <Trans
          components={getBasketErrorTrans(message, IS_IFRAME)}
          i18nKey={message}
          t={t}
        />
      </StyledBasketErrorText>
    </StyledBasketErrorWrapper>
  )
}

const getBasketErrorTrans = (
  message: IBasketError['message'],
  isIframe: boolean
) => {
  if (message === ALL_BASKET_ERROR_MESSAGES.BETS_INSUFFICIENT_BALANCE) {
    return BASKET_ERROR_ACTIONS[message]?.map(({ id, action }) =>
      isIframe && action === ERoutes.ProfileBalance ? (
        <StyledA as="a" href={IFRAME_TOP_UP_URL} target="_top" />
      ) : (
        <StyledLink key={id} to={action} />
      )
    )
  } else if (message === ALL_BASKET_ERROR_MESSAGES.CLIENT_BETS_BLOCK) {
    return BASKET_ERROR_ACTIONS[message]?.map(({ id, action }) => (
      <StyledBasketErrorAction key={id} onClick={action} />
    ))
  } else {
    return []
  }
}
