import React, { FC, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { selectInputSearchLine } from 'astra-core/containers/SearchProvider'

import { lineTournamentsContainerActions } from 'pages/page-line-tournaments/LineTournamentsContainer/slice'
import {
  selectLayoutIsLoading,
  selectLineTournamentsFetchItems
} from 'pages/page-line-tournaments/LineTournamentsContainer/selectors'
import { LineTournamentsParams } from 'pages/page-line-tournaments/LineTournamentsContainer/types'
import { EmptyPageFilter } from 'features/empty-page-filter'
import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import { StyledLoadingIndicatorWrapper } from 'widgets/tournaments-list'
import {
  EventsPageHeader,
  EventsPageHeaderTypes
} from 'widgets/events-page-header'
import { LineTournamentsWrapper } from 'pages/page-line-tournaments/ui/line-tournaments.styled'
import { LineTournamentsGroups } from 'pages/page-line-tournaments/ui/line-tournaments-group'
import { ClearSearchResults } from 'features/clear-search-results'

import { useTournamentsGroupedBySubSport } from '../LineTournamentsContainer/hooks'

export const LineTournaments: FC = () => {
  const dispatch = useDispatch()
  const { sportId } = useParams<LineTournamentsParams>()
  const searchText = useSelector(selectInputSearchLine)

  const { loading } = useSelector(selectLineTournamentsFetchItems)

  const isLayoutIsLoading = useSelector(selectLayoutIsLoading)

  const { isGenericSport, groupedTournaments } =
    useTournamentsGroupedBySubSport({
      sportId: +sportId
    })

  const resetLineFilter = useCallback(() => {
    dispatch(lineTournamentsContainerActions.resetFilters())
  }, [dispatch])

  const hasResults = groupedTournaments.length > 0

  return (
    <LineTournamentsWrapper>
      <EventsPageHeader type={EventsPageHeaderTypes.LineTournaments} />
      {isLayoutIsLoading ? (
        <StyledLoadingIndicatorWrapper>
          <LoaderSpinner />
        </StyledLoadingIndicatorWrapper>
      ) : !isLayoutIsLoading && hasResults ? (
        <LineTournamentsGroups
          groupedTournaments={groupedTournaments}
          showHeader={isGenericSport}
        />
      ) : searchText ? (
        <ClearSearchResults />
      ) : loading && !hasResults ? (
        <LoaderSpinner />
      ) : (
        <EmptyPageFilter onButtonClickResetFilter={resetLineFilter} />
      )}
    </LineTournamentsWrapper>
  )
}
