import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCashoutByBetId,
  selectCurrentCashout,
  betsHistoryProviderActions,
  selectIsMakeCashoutLoading,
  selectCashoutError
} from 'astra-core/containers/BetsHistoryProvider'
import { useTranslation } from 'react-i18next'
import { useGetCurrencyIcon } from 'astra-core/hooks'
import { formatAmountLocaleString } from 'astra-core'

import { RootState } from 'shared/types/store'
import { Tooltip } from 'shared/ui/Tooltip'
import { BasketTooltipProvider } from 'shared/ui/Tooltip/basket-tooltip'

import {
  StyledBasketHistoryCashoutButtonCurrency,
  StyledBasketHistoryCashoutButtonTextWrapper,
  StyledBasketHistoryCashoutModalWholeBackground,
  StyledBasketHistoryCashoutWrapper
} from './BasketHistoryCashout.styled'
import {
  TBasketHistoryCashoutProps,
  TBasketHistoryCashoutTextProps
} from './BasketHistoryCashout.types'
import { CurrentCashoutModal } from './components'
import { MakeCashoutLoading } from './components/CurrentCashoutModal/constants'
import { DISABLE_HOVER_ON_CHILD } from './constants'

export const BasketHistoryCashout: FC<TBasketHistoryCashoutProps> = ({
  id,
  isLastElement
}) => {
  const dispatch = useDispatch()
  const cashout = useSelector((state: RootState) =>
    selectCashoutByBetId(state, id)
  )
  const currentCashout = useSelector(selectCurrentCashout)
  const isMakeCashoutLoading = useSelector(selectIsMakeCashoutLoading)
  const isShowCurrentCashout = !!(currentCashout && currentCashout.betId === id)
  const isCashoutExist = !!(cashout && cashout.amount)
  const cashoutError = useSelector(selectCashoutError)
  const isShowCurrentCashoutModal =
    isShowCurrentCashout && !isMakeCashoutLoading

  const unsetCurrentCashout = (event) => {
    event.stopPropagation()
    dispatch(betsHistoryProviderActions.unsetCurrentCashout())
  }

  const setCurrentCashout = (event) => {
    event.stopPropagation()

    if (isCashoutExist && !isShowCurrentCashout && !isMakeCashoutLoading) {
      dispatch(
        betsHistoryProviderActions.setCurrentCashout({ betId: cashout.betId })
      )
    }
  }

  return (
    <>
      {isCashoutExist || !!cashoutError ? (
        <BasketTooltipProvider>
          <StyledBasketHistoryCashoutWrapper onClick={setCurrentCashout}>
            {isMakeCashoutLoading && isShowCurrentCashout ? (
              <MakeCashoutLoading />
            ) : isCashoutExist ? (
              <BasketHistoryCashoutText {...cashout} />
            ) : null}

            {isShowCurrentCashoutModal && (
              <>
                <CurrentCashoutModal isLastElement={isLastElement} />
                <StyledBasketHistoryCashoutModalWholeBackground
                  onClick={unsetCurrentCashout}
                />
              </>
            )}
          </StyledBasketHistoryCashoutWrapper>
        </BasketTooltipProvider>
      ) : null}
    </>
  )
}

const BasketHistoryCashoutText: FC<TBasketHistoryCashoutTextProps> = ({
  amount
}) => {
  const [t] = useTranslation()
  const currencyIcon = useGetCurrencyIcon()
  const isMakeCashoutLoading = useSelector(selectIsMakeCashoutLoading)

  const text = `${t('sell for')} ${formatAmountLocaleString({
    value: amount
  })}`

  return (
    <Tooltip
      isCommon={!isMakeCashoutLoading}
      title={t('currently impossible to sell a bet')}
    >
      <StyledBasketHistoryCashoutButtonTextWrapper
        className={DISABLE_HOVER_ON_CHILD}
        disabled={isMakeCashoutLoading}
        isLoading={isMakeCashoutLoading}
        view="secondary-medium"
      >
        {text}
        <StyledBasketHistoryCashoutButtonCurrency>
          {currencyIcon}
        </StyledBasketHistoryCashoutButtonCurrency>
      </StyledBasketHistoryCashoutButtonTextWrapper>
    </Tooltip>
  )
}
