import { useTranslation } from 'react-i18next'
import { selectBonusOnExpress } from 'astra-core/containers/BasketProvider'
import { useSelector } from 'react-redux'
import { bonusOnExpressToPercent, useGetCurrencyIcon } from 'astra-core'
import { FC, useMemo } from 'react'
import { selectCurrencyMaxPayout } from 'astra-core/containers/CommonDataProvider'

import { IconInfoCircleFilled } from 'shared/ui/Icon/General/IconInfoCircleFilled'
import { ExpressBonusModal } from 'features/express-bonus-modal/express-bonus-modal'
import { Typography } from 'shared/ui/typography'
import { formatMonetaryAmount } from 'shared/lib/format/formatMonetaryAmount'

import {
  StyledBonus,
  StyledBonusContent,
  StyledBonusPercent,
  StyledBonusSum,
  StyledBonusText,
  StyledBonusWrapper
} from './express-bonus.styled'
import { ExpressBonusProps } from './express-bonus.types'

export const ExpressBonus: FC<ExpressBonusProps> = ({ winSum }) => {
  const [t] = useTranslation()
  const bonus = useSelector(selectBonusOnExpress)
  const bonusAsPercent = bonusOnExpressToPercent(bonus)
  const currencyIcon = useGetCurrencyIcon()

  const maxPayout = useSelector(selectCurrencyMaxPayout)

  const sumWin = useMemo(() => {
    const sum =
      winSum * (bonus - 1) >= maxPayout ? maxPayout : winSum * (bonus - 1)

    return sum
  }, [winSum, maxPayout, bonus])

  const expressBonusRestriction = useMemo(() => {
    const totalSum = +sumWin.toFixed(2) + winSum

    if (totalSum >= maxPayout) {
      return +(sumWin - (totalSum - maxPayout)).toFixed(2)
    }

    return 0
  }, [sumWin, winSum, maxPayout])

  return (
    <StyledBonusWrapper>
      <StyledBonus disabled={winSum >= maxPayout}>
        <StyledBonusContent>
          <StyledBonusText>{t('express bonus')}</StyledBonusText>
          <StyledBonusPercent>{bonusAsPercent}%</StyledBonusPercent>
          <ExpressBonusModal>
            <IconInfoCircleFilled size={16} />
          </ExpressBonusModal>
        </StyledBonusContent>
        <StyledBonusSum>
          {formatMonetaryAmount(+sumWin.toFixed(2))}
          <div>{currencyIcon}</div>
        </StyledBonusSum>
      </StyledBonus>

      {winSum >= maxPayout ? (
        <Typography color="text-description" font="Body / 12 Regular">
          {t('disabled express bonus')}
        </Typography>
      ) : (
        expressBonusRestriction > 0 && (
          <Typography color="text-description" font="Body / 12 Regular">
            {t('limited express bonus 1')}{' '}
            <Typography color="text-description" font="Body / 12 SemiBold">
              {formatMonetaryAmount(expressBonusRestriction)} {currencyIcon}
            </Typography>{' '}
            {t('limited express bonus 2')}
          </Typography>
        )
      )}
    </StyledBonusWrapper>
  )
}
