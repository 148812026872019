import { searchProviderActions } from 'astra-core/containers/SearchProvider'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'

import {
  UseGetHandlerSearchMarketsResetProps,
  UseResetSearchProps
} from './use-search-markets-reset.types'

export const useGetHandlerSearchMarketsReset = ({
  eventId,
  onReset
}: UseGetHandlerSearchMarketsResetProps) => {
  const dispatch = useDispatch()

  const onResetSearch = () => {
    dispatch(
      searchProviderActions.setSearchQuery({
        eventId,
        value: ''
      })
    )

    onReset?.()
  }

  return { onResetSearch }
}

export const useSearchMarketsReset = ({
  onReset,
  eventId,
  isSearchOpen,
  inputRef,
  inputValue
}: UseResetSearchProps) => {
  const { onResetSearch } = useGetHandlerSearchMarketsReset({
    eventId,
    onReset
  })

  useEffect(() => {
    const isInputActive = document.activeElement === inputRef.current

    if (!inputValue.length && !isInputActive && isSearchOpen) {
      onResetSearch()
    }
  }, [onResetSearch, isSearchOpen, inputValue.length, inputRef])

  return { onResetSearch }
}
