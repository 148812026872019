import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import {
  ECashoutStatusView,
  selectCashoutSoldBet,
  selectCashoutStatusView
} from 'astra-core/containers/BetsHistoryProvider'

import { useTimer } from 'hooks'
import { ENotificationContainerIds } from 'shared/lib/Toastify'
import { IconWarningTriangleFilled16 } from 'shared/ui/Icon/General/IconWarningTriangleFilled16'

import {
  TOASTIFY_CASHOUT_ID,
  TToastifyCashoutNotificationProps
} from './WithdrawalFormMethods.types'
import {
  StyledDeleteHistoryHint,
  StyledIconCheckCircleFilled16,
  StyledToastifyCashoutNotificationText,
  StyledToastifyCashoutNotificationTextWrapper
} from './ToastifyCashoutNotification.styled'
import { TOASTIFY_CASHOUT_NOTIFICATION_TIMER } from './constants'

export const ToastifyCashoutNotification: FC<
  TToastifyCashoutNotificationProps
> = ({ toastProps }) => {
  // TODO try to use setTimetout
  const seconds = useTimer(TOASTIFY_CASHOUT_NOTIFICATION_TIMER)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!seconds) {
      toast.update(toastProps.toastId, {
        closeButton: false,
        containerId: ENotificationContainerIds.SYSTEM,
        autoClose: 10,
        draggable: false,
        hideProgressBar: true,
        closeOnClick: false,
        toastId: TOASTIFY_CASHOUT_ID
      })
    }
  }, [dispatch, seconds, toastProps.toastId])

  return (
    <StyledDeleteHistoryHint>
      <ToastifyCashoutNotificationText />
    </StyledDeleteHistoryHint>
  )
}

const ToastifyCashoutNotificationText = () => {
  const [t] = useTranslation()
  const cashoutStatusView = useSelector(selectCashoutStatusView)
  const soldBet = useSelector(selectCashoutSoldBet)

  if (ECashoutStatusView.SUCCESS === cashoutStatusView && soldBet) {
    return (
      <StyledToastifyCashoutNotificationTextWrapper>
        <StyledIconCheckCircleFilled16 colorToken="icon-positive" />

        <StyledToastifyCashoutNotificationText>
          {`${t('bet sold')}`}
        </StyledToastifyCashoutNotificationText>
      </StyledToastifyCashoutNotificationTextWrapper>
    )
    // TODO NIT: no need for else in these cases
  } else {
    return (
      <StyledToastifyCashoutNotificationTextWrapper>
        <IconWarningTriangleFilled16 colorToken="icon-warning" size={16} />

        <StyledToastifyCashoutNotificationText>
          {t('failed to sell bet')}
        </StyledToastifyCashoutNotificationText>
      </StyledToastifyCashoutNotificationTextWrapper>
    )
  }
}
