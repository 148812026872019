import { BetOutcomeStatus, BetStatus, BetType } from 'betweb-openapi-axios'

import {
  LegacyBet,
  LegacyBetEvent,
  legacyBtypeToBetType,
  legacyResultToBetOutcomeStatus,
  legacyResultToBetStatus
} from './types'

export const getBetStatusFromLegacy = (bet: LegacyBet) => {
  if (bet.calc_cashout_sum !== null) {
    return BetStatus.Sold
  }

  if (legacyResultToBetStatus[bet.result]) {
    return legacyResultToBetStatus[bet.result]
  }

  return BetStatus.Unknown
}

export const getBetEventStatusFromLegacy = (betEvent: LegacyBetEvent) => {
  if (legacyResultToBetOutcomeStatus[betEvent.result]) {
    return legacyResultToBetOutcomeStatus[betEvent.result]
  }

  return BetOutcomeStatus.Na
}

export const getBetTypeFromLegacy = (bet: LegacyBet) => {
  if (legacyBtypeToBetType[bet.btype]) {
    return legacyBtypeToBetType[bet.btype]
  }

  return BetType.System
}
