import {
  favouritesProviderActions,
  selectFavouriteEvents,
  selectFavouriteEventsCount
} from 'astra-core/containers/FavouritesProvider'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

import { ERoutes } from 'shared/types/routes'
import { useHandleRedirectList } from 'widgets/header/components/PersonalData/components/ComponentOfAuthorized/hooks'
import { IconFavoritesFilled16 } from 'shared/ui/Icon/General/IconFavoritesFilled16'
import { HIDDEN_FAVORITES_STATUSES } from 'entities/event'

import {
  StyledSportsMenuItemCount,
  StyledSportsMenuItemText
} from '../sports-menu-list/sports-menu-list.styled'

import {
  StyledFavoriteButton,
  StyledFavoriteButtonWrapper,
  StyledIconWrapper
} from './favorite-tab.styled'

export const FavoriteTab = () => {
  const [t] = useTranslation()
  const favouriteEventsCount = useSelector(selectFavouriteEventsCount)
  const handleRedirectList = useHandleRedirectList()
  const location = useLocation()

  const events = useSelector(selectFavouriteEvents)
  const dispatch = useDispatch()

  const hiddenEvents = events.filter((event) =>
    HIDDEN_FAVORITES_STATUSES.includes(event.status)
  )

  useEffect(() => {
    hiddenEvents.forEach((event) => {
      dispatch(
        favouritesProviderActions.deleteFavouriteEvent({ eventId: event.id })
      )
    })
  }, [dispatch, hiddenEvents])

  return (
    <StyledFavoriteButtonWrapper>
      <StyledFavoriteButton
        $isActive={location.pathname === ERoutes.FavouriteEvents}
        onClick={handleRedirectList(ERoutes.FavouriteEvents)}
      >
        <StyledIconWrapper>
          <IconFavoritesFilled16 colorToken="icon-warning" />
        </StyledIconWrapper>

        <StyledSportsMenuItemText>{t('favorite')}</StyledSportsMenuItemText>
        <StyledSportsMenuItemCount>
          {favouriteEventsCount}
        </StyledSportsMenuItemCount>
      </StyledFavoriteButton>
    </StyledFavoriteButtonWrapper>
  )
}
