import { FC } from 'react'
import { LogoEntityType } from 'betweb-openapi-axios/dist/api'
import { useSelector } from 'react-redux'

import { EntityLogoWrapper } from 'features/entity-logo/entity-logo.styled'
import { selectLogoByEntityTypeAndId } from 'entities/logos/selectors'
import { IconLogoTeam } from 'shared/ui/Icon/General/IconLogoTeam'

export const EntityLogo: FC<{
  entityType: LogoEntityType
  entityId?: number
  size?: number
}> = ({ entityId, entityType, size = 24 }) => {
  const entityLogo = useSelector((state) =>
    selectLogoByEntityTypeAndId(state, { entityId, entityType })
  )

  if (!entityLogo?.logo?.logoUrl) {
    return <IconLogoTeam colorToken="icon-opacity" size={size} />
  }

  const { logoUrl } = entityLogo.logo

  return <EntityLogoWrapper backgroundImg={logoUrl} size={size} />
}
