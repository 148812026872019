import { ReactNode } from 'react'

import {
  LegacyPaymentTopUpVariantKeys,
  LegacyPaymentWithdrawalVariantKeys
} from 'entities/legacy-api'
import {
  IconBankCenterCredit,
  IconBankEurasianBank,
  IconBankHalyk,
  IconBankJysan,
  IconBankKaspi,
  IconBankRbk,
  IconBankSimply,
  IconBerekeBank,
  IconCashRegisterPaydala,
  IconGooglePay,
  IconMastercard,
  IconQiwi,
  IconSimActiv,
  IconSimAltel,
  IconSimBeeline,
  IconSimIzi,
  IconSimKcell,
  IconSimTele2,
  IconVisa,
  IconWooppay
} from 'shared/ui/Icon'

export const ICON_TOP_UP_PAYMENT_VARIANT_MAP: Record<
  LegacyPaymentTopUpVariantKeys,
  ReactNode[]
> = {
  [LegacyPaymentTopUpVariantKeys.VISA_MASTERCARD]: [
    <IconVisa colorToken="icon-primary-2" size={{ height: 12, width: 36 }} />,
    <IconMastercard size={{ height: 16, width: 26 }} />
  ],
  [LegacyPaymentTopUpVariantKeys.KASPI_KZ]: [
    <IconBankKaspi
      colorToken="icon-primary-2"
      size={{ height: 16, width: 68 }}
    />
  ],
  [LegacyPaymentTopUpVariantKeys.B_BANK]: [
    <IconBerekeBank
      colorToken="icon-primary-2"
      size={{ height: 16, width: 95 }}
    />
  ],
  [LegacyPaymentTopUpVariantKeys.GOOGLE_PAY]: [
    <IconGooglePay
      colorToken="icon-primary-2"
      size={{ height: 16, width: 40 }}
    />
  ],
  [LegacyPaymentWithdrawalVariantKeys.WOOPPAY]: [
    <IconGooglePay
      colorToken="icon-primary-2"
      size={{ height: 16, width: 40 }}
    />
  ],
  [LegacyPaymentTopUpVariantKeys.ACTIV]: [
    <IconSimActiv
      colorToken="icon-primary-2"
      size={{ height: 16, width: 56 }}
    />
  ],
  [LegacyPaymentTopUpVariantKeys.ALTEL_WOO_PAY]: [
    <IconSimAltel size={{ height: 12, width: 66 }} />
  ],
  [LegacyPaymentTopUpVariantKeys.BEELINE_KZ]: [
    <IconSimBeeline
      colorToken="icon-primary"
      size={{ height: 16, width: 57 }}
    />
  ],
  [LegacyPaymentTopUpVariantKeys.KCELL_KZ]: [
    <IconSimKcell
      colorToken="icon-primary-2"
      size={{ height: 14, width: 42 }}
    />
  ],
  [LegacyPaymentTopUpVariantKeys.TELE2]: [
    <IconSimTele2 colorToken="icon-primary" size={{ height: 16, width: 42 }} />
  ],
  [LegacyPaymentTopUpVariantKeys.QIWI]: [
    <IconQiwi colorToken="icon-primary-2" size={{ height: 16, width: 93 }} />
  ],
  [LegacyPaymentTopUpVariantKeys.WOOPPAY]: [
    <IconWooppay colorToken="icon-primary-2" size={{ height: 16, width: 65 }} />
  ],
  [LegacyPaymentTopUpVariantKeys.KCELL_ACTIVKZ]: [
    <IconSimKcell
      colorToken="icon-primary-2"
      size={{ height: 14, width: 42 }}
    />,
    <IconSimActiv
      colorToken="icon-primary-2"
      size={{ height: 16, width: 56 }}
    />
  ],
  [LegacyPaymentTopUpVariantKeys.QWT]: [
    <IconQiwi colorToken="icon-primary-2" size={{ height: 16, width: 93 }} />
  ],
  [LegacyPaymentTopUpVariantKeys.PAYDALA_KASSA]: [
    <IconCashRegisterPaydala
      colorToken="icon-primary"
      size={{ height: 16, width: 70 }}
    />
  ]
}

export const ICON_WITHDRAWAL_PAYMENT_VARIANT_MAP: Record<
  LegacyPaymentWithdrawalVariantKeys,
  ReactNode[]
> = {
  [LegacyPaymentWithdrawalVariantKeys.BANK_CARD]: [
    <IconBankKaspi
      colorToken="icon-primary-2"
      size={{ height: 16, width: 68 }}
    />,
    <IconVisa colorToken="icon-primary-2" size={{ height: 12, width: 36 }} />,
    <IconMastercard size={{ height: 16, width: 26 }} />
  ],
  [LegacyPaymentWithdrawalVariantKeys.BANK_CARD_FROM_IIN]: [
    <IconBankJysan size={{ height: 10, width: 42 }} />,
    <IconBankRbk colorToken="icon-primary" size={{ height: 16, width: 34 }} />,
    <IconBankCenterCredit size={{ height: 10, width: 65 }} />,
    <IconBankEurasianBank
      colorToken="icon-primary-2"
      size={{ height: 12, width: 40 }}
    />,
    <IconBankHalyk size={{ height: 12, width: 44 }} />
  ],
  [LegacyPaymentWithdrawalVariantKeys.SIMPLY]: [
    <IconBankSimply
      colorToken="icon-primary"
      size={{ height: 14, width: 43 }}
    />
  ],
  [LegacyPaymentWithdrawalVariantKeys.WOOPPAY_BANKS]: [
    <IconWooppay colorToken="icon-primary-2" size={{ height: 16, width: 65 }} />
  ],
  [LegacyPaymentWithdrawalVariantKeys.PAYDALA_BANK]: [
    <IconCashRegisterPaydala
      colorToken="icon-primary"
      size={{ height: 16, width: 70 }}
    />
  ],
  [LegacyPaymentWithdrawalVariantKeys.PAYDALA_ACTIV]: [
    <IconSimActiv
      colorToken="icon-primary-2"
      size={{ height: 16, width: 56 }}
    />
  ],
  [LegacyPaymentWithdrawalVariantKeys.PAYDALA_ALTEL]: [
    <IconSimAltel size={{ height: 12, width: 66 }} />
  ],
  [LegacyPaymentWithdrawalVariantKeys.PAYDALA_BEELINE]: [
    <IconSimBeeline
      colorToken="icon-primary"
      size={{ height: 16, width: 57 }}
    />
  ],
  [LegacyPaymentWithdrawalVariantKeys.PAYDALA_KCELL]: [
    <IconSimKcell
      colorToken="icon-primary-2"
      size={{ height: 14, width: 42 }}
    />
  ],
  [LegacyPaymentWithdrawalVariantKeys.PAYDALA_TELE2]: [
    <IconSimTele2 colorToken="icon-primary" size={{ height: 16, width: 42 }} />
  ],
  [LegacyPaymentWithdrawalVariantKeys.QIWI_WALLET]: [
    <IconQiwi colorToken="icon-primary-2" size={{ height: 16, width: 93 }} />
  ],
  [LegacyPaymentWithdrawalVariantKeys.WOOPPAY]: [
    <IconWooppay colorToken="icon-primary-2" size={{ height: 16, width: 65 }} />
  ],
  [LegacyPaymentWithdrawalVariantKeys.WP_MOBILE]: [
    <IconSimAltel size={{ height: 10, width: 46 }} />,
    <IconSimTele2 colorToken="icon-primary" size={{ height: 11, width: 30 }} />,
    <IconSimBeeline
      colorToken="icon-primary"
      size={{ height: 11, width: 40 }}
    />,
    <IconSimActiv
      colorToken="icon-primary-2"
      size={{ height: 10, width: 29 }}
    />,
    <IconSimKcell
      colorToken="icon-primary-2"
      size={{ height: 9, width: 29 }}
    />,
    <IconSimIzi colorToken="icon-primary" size={{ height: 10, width: 29 }} />
  ],
  [LegacyPaymentWithdrawalVariantKeys.WP_CBC]: [
    <IconBankHalyk size={{ height: 14, width: 52 }} />
  ],
  [LegacyPaymentWithdrawalVariantKeys.QIWI_CBC]: [
    <IconBankHalyk size={{ height: 14, width: 52 }} />
  ],
  [LegacyPaymentWithdrawalVariantKeys.PAYDALA_KASSA]: [
    <IconCashRegisterPaydala
      colorToken="icon-primary"
      size={{ height: 16, width: 70 }}
    />
  ]
}
