import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectConfig } from 'astra-core/containers/ConfigProvider'

import { filterRoutes } from 'shared/lib/routes'
import { ERoutes } from 'shared/types/routes'

export const useGetMenuList = () => {
  const { IS_IFRAME } = useSelector(selectConfig)

  const [t] = useTranslation()
  return filterRoutes({
    isIframe: IS_IFRAME,
    routeList: [
      { path: ERoutes.About, locale: 'about company', hideInIframe: true },
      { path: ERoutes.Root, locale: 'partners', hideInIframe: true },
      {
        path: ERoutes.Documents,
        locale: 'documents'
      }
    ],
    t
  })
}
