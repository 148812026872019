import { FC, PropsWithChildren } from 'react'

import { NotificationsPanel } from 'widgets/notifications-panel'
import { SportsMenu } from 'widgets/sports-menu'
import { FooterOfPage } from 'widgets/footer-of-page'
import { RightBasketContainer } from 'widgets/basket-aside'
import {
  ColumnLayout,
  ColumnLayoutContent,
  ColumnLayoutScroll
} from 'shared/lib/layout'

export const EventsTable: FC<PropsWithChildren> = ({ children }) => (
  <ColumnLayout>
    <SportsMenu />

    <NotificationsPanel />

    <ColumnLayoutScroll>
      <ColumnLayoutContent>
        {children}
        <FooterOfPage />
      </ColumnLayoutContent>
    </ColumnLayoutScroll>

    <RightBasketContainer />
  </ColumnLayout>
)
