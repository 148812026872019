import { ContentLoader } from 'shared/ui/content-loader/content-loader'
import {
  BasketBodyLoaderItem,
  BasketBodyLoaderItems,
  BasketBodyLoaderTitle,
  BasketBodyLoaderWrapper
} from 'features/basket/basket-body/ui/BasketBody.styled'

export const BasketBodyLoader = () => (
  <BasketBodyLoaderWrapper>
    <BasketBodyLoaderTitle>
      <ContentLoader borderRadius={8} height={32} width="100%" />
      <ContentLoader borderRadius={8} height={32} width={32} />
    </BasketBodyLoaderTitle>
    <BasketBodyLoaderItems>
      <BasketBodyLoaderItem>
        <ContentLoader borderRadius={4} height={14} width={176} />
        <ContentLoader borderRadius={4} height={14} width={134} />
        <div>
          <ContentLoader borderRadius={4} height={14} width={14} />
          <ContentLoader borderRadius={4} height={14} width={32} />
        </div>
      </BasketBodyLoaderItem>
      <BasketBodyLoaderItem>
        <ContentLoader borderRadius={4} height={14} width={176} />
        <ContentLoader borderRadius={4} height={14} width={134} />
        <div>
          <ContentLoader borderRadius={4} height={14} width={14} />
          <ContentLoader borderRadius={4} height={14} width={32} />
        </div>
      </BasketBodyLoaderItem>
      <BasketBodyLoaderItem>
        <ContentLoader borderRadius={4} height={14} width={176} />
        <ContentLoader borderRadius={4} height={14} width={134} />
        <div>
          <ContentLoader borderRadius={4} height={14} width={14} />
          <ContentLoader borderRadius={4} height={14} width={32} />
        </div>
      </BasketBodyLoaderItem>
    </BasketBodyLoaderItems>
  </BasketBodyLoaderWrapper>
)
