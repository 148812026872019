import { Event, EventResult, ShortStatistics } from 'betweb-openapi-axios'
import { useSelector } from 'react-redux'
import { selectMatchPhase } from 'astra-core/containers/CommonDataProvider'
import { useEffect, useState } from 'react'
import { useInterval, dayjsCore as dayjs } from 'astra-core'
import duration from 'dayjs/plugin/duration'

import {
  EEventStatisticsCodes,
  TUseTimerCountdownParams
} from 'shared/lib/events/types'

dayjs.extend(duration)

const ENABLED_TIMER_MATCH_PHASE_CODES = [
  'Half_1',
  'Half_2',
  'Extra_1',
  'Extra_2',
  'Quarter_1',
  'Quarter_2',
  'Quarter_3',
  'Quarter_4',
  'Overtime'
]

const parseTimeToSeconds = (time: string) => {
  const [minutes, seconds] = time.split(':').map(Number)

  return minutes * 60 + seconds
}

const secondsToTime = (seconds: number) =>
  dayjs
    .duration({ seconds: seconds % 60, minutes: Math.floor(seconds / 60) })
    .format('mm:ss')

export const useTimerCountdownBase = (
  statistics: Array<ShortStatistics> | undefined
) => {
  const currentTime = statistics?.find(
    (s) => s.code === EEventStatisticsCodes.CurrentTime
  )
  const remainingTime = statistics?.find(
    (s) => s.code === EEventStatisticsCodes.RemainingTime
  )
  const additionalTime = statistics?.find(
    (s) => s.code === EEventStatisticsCodes.AddTime
  )
  const matchPhaseStatistic = statistics?.find(
    (s) => s.code === EEventStatisticsCodes.MatchPhase
  )
  const matchPhase = useSelector((state) =>
    selectMatchPhase(state, +(matchPhaseStatistic?.value ?? 0))
  )

  const availableTimeValue =
    currentTime?.value || remainingTime?.value || '00:00'

  const [timerValue, setTimerValue] = useState(
    parseTimeToSeconds(availableTimeValue)
  )

  useEffect(() => {
    setTimerValue(parseTimeToSeconds(availableTimeValue))
  }, [availableTimeValue])

  const hasTime = !!currentTime || !!remainingTime || false
  const direction = remainingTime !== undefined ? -1 : 1

  const timerEnabled = ENABLED_TIMER_MATCH_PHASE_CODES.includes(
    matchPhase?.code
  )

  useInterval(
    () => {
      if (hasTime && timerEnabled) {
        setTimerValue((prev) => {
          if (prev === 0) {
            return prev
          }

          return prev + direction
        })
      }
    },
    timerEnabled ? 1000 : null
  )

  if (!hasTime) {
    return { hasTime, value: '' }
  }

  return {
    hasTime,
    value: secondsToTime(timerValue),
    addTime: additionalTime?.value
  }
}

export const useTimerCountdown = ({
  event
}: TUseTimerCountdownParams<Event>) => {
  return useTimerCountdownBase(event.statistics)
}

export const useTimerCountdownForResult = ({
  event
}: TUseTimerCountdownParams<EventResult>) => {
  return useTimerCountdownBase(event.liveStatistics)
}
