import { RootState } from 'shared/types/store'

import { initialState } from './slice'

const getLayoutSubstate = (state: RootState) =>
  (state.rightBasketContainerState || initialState).layout

export const selectIsShowBasketOnSmallWindow = (state: RootState) =>
  getLayoutSubstate(state).isShowBasketOnSmallWindow

export const selectIsBasketLoaded = (state: RootState) =>
  getLayoutSubstate(state).isBasketLoaded
