import { FC, useMemo } from 'react'
import { useGroupedEventsByGroupId } from 'astra-core/hooks/useEvents'
import { EventsGroup } from 'astra-core/hooks/useEvents/types'
import { useSelector } from 'react-redux'

import { EventTableRow, EventTableHeader } from 'features/events-table'
import {
  selectEntityTags,
  sortByEntityTags,
  useSortByIncludingTags
} from 'entities/event'
import { selectLineTournaments } from 'pages/page-line-tournaments/LineTournamentsContainer/selectors'
import { RootState } from 'shared/types/store'
import { OutcomesComponentType, OutcomesGrid } from 'widgets/outcomes-grid'

import {
  EventsTableProps,
  EventTournamentsTableProps
} from './events-table.types'
import * as S from './events-table.styled'

// TODO remove and return old logic
const highPositionGroups = [
  29448, 29572, 29579, 29580, 27891, 25307, 29233, 29577, 29634, 29635, 29636,
  29412, 25410
]

const byPriorityPosition = (a: EventsGroup, b: EventsGroup): number => {
  if (highPositionGroups.includes(a.id) && !highPositionGroups.includes(b.id)) {
    return -1
  }
  if (!highPositionGroups.includes(a.id) && highPositionGroups.includes(b.id)) {
    return 1
  }
  if (
    !highPositionGroups.includes(a.id) &&
    !highPositionGroups.includes(b.id)
  ) {
    return 0
  }
  return highPositionGroups.indexOf(a.id) - highPositionGroups.indexOf(b.id)
}

export const EventsTable: FC<EventsTableProps> = ({
  events,
  headerProps,
  rowProps,
  topHeaderCodesOnly,
  isBorderRadius,
  sortByTags
}) => {
  const eventsGroups = useGroupedEventsByGroupId(events)

  const entityTags = useSelector(selectEntityTags)

  const sortedGroupByPriority = eventsGroups.slice().sort(byPriorityPosition)

  const sortedGroupByTags = useMemo(
    () =>
      sortedGroupByPriority.map((item) => {
        const sortedEvents = sortByEntityTags(item.events, entityTags)
        return { ...item, events: sortedEvents }
      }),
    [sortedGroupByPriority, entityTags]
  )

  const sortByIncludindTags = useSortByIncludingTags(sortedGroupByTags)
  const finalSorted = useMemo(
    () => sortByEntityTags(sortByIncludindTags, entityTags),
    [sortByIncludindTags, entityTags]
  )

  return (
    <S.EventsTableGroupsWrapper>
      {(sortByTags ? finalSorted : sortedGroupByPriority).map(
        (group, index, { length }) => (
          <S.EventsTableWrapper
            isBottomBorderRadius={isBorderRadius && length - 1 === index}
            isTopBorderRadius={isBorderRadius && index === 0}
            key={group.id}
          >
            <thead>
              <EventTableHeader
                hideOutcomeCodes={index > 0 && topHeaderCodesOnly}
                tournament={group}
                withIcon
                {...headerProps}
              />
            </thead>
            <tbody>
              {group.events.map((event) => (
                <EventTableRow
                  Outcomes={
                    <OutcomesGrid
                      eventId={event.id}
                      type={OutcomesComponentType.EVENTS_LIST}
                    />
                  }
                  event={event}
                  key={event.id}
                  {...rowProps}
                />
              ))}
            </tbody>
          </S.EventsTableWrapper>
        )
      )}
    </S.EventsTableGroupsWrapper>
  )
}

export const EventTournamentsTable: FC<EventTournamentsTableProps> = ({
  group,
  headerProps,
  topHeaderCodesOnly
}) => {
  const entityTags = useSelector(selectEntityTags)

  // TODO remove after tags will be added to GET /events
  const allTournaments = useSelector((state: RootState) =>
    selectLineTournaments(state, { sportId: `${group[0].sportId}` })
  )

  const groupWithTags = useMemo(() => {
    const tournamentsMap = allTournaments.reduce((acc, item) => {
      acc[item.id] = item
      return acc
    }, {})

    const mappedGroup = group.map((item) => {
      if (tournamentsMap?.[item.id]) {
        item.tags = tournamentsMap[item.id].tags
      }

      return item
    })

    return mappedGroup
  }, [allTournaments, group])

  const sortedGroupByPriority = groupWithTags.slice().sort(byPriorityPosition)

  const sortedGroupByTags = useMemo(
    () =>
      sortedGroupByPriority.map((item) => {
        const sortedEvents = sortByEntityTags(item.events, entityTags)
        return { ...item, events: sortedEvents }
      }),
    [sortedGroupByPriority, entityTags]
  )

  const sortByIncludindTags = useSortByIncludingTags(sortedGroupByTags)
  const finalSorted = useMemo(
    () => sortByEntityTags(sortByIncludindTags, entityTags),
    [sortByIncludindTags, entityTags]
  )

  return (
    <S.EventsTableGroupsWrapper>
      {finalSorted.map((group, index) => (
        <S.EventsTableWrapper key={group.id}>
          <EventTableHeader
            hideOutcomeCodes={index > 0 && topHeaderCodesOnly}
            tournament={group}
            {...headerProps}
          />

          {group.events.map((event) => (
            <EventTableRow
              Outcomes={
                <OutcomesGrid
                  eventId={event.id}
                  type={OutcomesComponentType.EVENTS_LIST}
                />
              }
              event={event}
              key={event.id}
            />
          ))}
        </S.EventsTableWrapper>
      ))}
    </S.EventsTableGroupsWrapper>
  )
}
