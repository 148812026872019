import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState
} from 'react'

import { LegacyBet } from '../../legacy-api/lib'

const BetsHistoryContext = createContext<{
  allExpressSystemCollapsed: boolean
  toggleCollapseAll: () => void
  collapseAllDisabled: boolean
} | null>(null)

export const BetsHistoryLegacyContextProvider: FC<
  PropsWithChildren<{
    betIds: LegacyBet['bet_id'][]
  }>
> = ({ children, betIds }) => {
  const [allExpressSystemCollapsed, setAllExpressSystemCollapsed] =
    useState(true)

  const toggleCollapseAll = () => {
    setAllExpressSystemCollapsed((prev) => !prev)
  }

  const collapseAllDisabled = !betIds.length

  return (
    <BetsHistoryContext.Provider
      value={{
        allExpressSystemCollapsed,
        toggleCollapseAll,
        collapseAllDisabled
      }}
    >
      {children}
    </BetsHistoryContext.Provider>
  )
}

export const useBetsHistoryLegacyContext = () => useContext(BetsHistoryContext)!
