import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EColorsNames, EColorsTypes } from '../../../../../../shared/lib/theme'
import { ERoutes } from '../../../../../../shared/types/routes'
import { layoutContainerActions } from '../../../../../../containers/LayoutContainer/slice'
import { getIsDisplayNotificationPanel } from '../../../../../../containers/LayoutContainer/selectors'
import {
  useHandleRedirectList,
  useOnboardingAttrs
} from '../ComponentOfAuthorized/hooks'

import {
  StyledCasinoComponentOfAuthorized,
  StyledIconMessage,
  StyledIconPersonal,
  StyledPadding
} from './CasinoComponentOfAuthorized.styled'
import { BalanceButton } from './BalanceButton'

export const CasinoComponentOfAuthorized = () => {
  const dispatch = useDispatch()
  const isDisplayNotificationPanel = useSelector(getIsDisplayNotificationPanel)

  const handleRedirectList = useHandleRedirectList()

  const { onboardingAttrPersonalAccount, onboardingAttrBalance } =
    useOnboardingAttrs()

  const onToggleNotificationPanel = () => {
    dispatch(
      layoutContainerActions.toggleIsDisplayNotificationPanel(
        !isDisplayNotificationPanel
      )
    )
  }

  return (
    <StyledCasinoComponentOfAuthorized>
      <StyledPadding {...onboardingAttrBalance}>
        <StyledIconMessage size={20} onClick={onToggleNotificationPanel} />
      </StyledPadding>
      {/* Check if we should show the bonus container, because it returns null if isBalanceHidden and it will break the onboarding */}

      <StyledPadding {...onboardingAttrBalance}>
        <BalanceButton />
      </StyledPadding>

      <StyledPadding {...onboardingAttrPersonalAccount}>
        <StyledIconPersonal
          colorProps={{
            name: EColorsNames.Primary,
            type: EColorsTypes.CUSTOM,
            value: 7
          }}
          size={20}
          onClick={handleRedirectList(ERoutes.Profile)}
        />
      </StyledPadding>
    </StyledCasinoComponentOfAuthorized>
  )
}
