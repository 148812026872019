import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { ESportsCodes } from 'astra-core/containers/CommonDataProvider'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  EFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'
import { selectBroadcastAvailabilityByEventId } from 'astra-core/containers/BroadcastProvider'

import { matchBroadcastModel } from 'entities/match-broadcast'
import { useEventBackLink } from 'widgets/Event/useEventBackLink'
import { IconArrowLeft } from 'shared/ui/Icon/General/IconArrowLeft'
import { EventContext, IEventContext } from 'widgets/Event/Event.context'
import { useIsSmall, useIsTabletOrMobile } from 'shared/lib/styled'
import { Tooltip } from 'shared/ui/Tooltip'
import { useTextOverflow } from 'shared/lib/text-overflow/use-text-overflow'
import { BasketButtonSmallWindow } from 'features/basket/open-basket-small-window'
import { entityTagsColorsSchema } from 'entities/event/model/constants'
import { TScrollValues } from 'widgets/Event/Event.types'
import { FavoriteButton } from 'features/favorite-button'
import { IconVideoFilled } from 'shared/ui/Icon/General/IconVideoFilled'
import { useGetEntityTagName } from 'entities/event'

import { BannerLineContent, BannerLiveContent } from './components'
import {
  BANNER_TABS_HEIGHT,
  LINE_COMPACT_CONTENT,
  LIVE_COMPACT_CONTENT,
  useEventBannerData
} from './libs'
import {
  StyledBannerTab,
  StyledBannerTabWrapper,
  StyledBannerTabs,
  StyledBannerTabsGroup,
  StyledEventArrow,
  StyledEventBanner,
  StyledEventBannerContainer,
  StyledEventBannerWrapper,
  StyledEventHead,
  StyledEventHeadWrapper,
  StyledEventTitle,
  StyledLine,
  StyledLineIndicator
} from './EventBanner.styled'

export const EventBanner: FC<TScrollValues> = ({
  scrollTop,
  scrollHeight,
  clientHeight
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const { event } = useContext(EventContext) as IEventContext
  const { containerRef, sport } = useEventBannerData()
  const [ref, isOverflowing] = useTextOverflow<HTMLDivElement>()

  const isLive = useMemo(() => event.live, [event.live])
  const [redirectToBack] = useEventBackLink(event)

  const isTabletOrMobile = useIsTabletOrMobile()
  const isSmall = useIsSmall()

  const [transition, setTransition] = useState(0)
  const [maxHeight, setMaxHeight] = useState(0)

  const isHasBroadcast = useSelector((state) =>
    selectBroadcastAvailabilityByEventId(state, event.id)
  )
  const isFeatureFlagEnabled = useFeatureFlag(EFeatureFlags.BROADCAST_ENABLED)
  const isBroadcastEnabled =
    event.live && isHasBroadcast && isFeatureFlagEnabled

  useEffect(() => {
    const scrollableHeight =
      maxHeight +
      BANNER_TABS_HEIGHT -
      (isLive ? LIVE_COMPACT_CONTENT : LINE_COMPACT_CONTENT)

    const maxScrollDown = scrollHeight - clientHeight

    if (
      transition === 0 &&
      maxScrollDown - scrollableHeight < scrollableHeight
    ) {
      return
    }
    if (scrollTop > scrollableHeight && maxHeight !== 0) {
      setTransition(1)
    } else {
      setTransition(Math.round(scrollTop / scrollableHeight))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxHeight, scrollTop, isLive, scrollHeight, clientHeight])

  const entityTagName = useGetEntityTagName({ tags: event.tags })

  const handleOpenBroadcast = useCallback(
    (e) => {
      e.stopPropagation()

      dispatch(
        matchBroadcastModel.liveMatchBroadcastActions.addBroadcast({
          eventId: event.id
        })
      )
    },
    [dispatch, event.id]
  )

  return (
    <StyledEventBannerContainer>
      <StyledEventHeadWrapper ref={containerRef}>
        <StyledEventHead
          to={{
            pathname: redirectToBack,
            state: { maintainLineFilter: true }
          }}
        >
          <StyledEventArrow>
            <IconArrowLeft colorToken="icon-secondary" />
          </StyledEventArrow>
          <Tooltip
            isCommon={!isOverflowing}
            title={`${sport?.name}. ${event.tournament.name}`}
          >
            <StyledEventTitle ref={ref}>
              {sport?.name}. {event.tournament.name}
            </StyledEventTitle>
          </Tooltip>
        </StyledEventHead>

        {(isTabletOrMobile || isSmall) && <BasketButtonSmallWindow isSmall />}
      </StyledEventHeadWrapper>

      <StyledEventBannerWrapper>
        {entityTagName && (
          <StyledLine entityTagColor={entityTagsColorsSchema[entityTagName]}>
            <StyledLineIndicator>{entityTagName}</StyledLineIndicator>
          </StyledLine>
        )}

        <StyledEventBanner
          isLive={isLive}
          maxHeight={maxHeight + BANNER_TABS_HEIGHT}
          sport={sport.code as ESportsCodes}
          transition={transition}
        >
          {isLive ? (
            <BannerLiveContent setMaxHeight={setMaxHeight} />
          ) : (
            <BannerLineContent setMaxHeight={setMaxHeight} />
          )}

          <StyledBannerTabs>
            {isLive ? (
              <>
                <StyledBannerTabsGroup>
                  {isBroadcastEnabled && (
                    <StyledBannerTabWrapper onClick={handleOpenBroadcast}>
                      <StyledBannerTab>
                        <IconVideoFilled />
                        {t('stream')}
                      </StyledBannerTab>
                    </StyledBannerTabWrapper>
                  )}

                  {/* <StyledBannerTabWrapper>
                    <StyledBannerTab>
                      <IconLiveTracker />
                      {t('live tracker')}
                    </StyledBannerTab>
                  </StyledBannerTabWrapper> */}
                </StyledBannerTabsGroup>
              </>
            ) : (
              <div />
            )}

            <StyledBannerTabsGroup>
              {/* <StyledBannerTabWrapper>
                <StyledBannerTab>
                  <IconStatisticsFilled />
                  {t('statistics')}
                </StyledBannerTab>
              </StyledBannerTabWrapper> */}

              <StyledBannerTabWrapper>
                <StyledBannerTab>
                  <FavoriteButton eventId={event.id} />
                </StyledBannerTab>
              </StyledBannerTabWrapper>
            </StyledBannerTabsGroup>
          </StyledBannerTabs>
        </StyledEventBanner>
      </StyledEventBannerWrapper>
    </StyledEventBannerContainer>
  )
}
