import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ETabTypes } from 'astra-core/containers/BalanceProvider'
import { useDispatch, useSelector } from 'react-redux'
import {
  accountProviderActions,
  selectShowIsSuccessSignUpModal,
  selectTypeAuthModal
} from 'astra-core/containers/AccountProvider'
import {
  useGetPostAuthServiceMessage,
  EPostMessageIdEvent
} from 'astra-core/containers/AuthMessageProvider'

import { IconSuccess } from 'shared/ui/Icon/General/IconSuccess'
import { ERoutes } from 'shared/types/routes'
import { IconCloseOutline16 } from 'shared/ui/Icon/General/IconCloseOutline16'
import { Button } from 'shared/ui/Button'
import { ModalDeprecated } from 'shared/ui/modal-deprecated'

import {
  StyledSuccessPageTitle,
  StyledSuccessPageModalHeaderWrapper,
  StyledSuccessPageSubtitle
} from '../../success-page.styled'

import {
  StyledSuccessSignUp,
  StyledSuccessSignUpComponentsWrapper,
  SuccessSignUpText
} from './success-sign-up.styled'

export const SuccessSignUp = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const typeAuthModal = useSelector(selectTypeAuthModal)
  const showIsSuccessSignUpModal = useSelector(selectShowIsSuccessSignUpModal)

  const toggleModalWindow = useCallback(() => {
    dispatch(
      accountProviderActions.setIsShowSuccessSignUpModal(
        !showIsSuccessSignUpModal
      )
    )
    dispatch(accountProviderActions.setIsDisplayTypeformModal(true))
  }, [dispatch, showIsSuccessSignUpModal])

  const handleClick = useCallback(() => {
    history.push(`${ERoutes.ProfileBalance}/${ETabTypes.Payment}`)
    toggleModalWindow()
  }, [history, toggleModalWindow])

  const handleMessage = useCallback(
    (event) => {
      if (
        event?.isSuccessSignUp &&
        !showIsSuccessSignUpModal &&
        typeAuthModal !== null // <-- for will avoid run this conditional after showIsSuccessSignUpModal becomes false again from true
      ) {
        dispatch(accountProviderActions.getUserAfterSuccessSignUp()) // Starting getUser and after that start set some local user settings

        dispatch(accountProviderActions.setIsShowSuccessSignUpModal(true))
      }
    },
    [dispatch, showIsSuccessSignUpModal, typeAuthModal]
  )

  useGetPostAuthServiceMessage<{ pageTitle: string }>(
    EPostMessageIdEvent.pageChange,
    handleMessage
  )

  return (
    <ModalDeprecated
      styleContainer={{
        width: '375px',
        height: '244px',
        position: 'relative',
        padding: 32
      }}
      isOpen={showIsSuccessSignUpModal}
      showFooter
      onModalClose={toggleModalWindow}
    >
      <StyledSuccessPageModalHeaderWrapper>
        <IconCloseOutline16
          colorToken="icon-secondary-2"
          onClick={toggleModalWindow}
        />
      </StyledSuccessPageModalHeaderWrapper>
      <StyledSuccessSignUpComponentsWrapper>
        <StyledSuccessSignUp>
          <IconSuccess size={40} />
          <SuccessSignUpText>
            <StyledSuccessPageTitle>
              {t('registration completed')}
            </StyledSuccessPageTitle>
            <StyledSuccessPageSubtitle>
              {t('order to place')}
            </StyledSuccessPageSubtitle>
          </SuccessSignUpText>
        </StyledSuccessSignUp>
        <Button view="primary-extra-large" onClick={handleClick}>
          {t('to top up balance')}
        </Button>
      </StyledSuccessSignUpComponentsWrapper>
    </ModalDeprecated>
  )
}
