import React from 'react'
import { useSelector } from 'react-redux'
import { selectAccount } from 'astra-core/containers/AccountProvider'
import { useRouteMatch } from 'react-router-dom'

import { ERoutes } from 'shared/types/routes'

import { ComponentOfAuthorized, ComponentOfUnauthorized } from './components'
import { CasinoComponentOfAuthorized } from './components/CasinoComponentOfAuthorized'

export const PersonalData = () => {
  const account = useSelector(selectAccount)
  const casinoMatches = useRouteMatch(ERoutes.Casino)

  return account ? (
    casinoMatches ? (
      <CasinoComponentOfAuthorized />
    ) : (
      <ComponentOfAuthorized />
    )
  ) : (
    <ComponentOfUnauthorized />
  )
}
