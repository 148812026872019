import React, { FC, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectInputEventsSearch } from 'astra-core/containers/SearchProvider'
import { Event } from 'betweb-openapi-axios'

import { RootState } from 'shared/types/store'
import {
  InputSearchMarkets,
  useSearchMarketsOnChangeInput,
  useSearchMarketsReset
} from 'entities/outcome-table'

export const OutcomesSearch: FC<{
  eventId: Event['id']
}> = ({ eventId }) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const value =
    useSelector((state: RootState) =>
      selectInputEventsSearch(state, eventId)
    ) || ''

  const { onChangeInputDebounce } = useSearchMarketsOnChangeInput(eventId)

  const { onResetSearch } = useSearchMarketsReset({
    eventId,
    onReset: () => setIsSearchOpen(false),
    isSearchOpen,
    inputValue: value,
    inputRef
  })

  const handleBlur = () => {
    if (!value) {
      setIsSearchOpen(false)
    }
  }

  return (
    <InputSearchMarkets
      inputRef={inputRef}
      isOpen={isSearchOpen}
      value={value}
      onBlur={handleBlur}
      onChange={onChangeInputDebounce}
      onClose={onResetSearch}
      onOpen={() => setIsSearchOpen(true)}
    />
  )
}
