import React from 'react'
import { useSelector } from 'react-redux'

import { useInjectReducer, useInjectSaga } from 'shared/lib/@reduxjs'
import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import {
  reducerLine,
  sliceKeyLine
} from 'pages/page-line-tournaments/LineTournamentsContainer/slice'
import { lineTournamentsContainerSaga } from 'pages/page-line-tournaments/LineTournamentsContainer/saga'
import { selectLineIsEmpty } from 'pages/page-line-tournaments/LineTournamentsContainer/selectors'
import { LineEvents } from 'pages/page-line-events/ui/line'
import { LineProps } from 'pages/page-line-events/LineContainer/types'
import { useLineContainerData } from 'pages/page-line-tournaments/LineTournamentsContainer/hooks'

export function LineContainer(_props: LineProps) {
  useInjectReducer({ key: sliceKeyLine, reducer: reducerLine })
  useInjectSaga({ key: sliceKeyLine, saga: lineTournamentsContainerSaga })

  const isEmpty = useSelector(selectLineIsEmpty)

  useLineContainerData()

  return isEmpty ? <LoaderSpinner /> : <LineEvents />
}
