import styled, { css } from 'styled-components'

import { Scrollable } from 'shared/ui/scrollable'

export const StyledBasketBodyWrapper = styled.div<{
  broadcastsEventIdsLength: number
}>`
  display: flex;
  flex-direction: column;

  ${({ broadcastsEventIdsLength }) => css`
    ${broadcastsEventIdsLength < 2 &&
    css`
      overflow-y: auto;
    `}
  `}
`

export const StyledBetGroupsList = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.tokens.alias.space['space-xs-8']}px
      ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const StyledBetCardList = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.tokens.alias.space['space-xxxxs-2']}px;
  `}
`

export const StyledBasketBodyList = styled(Scrollable)`
  .rcs-positioning .rcs-inner-handle {
    height: calc(100% - 20px);
  }
`

export const BasketBodyLoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const BasketBodyLoaderTitle = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
    padding: ${theme.tokens.alias.space['space-m-16']}px
      ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const BasketBodyLoaderItems = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xxxxs-2']}px;
    padding: 0 ${theme.tokens.alias.space['space-xs-8']}px
      ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const BasketBodyLoaderItem = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-base']};
    border-radius: ${theme.tokens.alias.space['space-xs-8']}px;
    gap: ${theme.tokens.alias.space['space-xxs-6']}px;
    padding: ${theme.tokens.alias.space['space-xs-8']}px
      ${theme.tokens.alias.space['space-s-12']}px;
  `}
  > div:last-child {
    display: flex;
    justify-content: space-between;
  }
`
