import { FC, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectMatchPhase } from 'astra-core/containers/CommonDataProvider'
import { selectBroadcastAvailabilityByEventId } from 'astra-core/containers/BroadcastProvider'
import {
  EFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'

import { RootState } from 'shared/types/store'
import { EEventStatisticsCodes } from 'shared/lib/events/types'
import { IconTime } from 'shared/ui/Icon/General/icon-time'
import { Typography } from 'shared/ui/typography'
import { useTimerCountdown } from 'shared/lib/events/timer-countdown'
import { matchBroadcastModel } from 'entities/match-broadcast'
import { FavoriteButton } from 'features/favorite-button'
import { selectIsActiveBroadcastsEventId } from 'entities/match-broadcast/model'
import { IconBroadcast } from 'shared/ui/Icon/General/IconBroadcast'

import * as S from './live-content.styled'
import { LiveContentProps } from './live-content.types'
import { StyledIconVideoFilledWrapper } from './live-content.styled'

export const LiveContent: FC<LiveContentProps> = ({ event }) => {
  const dispatch = useDispatch()
  const isHasBroadcast = useSelector((state) =>
    selectBroadcastAvailabilityByEventId(state, event.id)
  )
  const isActiveBroadcastsEventId = useSelector((state: RootState) =>
    selectIsActiveBroadcastsEventId(state, +event.id)
  )

  const matchPhaseStat = event.statistics.find(
    (s) => s.code === EEventStatisticsCodes.MatchPhase
  )
  const matchPhase = useSelector((state) =>
    selectMatchPhase(state, matchPhaseStat ? +matchPhaseStat!.value! : 0)
  )

  const gameScoreStatistics = event.statistics.find(
    (s) => s.code === EEventStatisticsCodes.GameScore
  )
  const scoreByPeriodsStatistics = event.statistics.find(
    (s) => s.code === EEventStatisticsCodes.ScoresByPeriods
  )
  const scoreStatistics = event.statistics.find(
    (s) => s.code === EEventStatisticsCodes.Score
  )

  const gameScore = gameScoreStatistics?.value
    .split(':')
    .map((item) => (item === '50' ? 'A' : item))
  const scoreByPeriods = useMemo(
    () =>
      scoreByPeriodsStatistics?.value
        .split(',')
        .map((item) => item.split(':'))
        .map(([home, away], period) => ({
          home,
          away,
          period
        })),
    [scoreByPeriodsStatistics?.value]
  )

  const score = scoreStatistics?.value.split(':')
  const { hasTime, value } = useTimerCountdown({ event })
  const showTimeBlock = hasTime || !!matchPhase

  const isFeatureFlagEnabled = useFeatureFlag(EFeatureFlags.BROADCAST_ENABLED)
  const isBroadcastEnabled =
    event.live && isHasBroadcast && isFeatureFlagEnabled

  const handleClickBroadcast = useCallback(
    (e) => {
      e.stopPropagation()

      if (isActiveBroadcastsEventId) {
        dispatch(
          matchBroadcastModel.liveMatchBroadcastActions.removeBroadcast({
            eventId: event.id
          })
        )
      } else {
        dispatch(
          matchBroadcastModel.liveMatchBroadcastActions.addBroadcast({
            eventId: event.id
          })
        )
      }
    },
    [dispatch, event.id, isActiveBroadcastsEventId]
  )

  return (
    <>
      <S.LiveContentScoreCell>
        <S.ScoresStatistics>
          {(scoreByPeriods || gameScore) && (
            <S.EventScoreByPeriods>
              {gameScore && (
                <S.EventScoreByPeriodsColumn>
                  <span>({gameScore[0]})</span>
                  <span>({gameScore[1]})</span>
                </S.EventScoreByPeriodsColumn>
              )}

              {scoreByPeriods && (
                <>
                  {scoreByPeriods.map((item) => (
                    <S.EventScoreByPeriodsColumn key={item.period}>
                      <span>{item.home}</span>
                      <span>{item.away}</span>
                    </S.EventScoreByPeriodsColumn>
                  ))}
                </>
              )}
            </S.EventScoreByPeriods>
          )}

          <S.EventScore>
            {score?.length && <div>{score[0]}</div>}
            {score?.length && <div>{score[1]}</div>}
          </S.EventScore>
        </S.ScoresStatistics>
      </S.LiveContentScoreCell>

      <S.LiveContentStatsCell>
        <S.AdditionalBlockWrapper>
          {showTimeBlock && (
            <S.TimeStatsWrapper>
              {hasTime && (
                <S.TimeWrapper>
                  <IconTime size={12} />

                  <Typography color="text-primary-2" font="Hint / 10 Bold">
                    {value}
                  </Typography>
                </S.TimeWrapper>
              )}

              {matchPhase && (
                <Typography color="text-secondary-3" font="Hint / 10 SemiBold">
                  {matchPhase.name}
                </Typography>
              )}
            </S.TimeStatsWrapper>
          )}

          <S.IconsWrapper>
            {isBroadcastEnabled && (
              <StyledIconVideoFilledWrapper
                isActive={isActiveBroadcastsEventId}
              >
                <IconBroadcast
                  colorToken="icon-secondary-3"
                  size={12}
                  onClick={handleClickBroadcast}
                />
              </StyledIconVideoFilledWrapper>
            )}

            <FavoriteButton eventId={event.id} />
          </S.IconsWrapper>
        </S.AdditionalBlockWrapper>
      </S.LiveContentStatsCell>
    </>
  )
}
