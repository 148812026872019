import React, { FC, useMemo, useCallback, KeyboardEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  basketProviderActions,
  selectBasketBonusWallet,
  selectDisabledButtonExpressSystem,
  selectMaxBetExpressSystem,
  selectSystemAmount,
  selectSystemSize
} from 'astra-core/containers/BasketProvider'

import { SumInput } from 'features/input-sum'
import { validateBasketInputValue } from 'shared/lib/basket/validateBasketInputValue'
import {
  BetCardElement,
  DependentOutcomes,
  StyledInputSum
} from 'features/basket/basket-outcomes'
import { BasketBottom } from 'features/basket/basket-bottom'
import { BasketTooltipProvider } from 'shared/ui/Tooltip/basket-tooltip'

import {
  StyledBasketBodyList,
  StyledBetCardList,
  StyledBetGroupsList
} from '../BasketBody.styled'
import { useBetErrors, useEvents } from '../../lib'

import { getSystemMaxWinSum } from './SystemCombination'
import { SystemProps } from './System.types'
import { SystemTabs } from './SystemTabs'

export const System: FC<SystemProps> = ({ outcomes }) => {
  const dispatch = useDispatch()

  const systemAmount = useSelector(selectSystemAmount)
  const systemSize = useSelector(selectSystemSize)
  const disabledButton = useSelector(selectDisabledButtonExpressSystem)
  const maxSystemBet = useSelector(selectMaxBetExpressSystem)
  const isBonusWallet = useSelector(selectBasketBonusWallet)

  const { minAmountError, maxAmountError } = useBetErrors()
  const { events, dependentEvents } = useEvents(outcomes)

  const [inputValue, setInputValue] = useState('')

  const calcWinSum = useMemo(() => {
    const amount = (inputValue !== '' && inputValue) || 0

    return (
      getSystemMaxWinSum(systemSize, +amount, outcomes) -
      +amount * +isBonusWallet
    )
  }, [inputValue, systemSize, outcomes, isBonusWallet])

  const setOutcomeAmount = useCallback(
    (value: string) => {
      setInputValue(value)
      dispatch(
        basketProviderActions.setSystemAmount({
          amount: validateBasketInputValue(value)
        })
      )
    },
    [dispatch]
  )

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        dispatch(basketProviderActions.makeBet())
      }
    },
    [dispatch, basketProviderActions]
  )

  return (
    <>
      <StyledBasketBodyList>
        <StyledBetGroupsList>
          <BasketTooltipProvider>
            {!!dependentEvents.length && (
              <DependentOutcomes dependentEvents={dependentEvents} />
            )}

            <StyledBetCardList>
              {events.map((outcome) => (
                <BetCardElement key={outcome.id} outcome={outcome} />
              ))}
            </StyledBetCardList>
          </BasketTooltipProvider>
        </StyledBetGroupsList>
      </StyledBasketBodyList>

      <BasketBottom disabledButton={disabledButton || !systemAmount}>
        <SystemTabs />
        <StyledInputSum>
          <SumInput
            errorMessage={maxAmountError?.message || minAmountError?.message}
            maxValue={maxSystemBet}
            winSum={calcWinSum}
            onChange={setOutcomeAmount}
            onKeyDown={handleKeyDown}
          />
        </StyledInputSum>
      </BasketBottom>
    </>
  )
}
