import styled, { css } from 'styled-components'

import { Button } from 'shared/ui/Button'
import { typography } from 'shared/lib/theme/typography'

export const StyledCurrentCashoutWrapper = styled.div<{
  isLastElement: boolean
}>`
  align-items: center;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  left: 0px;
  padding: 8px 8px 8px 12px;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 1001;

  ${({ theme }) =>
    css`
      background-color: ${theme.tokens.colors.surface['surface-modal']};
      box-shadow: inset 0 0 0 1px
        ${theme.tokens.colors.border['border-primary']};
    `}

  ${({ isLastElement }) => css`
    ${isLastElement &&
    css`
      top: -22px;
    `}
  `}
`

export const StyledCurrentCashoutText = styled.div`
  display: flex;
  justify-content: flex-start;
  width: auto;

  ${typography['Body / 12 Medium']}

  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
  `}
`

export const StyledCurrentCashoutButton = styled(Button)`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  min-width: 40px;
  padding: 4px 6px;
  width: 100%;

  ${typography['Hint / 10 BOLD']}

  ${({ theme }) =>
    css`
      border-radius: ${theme.tokens.alias['border-radius'][
        'border-radius-m-8'
      ]}px;
    `}
`

export const StyledCurrentCashoutButtonWrapper = styled.div`
  display: flex;
  gap: 4px;
  width: 100%;
`

export const StyledMakeCashoutLoadingWrapper = styled(Button)`
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 100%;

  ${({ theme }) =>
    css`
      background-color: ${theme.tokens.colors.text['surface-disabled']};
      border-radius: ${theme.tokens.alias['border-radius'][
        'border-radius-m-8'
      ]}px;
    `}
`

export const StyledCurrentCashoutModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`
