import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'
import {
  formatAmountLocaleString,
  getBetOutcomeId,
  useGetCurrencyIcon
} from 'astra-core'
import { useSelector } from 'react-redux'
import {
  selectLoyaltyById,
  selectLoyaltyCurrencyRestrictionById
} from 'astra-core/containers/AccountProvider'
import { BetStatus, BetType } from 'betweb-openapi-axios'

import { getExpressSystemOutcomesCount } from 'features/bet-history-card/bet-history-utils'
import {
  BetHistoryCardProps,
  ECellEventTitle
} from 'features/bet-history-card/bet-history-card.types'
import { BasketTooltipProvider } from 'shared/ui/Tooltip/basket-tooltip'
import { RootState } from 'shared/types/store'
import { Typography } from 'shared/ui/typography'
import { ExpressBonusModal } from 'features/express-bonus-modal/express-bonus-modal'
import { IconInfoCircleFilled } from 'shared/ui/Icon/General/IconInfoCircleFilled'
import { separateNumber } from 'shared/lib/format/separate-number'
import {
  getBetBonusAmountFormatted,
  getExpressBonusSum
} from 'entities/bet-history'
import { formatMonetaryAmount } from 'shared/lib/format/formatMonetaryAmount'
import { IconBonus } from 'shared/ui/Icon/General/IconBonus'
import { IconTicketNo } from 'shared/ui/Icon/General/IconTicketNo'

import { BasketHistoryCashout } from '../BasketHistoryCashout'
import { BasketHistoryOutcome } from '../BasketHistoryOutcome'

import {
  StyledBasketHistoryBonusWrapper,
  StyledBasketHistoryItem,
  StyledBasketHistoryItemBottom,
  StyledBasketHistoryItemMain,
  StyledBasketHistoryItemRow,
  StyledBasketHistoryItemWrapper,
  StyledBasketHistoryOutcomes,
  StyledBasketHistorySumCoupon,
  StyledBasketHistoryTextBonus,
  StyledBasketHistoryTextCoupon,
  StyledBasketHistoryTextSecondary,
  StyledBetHistoryTag
} from './BasketHistoryCard.styled'

export const BasketHistoryCard: FC<BetHistoryCardProps> = ({
  item,
  isLastElement
}) => {
  const { t } = useTranslation()
  const currencyIcon = useGetCurrencyIcon()
  const couponLoyalty = useSelector((state: RootState) =>
    selectLoyaltyById(state, item.appliedCouponId)
  )

  const loyaltyCurrencyRestriction = useSelector((state: RootState) =>
    selectLoyaltyCurrencyRestrictionById(state, item.appliedCouponId)
  )

  const [showOutcomes, setShowOutcomes] = useState(false)

  const betCount =
    item.outcomes.length > 1
      ? `${getExpressSystemOutcomesCount(item)} ${t('events', {
          count: item.outcomes.length
        }).toLowerCase()}`
      : ''

  const betAmountFormatted = getBetBonusAmountFormatted({
    bet: item,
    couponLoyalty,
    currencyIcon,
    loyaltyCurrencyRestriction
  })

  const handleToggle = useCallback(() => setShowOutcomes((prev) => !prev), [])

  return (
    <StyledBasketHistoryItem>
      <StyledBasketHistoryItemWrapper>
        <StyledBasketHistoryItemMain onClick={handleToggle}>
          <StyledBasketHistoryItemRow>
            <Typography color="text-primary" font="Body / 12 SemiBold">
              {ECellEventTitle[item.betType]
                ? `${t(ECellEventTitle[item.betType])} ${betCount}`
                : ECellEventTitle.default}
            </Typography>
            <StyledBasketHistoryTextSecondary>
              {dayjs(item.timestamp).format('DD.MM.YY HH:mm')}
            </StyledBasketHistoryTextSecondary>
          </StyledBasketHistoryItemRow>
          <StyledBasketHistoryItemRow>
            <StyledBasketHistoryTextSecondary>
              {t('overall coefficient')}
            </StyledBasketHistoryTextSecondary>
            <Typography color="text-primary" font="Body / 12 SemiBold">
              {formatAmountLocaleString({
                value: item.coefficient?.toFixed(2)
              })}
            </Typography>
          </StyledBasketHistoryItemRow>
          <StyledBasketHistoryItemRow>
            <StyledBasketHistoryTextSecondary>
              {t('bets amount')}
            </StyledBasketHistoryTextSecondary>
            <StyledBasketHistoryTextSecondary>
              {item.bonusWallet ? (
                <StyledBasketHistoryTextBonus>
                  {formatAmountLocaleString({
                    value: item.bet
                  })}
                  <IconBonus size={12} />
                </StyledBasketHistoryTextBonus>
              ) : (
                formatAmountLocaleString({
                  value: couponLoyalty?.coupon.amount ? 0 : item.bet,
                  currency: currencyIcon
                })
              )}
            </StyledBasketHistoryTextSecondary>
          </StyledBasketHistoryItemRow>

          {couponLoyalty?.coupon.amount && (
            <StyledBasketHistoryItemRow>
              <StyledBasketHistoryTextCoupon>
                <IconTicketNo />
                {t('coupon applied')}
              </StyledBasketHistoryTextCoupon>
              <StyledBasketHistorySumCoupon>
                {formatAmountLocaleString({
                  value: couponLoyalty?.coupon.amount,
                  currency: currencyIcon
                })}
              </StyledBasketHistorySumCoupon>
            </StyledBasketHistoryItemRow>
          )}

          {item.betType === BetType.Express &&
            item.status !== BetStatus.Sold && (
              <StyledBasketHistoryItemRow>
                <StyledBasketHistoryBonusWrapper>
                  <StyledBasketHistoryTextSecondary>
                    {t('express bonus')}
                    <ExpressBonusModal>
                      <IconInfoCircleFilled size={16} />
                    </ExpressBonusModal>
                  </StyledBasketHistoryTextSecondary>
                </StyledBasketHistoryBonusWrapper>
                <Typography color="text-warning" font="Body / 12 Medium">
                  {formatMonetaryAmount(
                    separateNumber(getExpressBonusSum(item))
                  )}{' '}
                  {currencyIcon}
                </Typography>
              </StyledBasketHistoryItemRow>
            )}

          <StyledBasketHistoryItemRow>
            <StyledBetHistoryTag status={item.status} />
            <Typography color="text-primary" font="Body / 12 SemiBold">
              {betAmountFormatted}
            </Typography>
          </StyledBasketHistoryItemRow>

          <BasketHistoryCashout id={item.id} isLastElement={isLastElement} />
        </StyledBasketHistoryItemMain>

        {showOutcomes && (
          <StyledBasketHistoryOutcomes>
            <BasketTooltipProvider>
              {item.outcomes.map((outcome) => {
                const { event, outcomeTypeId, parameters, coefficient } =
                  outcome

                const eventProbability = {
                  eventId: event?.id || coefficient,
                  outcomeTypeId,
                  parameters
                }

                return (
                  <BasketHistoryOutcome
                    key={getBetOutcomeId(eventProbability)}
                    outcome={outcome}
                  />
                )
              })}
            </BasketTooltipProvider>
          </StyledBasketHistoryOutcomes>
        )}
      </StyledBasketHistoryItemWrapper>
      {!showOutcomes && <StyledBasketHistoryItemBottom />}
    </StyledBasketHistoryItem>
  )
}
