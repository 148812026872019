import React, { FC, memo, useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import videojs from 'video.js'
import Player from 'video.js/dist/types/player'
import { EAuthPagesAndModal } from 'astra-core'
import { getEventTitle } from 'astra-core/utils/events'
import { selectEventById } from 'astra-core/containers/EventsProvider/selectors'
import { selectAccount } from 'astra-core/containers/AccountProvider/selectors'
import { Draggable } from 'react-beautiful-dnd'
import {
  selectBroadcastAvailabilityByEventId,
  selectBroadcastByEventId
} from 'astra-core/containers/BroadcastProvider/selectors'
import { usePrevious } from 'astra-core/hooks/usePrevious'
import { useFeatureFlag } from 'astra-core/containers/ConfigProvider/utils'
import { EFeatureFlags } from 'astra-core/containers/ConfigProvider/types'
import { selectIsDevelopment } from 'astra-core/containers/ConfigProvider/selectors'
import { BroadcastType, Event } from 'betweb-openapi-axios'
import { broadcastProviderActions } from 'astra-core/containers/BroadcastProvider/slice'
import {
  TTypeAuthModal,
  accountProviderActions
} from 'astra-core/containers/AccountProvider'

import { RootState } from 'shared/types/store'
import { useOpenEventPage } from 'entities/event'
import { IconDragndrop } from 'shared/ui/Icon/General/IconDragndrop'
import { matchBroadcastModel } from 'entities/match-broadcast'
import { IconVideoFilled } from 'shared/ui/Icon/General/IconVideoFilled'
import { useInterval } from 'hooks'
import { BROADCAST_POLLING_INTERVAL } from 'pages/page-live-events/LiveContainer/utils'
import {
  selectBroadcastsEventIdsLength,
  FULL_SCREEN_TOGGLE_DISABLED_LIST,
  liveMatchBroadcastActions,
  selectIsBroadcastMuted,
  selectIsPlayingBroadcast
} from 'entities/match-broadcast/model'

import { GET_VIDEOJS_OPTIONS, getRedirectUrl } from './constants'
import {
  StyledBroadcastWrapper,
  StyledCloseIcon,
  StyledBroadcastHeader,
  StyledCompetitors,
  StyledNotAuthorized,
  StyledNotAuthorizedText,
  StyledBroadcastCompetitorsWrapper,
  StyledBroadcastTournamentWrapper,
  StyledBroadcastTournamentName,
  StyledButtonSignIn,
  StyledIconsWrapper,
  VIDEO_PLAYER_STYLES,
  VIDEO_PLAYER_WIDTH
} from './live-match-tracker.styled'

import 'video.js/dist/video-js.css'

export const LiveMatchBroadcast: FC<{
  broadcastId: Event['id']
  index: number
}> = memo(({ broadcastId, index }) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const videoRef = useRef<HTMLDivElement>(null)
  const playerRef = useRef<Player | null>()
  const isBroadcastFeatureFlagEnabled = useFeatureFlag(
    EFeatureFlags.BROADCAST_ENABLED
  )

  const account = useSelector(selectAccount)
  const isDevelopment = useSelector(selectIsDevelopment)
  const broadcastsEventIdsLength = useSelector(selectBroadcastsEventIdsLength)

  const isLogged = !!account
  const prevLogged = usePrevious(isLogged)

  const isHasBroadcast = useSelector((state) =>
    selectBroadcastAvailabilityByEventId(state, broadcastId)
  )
  const broadcast = useSelector((state) =>
    selectBroadcastByEventId(state, broadcastId)
  )
  const event = useSelector((state) => selectEventById(state, broadcastId))
  const isBroadcastMuted = useSelector((state: RootState) =>
    selectIsBroadcastMuted(state, event.id)
  )
  const isPlayingBroadcast = useSelector((state: RootState) =>
    selectIsPlayingBroadcast(state, event.id)
  )
  const link = useOpenEventPage({ event })
  const prevHasBroadcast = usePrevious(isHasBroadcast)

  const fetchEventBroadcast = useCallback(() => {
    if (
      isBroadcastFeatureFlagEnabled &&
      broadcastId &&
      isHasBroadcast &&
      isLogged
    ) {
      dispatch(
        broadcastProviderActions.fetchBroadcast({
          reqData: {
            eventId: broadcastId,
            platform: 'Desktop', // Desktop, Mobile, Android, IOS
            preferredBroadcastType: BroadcastType.Hls,
            redirectUrl: getRedirectUrl(isDevelopment)
          }
        })
      )
    }
  }, [
    dispatch,
    isDevelopment,
    isHasBroadcast,
    isBroadcastFeatureFlagEnabled,
    isLogged,
    broadcastId
  ])

  useEffect(() => {
    if (!prevHasBroadcast && isHasBroadcast) {
      fetchEventBroadcast()
    } else if (!prevLogged && isLogged) {
      fetchEventBroadcast()
    }
  }, [
    prevHasBroadcast,
    isHasBroadcast,
    fetchEventBroadcast,
    prevLogged,
    isLogged
  ])

  useInterval(fetchEventBroadcast, BROADCAST_POLLING_INTERVAL, true)

  useEffect(() => {
    if (broadcastId && account && broadcast?.broadcastUrl) {
      if (!playerRef.current) {
        const videoElement = document.createElement('video-js')
        videoElement.classList.add('vjs-big-play-centered')

        if (videoRef.current) {
          videoRef.current.innerHTML = ''
          videoRef.current.appendChild(videoElement)
        }

        const player: Player = videojs(videoElement, {
          ...GET_VIDEOJS_OPTIONS({ isPlayingBroadcast, isBroadcastMuted }),
          controlBar: {
            fullscreenToggle: !FULL_SCREEN_TOGGLE_DISABLED_LIST.includes(
              broadcast.providerType
            )
          },
          userActions: {
            doubleClick: !FULL_SCREEN_TOGGLE_DISABLED_LIST.includes(
              broadcast.providerType
            )
          },
          sources: [
            {
              src: broadcast.broadcastUrl,
              type: 'application/x-mpegURL'
            }
          ],
          poster: broadcast?.thumbnailUrl || undefined
        })

        playerRef.current = player

        player.on('volumechange', () => {
          if (player.muted()) {
            dispatch(
              liveMatchBroadcastActions.muteBroadcast({ id: broadcastId })
            )
          } else {
            dispatch(
              liveMatchBroadcastActions.unmuteBroadcast({
                id: broadcastId
              })
            )
          }
        })

        player.on('play', () => {
          dispatch(
            liveMatchBroadcastActions.setIsPlayingBroadcast({ id: broadcastId })
          )
        })

        player.on('pause', () => {
          dispatch(
            liveMatchBroadcastActions.unsetIsPlayingBroadcast({
              id: broadcastId
            })
          )
        })
      }
    }
  }, [
    videoRef,
    broadcastId,
    account,
    broadcast,
    dispatch,
    isBroadcastMuted,
    broadcastsEventIdsLength,
    isPlayingBroadcast
  ])

  const disposePlayer = useCallback(() => {
    const player = playerRef.current

    if (player && !player.isDisposed()) {
      player.dispose()
      playerRef.current = null
    }
  }, [])

  useEffect(() => {
    return () => {
      disposePlayer()
    }
  }, [disposePlayer])

  useEffect(() => {
    if (!(broadcastId && account && broadcast?.broadcastUrl)) {
      disposePlayer()
    }
  }, [disposePlayer, broadcastId, account, broadcast?.broadcastUrl])

  const handleClick = () => {
    if (event) {
      dispatch(
        matchBroadcastModel.liveMatchBroadcastActions.removeBroadcast({
          eventId: broadcastId
        })
      )
    }
  }

  const handleClickButton = useCallback(
    (types: TTypeAuthModal) => () =>
      dispatch(accountProviderActions.setTypeAuthModal(types)),
    [dispatch]
  )

  if (!(broadcastId && event)) {
    return null
  }

  const tournamentName = event.tournament.name

  return (
    <Draggable
      draggableId={String(broadcastId)}
      index={index}
      key={broadcastId}
    >
      {(provided) => (
        <StyledBroadcastWrapper
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <StyledBroadcastHeader>
            <StyledBroadcastTournamentWrapper>
              <StyledIconsWrapper {...provided.dragHandleProps}>
                {broadcastsEventIdsLength !== 1 && (
                  <IconDragndrop
                    colorToken="icon-secondary-3"
                    size={{ width: 8, height: 16 }}
                  />
                )}
                <IconVideoFilled colorToken="icon-secondary-2" />
              </StyledIconsWrapper>

              <StyledBroadcastTournamentName>
                {tournamentName}
              </StyledBroadcastTournamentName>

              <StyledCloseIcon
                colorToken="icon-secondary-3"
                onClick={handleClick}
              />
            </StyledBroadcastTournamentWrapper>

            <StyledBroadcastCompetitorsWrapper>
              <StyledCompetitors to={link}>
                {getEventTitle(event, false)}
              </StyledCompetitors>
            </StyledBroadcastCompetitorsWrapper>
          </StyledBroadcastHeader>

          <div data-vjs-player>
            {account ? (
              broadcast && broadcast?.broadcastType === BroadcastType.Iframe ? (
                <iframe
                  src={broadcast.broadcastUrl}
                  style={{ border: 'none', ...VIDEO_PLAYER_STYLES }}
                  title={String(broadcast.eventId)}
                  width={VIDEO_PLAYER_WIDTH}
                />
              ) : (
                <div ref={videoRef} style={VIDEO_PLAYER_STYLES} />
              )
            ) : (
              <StyledNotAuthorized>
                <StyledNotAuthorizedText
                  dangerouslySetInnerHTML={{
                    __html: t('you need to authorize to view translation')
                  }}
                />

                <StyledButtonSignIn
                  view="secondary-medium"
                  onClick={handleClickButton(EAuthPagesAndModal.SignIn)}
                >
                  {t('sign in')}
                </StyledButtonSignIn>
              </StyledNotAuthorized>
            )}
          </div>
        </StyledBroadcastWrapper>
      )}
    </Draggable>
  )
})
