import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

import { StyledIconButtonProps } from './IconButton.types'

export const StyledIconButton = styled.button<StyledIconButtonProps>`
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0;
  outline: none;
  padding: 0;

  ${({ theme, size, viewType, isCounter, isActive }) => css`
    align-items: center;
    display: flex;
    justify-content: center;
    transition: background-color 0.25s ease;

    svg {
      transition: fill 0.25s ease;
    }

    &:hover,
    &:active {
      svg {
        color: ${theme.tokens.colors.icon['icon-primary-2']};
      }
    }

    ${size === 'm' &&
    css`
      border-radius: ${theme.tokens.alias['border-radius'][
        'border-radius-s-6'
      ]}px;
      height: 24px;
      width: 24px;
    `}

    ${size === 'l' &&
    css`
      border-radius: ${theme.tokens.alias['border-radius'][
        'border-radius-m-8'
      ]}px;
      height: 32px;
      width: 32px;
    `}

    ${isCounter &&
    css`
      ${typography['Body / 12 Bold']}
      align-items: center;
      color: ${theme.tokens.colors.icon['icon-secondary-3']};
      display: flex;
      padding: 4px 8px 4px 4px;
      width: unset;
    `}
    
    ${viewType === 'ghost' &&
    css`
      &:hover {
        background-color: ${theme.tokens.colors.surface['surface-hover']};
      }
      &:active {
        background-color: ${theme.tokens.colors.surface['surface-active-4']};
      }
    `}

    ${viewType === 'fill' &&
    css`
      background-color: ${theme.tokens.colors.surface['surface-default']};
      &:hover {
        background-color: ${theme.tokens.colors.surface['surface-hover']};
      }
      &:active {
        background-color: ${theme.tokens.colors.surface['surface-active-4']};
      }
    `}

    ${viewType === 'outline' &&
    css`
      background-color: ${theme.tokens.colors.surface['surface-modal']};
      border: 1px solid ${theme.tokens.colors.border['border-default-gray']};
      &:hover {
        background-color: ${theme.tokens.colors.surface['surface-modal']};
        border: 1px solid ${theme.tokens.colors.border['border-hover']};
      }
      &:active {
        background-color: ${theme.tokens.colors.surface['surface-modal']};
        border: 1px solid ${theme.tokens.colors.border['border-default-gray']};
      }
    `}
    
    &:disabled, &:disabled:hover, &:disabled:active {
      background-color: ${theme.tokens.colors.surface['surface-modal']};
      border: 1px solid ${theme.tokens.colors.border['border-disabled']};
      svg {
        color: ${theme.tokens.colors.icon['icon-opacity']};
      }
    }

    ${isActive &&
    css`
      background: ${theme.tokens.colors.surface['surface-default']} !important;
      color: ${theme.tokens.colors.text['text-secondary-3']};

      svg {
        color: ${theme.tokens.colors.icon['icon-primary']} !important;
      }
    `}
  `}
`
