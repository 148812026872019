import React, { Dispatch, FC, SetStateAction, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { BetStatus } from 'betweb-openapi-axios'

import { NavTabs } from 'shared/ui/nav-tabs'
import { resetBetsHistory } from 'entities/legacy-api'

import { SECTION_SELECT_OPTIONS } from './constants'
import { NavTabsWrapper } from './SectionSelect.styled'

interface Props {
  betsStatus: BetStatus
  setBetsStatus: Dispatch<SetStateAction<BetStatus>>
}

export const SectionSelect: FC<Props> = ({ betsStatus, setBetsStatus }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetBetsHistory())
  }, [dispatch])

  return (
    <NavTabsWrapper>
      <NavTabs<true, BetStatus>
        items={SECTION_SELECT_OPTIONS}
        value={betsStatus}
        isControlled
        onChange={setBetsStatus}
      />
    </NavTabsWrapper>
  )
}
