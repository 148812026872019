import {
  EBetTypes,
  basketProviderActions,
  selectBasketBetTypeTab,
  selectBasketOutcomesLength
} from 'astra-core/containers/BasketProvider'
import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { selectUserSettingLayoutTheme } from 'astra-core/containers/SettingsProvider'

import { Tooltip } from 'shared/ui/Tooltip'

import {
  StyledBetTypeItemText,
  StyledBetTypeItemWrapper
} from './BasketNavItem.styled'
import { BetTypeItemProps } from './BasketNavItem.types'

const MIN_OUTCOMES_LENGTH = {
  [EBetTypes.Ordinar]: 0,
  [EBetTypes.Express]: 2,
  [EBetTypes.System]: 3
}

export const BasketNavItem: FC<BetTypeItemProps> = ({ locale, name }) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const betTypeTab = useSelector(selectBasketBetTypeTab)
  const outcomesLength = useSelector(selectBasketOutcomesLength)
  const typeTheme = useSelector(selectUserSettingLayoutTheme)

  const hoverText = useMemo(
    () =>
      EBetTypes.Express === name
        ? t('express hover text')
        : EBetTypes.System === name
        ? t('system hover text')
        : '',
    [t, name]
  )

  const isAllow = useMemo(() => {
    return ((outcomesLength as number) || 0) >= MIN_OUTCOMES_LENGTH[name]
  }, [name, outcomesLength])

  const handleBetTypeTabChange = useCallback(() => {
    if (isAllow) {
      dispatch(
        basketProviderActions.handleBetTypeTabChange({ betTypeTab: name })
      )
    }
  }, [dispatch, isAllow, name])

  const isCommon = useMemo(() => {
    return !hoverText || isAllow
  }, [hoverText, isAllow])

  return (
    <Tooltip isCommon={isCommon} title={hoverText}>
      <StyledBetTypeItemWrapper
        active={name === betTypeTab}
        disabled={!isAllow}
        typeTheme={typeTheme}
        onClick={handleBetTypeTabChange}
      >
        <StyledBetTypeItemText active={name === betTypeTab}>
          {t(locale)}
        </StyledBetTypeItemText>
      </StyledBetTypeItemWrapper>
    </Tooltip>
  )
}
