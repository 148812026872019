import { FC, PropsWithChildren, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  EMakeBetStatus,
  IBasketError,
  basketProviderActions,
  selectActiveLoyaltyByAppliedCouponIdsByBetType,
  selectBasketAppliedCoupon,
  selectBasketBetTypeTab,
  selectBasketBonusWallet,
  selectBasketMakeBetStatus,
  selectLoyaltyCouponCreatedStatus,
  selectMakeBetErrors,
  selectMakeBetErrorsBottom
} from 'astra-core/containers/BasketProvider'
import { ALL_BASKET_ERROR_MESSAGES } from 'astra-core/containers/BasketProvider/constants'
import {
  ELoyaltyCoupons,
  selectAccountBonus
} from 'astra-core/containers/AccountProvider'

import { BasketError } from 'entities/basket/ui/basket-error-message/BasketError'
import { BasketTooltipProvider } from 'shared/ui/Tooltip/basket-tooltip'
import { BasketBonus } from 'features/basket/basket-bonus'
import {
  BasketAvailableCoupons,
  BasketSuitableCoupons
} from 'features/basket/basket-bonus-coupons'
import { RootState } from 'shared/types/store'
import { identificationModel } from 'entities/constration'

import { AlertPassIdentification } from '../../ui/alert-pass-identification'

import {
  StyledBasketBottomWrapper,
  StyledBonusContainer,
  StyledButtonWrapper,
  StyledDividerLine
} from './BasketBottom.styled'
import { BasketButtonProps } from './BasketBottom.types'

const { useGetIsBlockedConstraint, IdentificationConstraints } =
  identificationModel

export const BasketBottom: FC<PropsWithChildren<BasketButtonProps>> = ({
  children,
  disabledButton
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()

  const bonus = useSelector(selectAccountBonus)
  const appliedCoupon = useSelector(selectBasketAppliedCoupon)
  const bonusWallet = useSelector(selectBasketBonusWallet)
  const makeBetStatus = useSelector(selectBasketMakeBetStatus)
  const errorsBottom = useSelector(selectMakeBetErrorsBottom)
  const errors: Dictionary<IBasketError[]> = useSelector(selectMakeBetErrors)

  const handleErrorClientBlock = () => {
    const errorValues = Object.values(errors).flat()
    return errorValues.find(
      (err) =>
        err.message === ALL_BASKET_ERROR_MESSAGES.CLIENT_BETS_BLOCK ||
        err.message === ALL_BASKET_ERROR_MESSAGES.BET_CHANGED_COEFFICIENT
    )
  }

  const errorClientBlock = handleErrorClientBlock()

  const makeBet = useCallback(() => {
    dispatch(basketProviderActions.makeBet())
  }, [dispatch])

  const isLoading = useMemo(
    () => EMakeBetStatus.loading === makeBetStatus,
    [makeBetStatus]
  )

  const availableCoupons = useSelector(selectLoyaltyCouponCreatedStatus)
  const betTypeTab = useSelector(selectBasketBetTypeTab)
  const suitableCoupons = useSelector((state: RootState) =>
    selectActiveLoyaltyByAppliedCouponIdsByBetType(
      state,
      ELoyaltyCoupons.SUITABLE
    )
  )

  const disabledCoupons = useMemo(() => {
    const suitabledIds = suitableCoupons[betTypeTab].map((item) => item.id)
    return availableCoupons.filter((item) => !suitabledIds.includes(item.id))
  }, [availableCoupons, suitableCoupons, betTypeTab])

  const isBlockedWithoutIdentification = useGetIsBlockedConstraint(
    IdentificationConstraints.BETS
  )

  return (
    <StyledBasketBottomWrapper>
      <BasketTooltipProvider>
        {children}

        {errorsBottom.map((error) => (
          <BasketError error={error} key={error.code} />
        ))}

        {errorClientBlock && (
          <BasketError error={errorClientBlock} key={errorClientBlock.code} />
        )}

        {(bonus || !!suitableCoupons[betTypeTab].length) && (
          <StyledBonusContainer>
            {!!bonus && <BasketBonus />}
            <StyledDividerLine />
            {!!suitableCoupons[betTypeTab].length && <BasketSuitableCoupons />}
          </StyledBonusContainer>
        )}

        {isBlockedWithoutIdentification && <AlertPassIdentification />}

        <StyledButtonWrapper
          disabled={disabledButton || isBlockedWithoutIdentification}
          isBonus={bonusWallet && !disabledButton}
          isCoupon={!!appliedCoupon}
          isLoading={isLoading}
          view="primary-extra-large"
          onClick={makeBet}
        >
          {t('make a bet')}
        </StyledButtonWrapper>

        {!!disabledCoupons.length && (
          <BasketAvailableCoupons coupons={disabledCoupons} />
        )}
      </BasketTooltipProvider>
    </StyledBasketBottomWrapper>
  )
}
