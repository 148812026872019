import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  DragDropContext,
  DragStart,
  DragUpdate,
  Droppable,
  DropResult
} from 'react-beautiful-dnd'

import { selectIsShowBasketOnSmallWindow } from 'entities/basket/model/selectors'
import { useInjectReducer } from 'shared/lib/@reduxjs'
import { basketReducer, basketSliceKey } from 'entities/basket/model'
import { LiveMatchBroadcast } from 'widgets/live-match-broadcast'
import { matchBroadcastModel } from 'entities/match-broadcast'
import {
  selectBroadcastsEventIds,
  selectBroadcastsEventIdsLength
} from 'entities/match-broadcast/model'
import { Content as BasketContent } from 'features/basket/ui/content'

import {
  StyledBasketAsideWrapper,
  StyledBasketWrapper,
  StyledDraggedDestinationElement,
  StyledLiveMatchBroadcastWrapper
} from './basket-aside.styled'
import { BasketTitle } from './basket-title'
import {
  DROPPABLE_PLACEHOLDER_DEFAULT_VALUE,
  getClientNodeValues,
  getDraggedDestinationElementPosition,
  getDraggedDom
} from './constants'
import { IDragDropContextEvent } from './basket-aside.types'

export const RightBasketContainer = () => {
  useInjectReducer({ key: basketSliceKey, reducer: basketReducer })

  return <BasketAside />
}

const BasketAside: FC = () => {
  const dispatch = useDispatch()
  const [placeholderProps, setPlaceholderProps] =
    useState<IDragDropContextEvent>(DROPPABLE_PLACEHOLDER_DEFAULT_VALUE)
  const isShowBasketOnSmallWindow = useSelector(selectIsShowBasketOnSmallWindow)
  const broadcastsEventIds = useSelector(selectBroadcastsEventIds)
  const broadcastsEventIdsLength = useSelector(selectBroadcastsEventIdsLength)

  const onDragEnd = (result: DropResult) => {
    setPlaceholderProps(DROPPABLE_PLACEHOLDER_DEFAULT_VALUE)

    if (!result.destination) return

    dispatch(
      matchBroadcastModel.liveMatchBroadcastActions.updateBroadcastList({
        sourceIndex: result.source.index,
        destinationIndex: result.destination.index
      })
    )
  }

  const handleDragStart = (event: DragStart) => {
    const draggedDOM = getDraggedDom(event.draggableId)

    if (!draggedDOM) {
      return
    }

    const parentNode = draggedDOM.parentNode as Element
    const childrenArray = Array.from(parentNode.children)
    const sourceIndex = event.source.index

    const values = getClientNodeValues({
      draggedDOM,
      parentNode,
      childrenArray,
      index: sourceIndex
    })

    setPlaceholderProps({
      ...values,
      destinationIndex: sourceIndex
    })
  }

  const handleDragUpdate = (event: DragUpdate) => {
    const draggedDOM = getDraggedDom(event.draggableId)

    if (!event.destination || !draggedDOM) {
      return
    }

    const parentNode = draggedDOM.parentNode as Element
    const childrenArray = Array.from(parentNode.children)
    const destinationIndex = event.destination.index

    const sourceIndex = event.source.index
    const movedItem = childrenArray[sourceIndex]
    childrenArray.splice(sourceIndex, 1)

    const updatedArray = [
      ...childrenArray.slice(0, destinationIndex),
      movedItem,
      ...childrenArray.slice(destinationIndex + 1)
    ]

    const values = getClientNodeValues({
      draggedDOM,
      parentNode,
      childrenArray: updatedArray,
      index: destinationIndex
    })

    setPlaceholderProps({
      ...values,
      destinationIndex
    })
  }

  return (
    <StyledBasketAsideWrapper
      isShowBasketOnSmallWindow={isShowBasketOnSmallWindow}
    >
      <StyledBasketWrapper>
        {!!broadcastsEventIdsLength && (
          <DragDropContext
            onDragEnd={onDragEnd}
            onDragStart={handleDragStart}
            onDragUpdate={handleDragUpdate}
          >
            <Droppable droppableId="numberList">
              {(provided, snapshot) => (
                <StyledLiveMatchBroadcastWrapper
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {broadcastsEventIds.map((broadcastId, index) => (
                    <LiveMatchBroadcast
                      broadcastId={broadcastId}
                      index={index}
                      key={broadcastId}
                    />
                  ))}
                  {provided.placeholder}
                  {snapshot.isDraggingOver && (
                    <StyledDraggedDestinationElement
                      style={getDraggedDestinationElementPosition(
                        placeholderProps
                      )}
                      destinationIndex={placeholderProps.destinationIndex}
                    />
                  )}
                </StyledLiveMatchBroadcastWrapper>
              )}
            </Droppable>
          </DragDropContext>
        )}

        <BasketContent BasketTypeTabs={<BasketTitle />} />
      </StyledBasketWrapper>
    </StyledBasketAsideWrapper>
  )
}
