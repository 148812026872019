import styled, { css } from 'styled-components'

import { OddModes } from 'entities/event-probability/model/odd-mode'
import { getProbabilityCellStyle } from 'features/probability-cell/lib/probability-cell-style'
import { typography } from 'shared/lib/theme/typography'
import { DecreaseIcon, IncreaseIcon } from 'features/probability-cell/ui'

export const StyledOutcomes = styled.div<{ isEventPage: boolean }>`
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  height: 100%;

  ${({ theme, isEventPage }) =>
    !isEventPage &&
    css`
      background: ${theme.tokens.colors.surface['surface-base']};
      padding: 0 ${theme.tokens.alias.space['space-xs-8']}px
        ${theme.tokens.alias.space['space-xs-8']}px
        ${theme.tokens.alias.space['space-xs-8']}px;
    `}
`

export const StyledOutcomesTables = styled.div<{ isEventPage: boolean }>`
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);

  &:empty {
    display: none;
  }

  ${({ theme, isEventPage }) =>
    !isEventPage &&
    css`
      background: ${theme.tokens.colors.surface['surface-page']};
      border-radius: ${theme.tokens.alias.space['space-xs-8']}px
        ${theme.tokens.alias.space['space-xs-8']}px
        ${theme.tokens.alias.space['space-m-16']}px
        ${theme.tokens.alias.space['space-m-16']}px;
    `}
`

export const OutcomesContent = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: 100%;
  overflow: auto;
`

export const ProbabilitiesWrapper = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const StyledOutcomesLoaderWrap = styled.div`
  align-items: center;
  display: flex;
  height: calc(100% - 189px);
  justify-content: center;
  min-height: 50px;
  position: relative;
  width: 100%;
`

export const StyledPanel = styled.div<{ opened: boolean }>`
  ${({ theme }) =>
    css`
      border-radius: ${theme.tokens.alias['border-radius'][
        'border-radius-l-12'
      ]}px;
      overflow: hidden;
    `}
`

export const StyledPanelHead = styled.div`
  align-items: center;
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 16px;

  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.surface['surface-base']};
    border-top-left-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-l-12'
    ]}px;
    border-top-right-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-l-12'
    ]}px;
    grid-column-gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
    padding: ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const StyledPanelTitle = styled.span`
  ${typography['Body / 12 BOLD']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
    > span {
      background: #ffbf0040;
      border-radius: ${theme.tokens.alias['border-radius'][
        'border-radius-xs-4'
      ]}px;
      margin: 0 -${theme.tokens.alias.space['space-xxxxs-2']}px;
      padding: 0 ${theme.tokens.alias.space['space-xxxxs-2']}px;
    }
  `}
`

export const StyledClearResultSearch = styled.div`
  height: calc(100% - 171px);
`

export const OutcomesHead = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
    padding: ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`
export const ButtonsBlock = styled.div`
  align-items: center;
  display: flex;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
  `}
`

export const StyledTableRadius = styled.div`
  overflow: hidden;
`

export const OddWrapper = styled.div<{
  isInBasket: boolean
  mode: OddModes
  isCentered: boolean
  isEmpty: boolean
}>`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: space-between;
  width: 100%;

  ${({ theme, isInBasket, mode, isCentered, isEmpty }) => css`
    ${isCentered &&
    css`
      justify-content: center;
      width: 55px;
    `}
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
    box-shadow: inset 0 0 0 1px
      ${theme.tokens.colors.border[
        isInBasket ? 'border-active' : 'border-primary'
      ]};
    padding: ${theme.tokens.alias.space['space-xs-8']}px;
    ${getProbabilityCellStyle({ isInBasket, mode, isEmpty })}
  `}
`
export const OddLabel = styled.span`
  ${typography['Body / 12 SemiBold']};

  > span {
    background: #ffbf0040;
    box-shadow: 12px 0 15px -4px #ffbf0040, -12px 0 8px -4px #ffbf0040;
  }
`

export const OddProbability = styled.span`
  ${typography['Body / 12 SemiBold']}
`

export const TableIncreaseIcon = styled(IncreaseIcon)`
  right: 3px;
  top: 3px;
`

export const TableDecreaseIcon = styled(DecreaseIcon)`
  bottom: 3px;
  right: 3px;
`

export const MessageNothingFoundContainer = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: ${theme.tokens.alias.space['space-s-12']}px;
    height: 100%;
    justify-content: center;
    padding: ${theme.tokens.alias.space['space-xxxxs-2']}rem 0;
    width: 100%;
  `}
`
