import { RefObject, useEffect, useMemo, useRef, useState } from 'react'

export const useTextOverflow = <T extends HTMLElement>() => {
  const [overflows, setOverflows] = useState(false)
  const targetRef = useRef<T>(null)

  const previousObserver = useRef<ResizeObserver | null>(null)

  useEffect(() => {
    if (previousObserver.current) {
      previousObserver.current.disconnect()
      previousObserver.current = null
    }

    if (targetRef.current?.nodeType === Node.ELEMENT_NODE) {
      const observer = new ResizeObserver(([entry]) => {
        if (entry && entry.target) {
          const { offsetWidth, scrollWidth, offsetHeight, scrollHeight } =
            entry.target as HTMLElement
          setOverflows(
            offsetWidth < scrollWidth ||
              Math.floor(scrollHeight / offsetHeight) > 1
          )
        }
      })

      observer.observe(targetRef.current)
      previousObserver.current = observer
    }
  }, [])

  useEffect(() => {
    return () => {
      previousObserver.current?.disconnect()
      previousObserver.current = null
    }
  }, [])

  return useMemo(() => [targetRef, overflows], [overflows]) as [
    RefObject<T>,
    boolean
  ]
}
