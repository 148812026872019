import React, { FC, PropsWithChildren, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalRef } from 'shared/ui/modal/modal.types'
import { Modal } from 'shared/ui/modal'
import { Typography } from 'shared/ui/typography'

import {
  StyledBonusModalText,
  StyledModalScrollable,
  StyledModalTextWrapper,
  StyledOpenModalWrapper
} from './express-bonus-modal.styled'

export const ExpressBonusModal: FC<PropsWithChildren> = ({ children }) => {
  const [t] = useTranslation()

  const modalRef = useRef<ModalRef>(null)

  const handleOpenModal = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    modalRef.current?.open()
  }, [])

  const text = (
    <StyledModalTextWrapper>
      <StyledModalScrollable>
        <StyledBonusModalText>
          <Typography color="text-primary-2" font="Body / 14 Medium">
            {t('express bet increase Bonus')}
            <Typography color="text-description" font="Body / 14 Medium">
              *
            </Typography>
            . {t('bonus awarded upon bet calculation')}
          </Typography>

          <ol>
            {t('more events more bonus')}
            <li>
              <span>{t('events 2 to 10 total coefficient')}</span>
              <ul>
                <li>2 {t('events express')} - 2%</li>
                <li>3 {t('events express')} - 3%</li>
                <li>4 {t('events express')} - 4%</li>
                <li>5 {t('events count')} - 5%</li>
                <li>6 {t('events count')} - 6%</li>
                <li>7 {t('events count')} - 7%</li>
                <li>8 {t('events count')} - 8%</li>
                <li>9 {t('events count')} - 9%</li>
                <li>10 {t('events count')} - 10%</li>
              </ul>
            </li>
            <li>
              <span>{t('events 11 to 20 min coefficient')}</span>
              <ul>
                <li>11 {t('events count')} - 15%</li>
                <li>12 {t('events count')} - 20%</li>
                <li>13 {t('events count')} - 30%</li>
                <li>14 {t('events count')} - 40%</li>
                <li>15 {t('events count')} - 50%</li>
                <li>16 {t('events count')} - 60%</li>
                <li>17 {t('events count')} - 70%</li>
                <li>18 {t('events count')} - 80%</li>
                <li>19 {t('events count')} - 90%</li>
                <li>
                  {t('from')} 20 {t('events count')} - 100%
                </li>
              </ul>
            </li>
          </ol>
          <a
            href="https://olimplanding.kz/landing/public/uploads/233973-288-bonus-na_express_ru_ru.pdf"
            rel="noreferrer"
            target="_blank"
          >
            {t('all rules')}
          </a>
          <Typography color="text-description" font="Body / 12 Medium">
            {t('express bonus rule description')}
          </Typography>
        </StyledBonusModalText>
      </StyledModalScrollable>
    </StyledModalTextWrapper>
  )

  return (
    <>
      <StyledOpenModalWrapper onClick={handleOpenModal}>
        {children}
      </StyledOpenModalWrapper>

      <Modal ref={modalRef} title={t('bonus express 100')}>
        {text}
      </Modal>
    </>
  )
}
