import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { range } from 'lodash'

import { ContentLoader } from 'shared/ui/content-loader/content-loader'
import { Typography } from 'shared/ui/typography'

export const EventsTableLoader = ({ count = 3 }: { count?: number }) => {
  return (
    <div>
      <HeaderLoader />
      {range(0, count).map((item) => (
        <RowLoader key={item} />
      ))}
    </div>
  )
}

const HeaderLoader = () => {
  const { t } = useTranslation()
  return (
    <Header>
      <HeaderLeft>
        <ContentLoader borderRadius={4} height={16} width={16} />
        <ContentLoader borderRadius={4} height={12} width={64} />
      </HeaderLeft>
      <HeaderRight>
        <HeaderRightBlocks>
          <Typography color="text-secondary-2" font="Hint / 11 SemiBold">
            {t('w1')}
          </Typography>
          <Typography color="text-secondary-2" font="Hint / 11 SemiBold">
            {t('X')}
          </Typography>
          <Typography color="text-secondary-2" font="Hint / 11 SemiBold">
            {t('w2')}
          </Typography>
        </HeaderRightBlocks>
        <HeaderRightBlocks>
          <Typography color="text-secondary-2" font="Hint / 11 SemiBold">
            {t('1X')}
          </Typography>
          <Typography color="text-secondary-2" font="Hint / 11 SemiBold">
            {t('12')}
          </Typography>
          <Typography color="text-secondary-2" font="Hint / 11 SemiBold">
            {t('X2')}
          </Typography>
        </HeaderRightBlocks>
        <HeaderRightBlocks>
          <Typography color="text-secondary-2" font="Hint / 11 SemiBold">
            {t('hc1')}
          </Typography>
          <Typography color="text-secondary-2" font="Hint / 11 SemiBold">
            {t('hc2')}
          </Typography>
        </HeaderRightBlocks>
        <HeaderRightBlocks
          style={{
            gridTemplateColumns: '42px 36px 36px'
          }}
        >
          <Typography color="text-secondary-2" font="Hint / 11 SemiBold">
            {t('total')}
          </Typography>
          <Typography color="text-secondary-2" font="Hint / 11 SemiBold">
            {t('total over')}
          </Typography>
          <Typography color="text-secondary-2" font="Hint / 11 SemiBold">
            {t('total under')}
          </Typography>
        </HeaderRightBlocks>
      </HeaderRight>
    </Header>
  )
}

const RowLoader = () => {
  return (
    <Row>
      <RowLeft>
        <ContentLoader borderRadius={4} height={12} width={157} />
        <ContentLoader borderRadius={4} height={12} width={97} />
      </RowLeft>
      <RowRight>
        <ContentLoader borderRadius={4} height={32} width={112} />
        <ContentLoader borderRadius={4} height={32} width={112} />
        <ContentLoader borderRadius={4} height={32} width={146} />
        <ContentLoader borderRadius={4} height={32} width={118} />
        <ContentLoader borderRadius={4} height={32} width={16} />
      </RowRight>
    </Row>
  )
}

const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.surface['surface-event-title']};
    box-shadow: inset 0 -1px 0 ${theme.tokens.colors.divider['divider-primary']};
    padding: ${theme.tokens.alias.space['space-xxxxs-2']}px
      ${theme.tokens.alias.space['space-xxxl-40']}px
      ${theme.tokens.alias.space['space-xxxxs-2']}px
      ${theme.tokens.alias.space['space-m-16']}px;
  `}
`

const HeaderLeft = styled.div`
  align-items: center;
  display: flex;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
  `}
`

const HeaderRight = styled.div`
  align-items: center;
  display: grid;
  gap: 8px;
  grid-template-columns: 112px 112px 146px 118px;
  grid-template-rows: 20px;
`

export const HeaderRightBlocks = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.tokens.alias.space['space-xxxxs-2']}px;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  justify-items: center;
`

const Row = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.surface['surface-base']};
    box-shadow: inset 0 -1px 0 ${theme.tokens.colors.divider['divider-primary']};
    padding: 0 ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

const RowLeft = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
    padding: ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

const RowRight = styled.div`
  display: flex;
  justify-content: flex-end;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
    padding: ${theme.tokens.alias.space['space-xxs-6']}px
      ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`
