import { Tournament } from 'betweb-openapi-axios'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import {
  ESportsCodes,
  selectSport
} from 'astra-core/containers/CommonDataProvider'

import { SportIcon } from 'shared/ui/Icon/sport-icon'
import { Typography } from 'shared/ui/typography'
import { getSearchTournamentLink } from 'shared/lib/events'
import { SearchHighlighter } from 'features/global-search/ui/search-highlighter'

import {
  SearchTournamentRowWrapper,
  SportIconWrapper
} from './search-tournament-row.styled'

interface Props {
  tournament: Tournament
}

export const SearchTournamentRow: FC<Props> = ({ tournament }) => {
  const tournamentLink = getSearchTournamentLink(tournament)
  const sport = useSelector((state) => selectSport(state, tournament.sportId))
  return (
    <SearchTournamentRowWrapper to={tournamentLink}>
      <SportIconWrapper>
        <SportIcon size="s" sport={sport?.code as ESportsCodes} />
      </SportIconWrapper>
      <SearchHighlighter
        color="text-primary-2"
        font="Body / 12 Medium"
        lineClamp={1}
        text={tournament.name}
      />
      <Typography color="text-secondary-2" font="Hint / 10 Bold">
        {tournament.totalEventCount}
      </Typography>
    </SearchTournamentRowWrapper>
  )
}
