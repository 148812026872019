import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
  useFeatureFlag,
  EFeatureFlags
} from 'astra-core/containers/ConfigProvider'

import { Button } from 'shared/ui/Button'
import { IconTop } from 'shared/ui/Icon/General/IconTop'
import {
  selectFilterIsTop,
  selectLiveEventsBySearch,
  selectLayoutIsLoading,
  selectLiveFetchItems,
  getFilterIsBroadcast,
  selectIsExistBroadcastForCurrentEvents
} from 'pages/page-live-events/LiveContainer/selectors'
import { liveContainerActions } from 'pages/page-live-events/LiveContainer/slice'
import { useIsSmall, useIsTabletOrMobile } from 'shared/lib/styled'
import { BasketButtonSmallWindow } from 'features/basket/open-basket-small-window'
import { RootState } from 'shared/types/store'
import { LiveEventsParams } from 'pages/page-live-events/LiveContainer/types'
import { IconVideoFilled } from 'shared/ui/Icon/General/IconVideoFilled'
import { LineSearch } from 'features/line-search'
import { useIsEventListHasTop } from 'entities/event'

import {
  StyledButtonsWrapper,
  StyledSearchDivider,
  StyledSearchWrapper
} from './live-content.styled'

export const LiveContent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isTop = useSelector(selectFilterIsTop)
  const isBroadcast = useSelector(getFilterIsBroadcast)
  const isLayoutIsLoading = useSelector(selectLayoutIsLoading)
  const liveFetchItems = useSelector(selectLiveFetchItems)

  const isFeatureFlagBroadcastEnabled = useFeatureFlag(
    EFeatureFlags.BROADCAST_ENABLED
  )

  const { sportId } = useParams<LiveEventsParams>()

  const isBroadcastFilterButtonEnabled = useSelector((state: RootState) =>
    selectIsExistBroadcastForCurrentEvents(state, { sportId })
  )
  const liveEvents = useSelector((state: RootState) =>
    selectLiveEventsBySearch(state, { sportId })
  )
  const { isEventListHasTop } = useIsEventListHasTop(liveEvents)

  const toggleFilterTop = useCallback(
    () => dispatch(liveContainerActions.toggleFilterIsTop()),
    [dispatch]
  )

  const toggleFilterBroadcast = useCallback(
    () => dispatch(liveContainerActions.toggleFilterIsBroadcast()),
    [dispatch]
  )

  useEffect(() => {
    if (
      isTop &&
      !isEventListHasTop &&
      !isLayoutIsLoading &&
      !liveFetchItems.loading
    ) {
      toggleFilterTop()
    }
  }, [
    isTop,
    isEventListHasTop,
    toggleFilterTop,
    dispatch,
    isLayoutIsLoading,
    liveFetchItems.loading
  ])

  const isTabletOrMobile = useIsTabletOrMobile()
  const isSmall = useIsSmall()

  return (
    <StyledButtonsWrapper>
      <StyledSearchWrapper>
        <LineSearch placeholder={t('search in live')} />
      </StyledSearchWrapper>
      <StyledSearchDivider />
      {isFeatureFlagBroadcastEnabled && (
        <Button
          disabled={!isBroadcastFilterButtonEnabled}
          icon={IconVideoFilled}
          isActive={isBroadcast}
          view="filter"
          onClick={toggleFilterBroadcast}
        >
          {t('with broadcast')}
        </Button>
      )}

      <Button
        disabled={!isEventListHasTop || isLayoutIsLoading}
        icon={IconTop}
        isActive={isTop}
        view="filter"
        onClick={toggleFilterTop}
      >
        {t('only top')}
      </Button>

      {(isTabletOrMobile || isSmall) && <BasketButtonSmallWindow isSmall />}
    </StyledButtonsWrapper>
  )
}
