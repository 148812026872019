import { useDispatch } from 'react-redux'
import { useEffect } from 'react'

import { BalanceOperationsTypes, paymentProviderActions } from '../../model'

export const useReset = (balanceOperationType: BalanceOperationsTypes) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      paymentProviderActions.reset({
        balanceOperationsType: balanceOperationType
      })
    )
  }, [balanceOperationType, dispatch])
}
