import { useDispatch, useSelector } from 'react-redux'
import {
  selectBetsHistoryBetsStatus,
  selectBetsHistoryFiltersDates
} from 'astra-core/containers/BetsHistoryProvider'
import React, { useEffect, useMemo, useState } from 'react'
import { BetStatus } from 'betweb-openapi-axios'
import dayjs from 'dayjs'

import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import {
  betsHistoryApi,
  LegacyBet,
  resetBetsHistory
} from 'entities/legacy-api'

import { BETS_HISTORY_PAGE_SIZE } from '../lib'

import { SelectBetStatus } from './SelectBetStatus'
import { PickerPeriod } from './PickerPeriod'
import { BasketHistoryCard } from './BasketHistoryCard'
import {
  StyledBasketHistoryFilters,
  StyledBasketHistoryList,
  StyledBasketHistoryScrollable,
  StyledBasketHistoryWrapper,
  StyledListWrapper,
  StylesBasketHistoryContent
} from './BasketHistory.styled'
import { EmptyHistoryScreen } from './EmptyHistoryScreen'

const { useGetBetsHistoryQuery } = betsHistoryApi

export const BasketHistoryLegacy = () => {
  const [pagination, setPagination] = useState({
    limit: BETS_HISTORY_PAGE_SIZE,
    offset: 0
  })
  const [betsStatus, setBetsStatus] = useState<BetStatus>(BetStatus.Unknown)
  const dates = useSelector(selectBetsHistoryFiltersDates)

  const dispatch = useDispatch()

  const filter = useMemo(() => {
    switch (betsStatus) {
      case BetStatus.Computed:
        return '10000'
      case BetStatus.Current:
        return '01000'
      case BetStatus.Won:
        return '00100'
      case BetStatus.Lost:
        return '00010'
      case BetStatus.Returned:
        return '00001'
      default:
        return '00000'
    }
  }, [betsStatus])

  const { data, isFetching } = useGetBetsHistoryQuery({
    time_shift: 0,
    filter,
    ...pagination,
    date:
      dates.startDate && dates.endDate
        ? dayjs(dates.startDate).format('YYYY-MM-DD')
        : undefined,
    date_end:
      dates.startDate && dates.endDate
        ? dayjs(dates.endDate).format('YYYY-MM-DD')
        : undefined,
    offset: pagination.offset / BETS_HISTORY_PAGE_SIZE
  })

  useEffect(() => {
    return () => {
      dispatch(resetBetsHistory())
    }
  }, [dispatch])

  const betsList = data?.data.bet_list ?? []
  const totalCount = data?.data.count ?? 0

  const hasMore = totalCount > betsList.length

  const handleBetStatusChange = (newStatus: BetStatus) => {
    setBetsStatus(newStatus)
    dispatch(resetBetsHistory())
    setPagination({
      offset: 0,
      limit: BETS_HISTORY_PAGE_SIZE
    })
  }

  return (
    <StyledBasketHistoryWrapper>
      <StyledBasketHistoryFilters>
        <SelectBetStatus value={betsStatus} onChange={handleBetStatusChange} />
        <PickerPeriod />
      </StyledBasketHistoryFilters>

      {isFetching && pagination.offset === 0 ? (
        <LoaderSpinner />
      ) : (
        <StyledBasketHistoryScrollable>
          <StylesBasketHistoryContent>
            <StyledListWrapper
              pagination={{
                pagination,
                onSetPagination: setPagination,
                hasMore,
                isLoading: isFetching
              }}
            >
              <BasketHistoryList bets={betsList} />
            </StyledListWrapper>
          </StylesBasketHistoryContent>
        </StyledBasketHistoryScrollable>
      )}
    </StyledBasketHistoryWrapper>
  )
}

export const BasketHistoryList = ({ bets }: { bets: LegacyBet[] }) => {
  const betsStatus = useSelector(selectBetsHistoryBetsStatus)

  const betsListLength = bets.length

  return (
    <>
      {betsListLength ? (
        <StyledBasketHistoryList>
          {bets.map((item, index) => (
            <BasketHistoryCard
              isLastElement={index === betsListLength - 1}
              item={item}
              key={item.bet_id}
            />
          ))}
        </StyledBasketHistoryList>
      ) : (
        <EmptyHistoryScreen withBetStatus={betsStatus !== null} />
      )}
    </>
  )
}
