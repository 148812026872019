import { FC } from 'react'
import { selectSport } from 'astra-core/containers/CommonDataProvider'
import { useSelector } from 'react-redux'

import { useTextOverflow } from 'shared/lib/text-overflow/use-text-overflow'
import { Tooltip } from 'shared/ui/Tooltip'
import { LegacyBetEvent } from 'entities/legacy-api'

import {
  StyledBasketHistoryOutcome,
  StyledBasketHistoryOutcomeCoefficient,
  StyledBasketHistoryOutcomeCoefficientValue,
  StyledBasketHistoryOutcomeCompetitors,
  StyledBasketHistoryOutcomeName,
  StyledBasketHistoryOutcomeTitle,
  StyledBasketHistoryOutcomeTitleText
} from './BasketHistoryOutcome.styled'

export const BasketHistoryOutcome: FC<{
  outcome: LegacyBetEvent
}> = ({ outcome }) => {
  const [namesRef, isNamesOverflowing] = useTextOverflow<HTMLDivElement>()
  const [teamsRef, isTeamsOverflowing] = useTextOverflow<HTMLSpanElement>()
  const [outcomeRef, isOutcomeOverflowing] = useTextOverflow<HTMLSpanElement>()

  const eventSport = useSelector((state) =>
    selectSport(state, outcome.sport_id || 0)
  )

  return (
    <StyledBasketHistoryOutcome>
      <div>
        <StyledBasketHistoryOutcomeTitle>
          <Tooltip isCommon={!isNamesOverflowing} title={eventSport?.name}>
            <StyledBasketHistoryOutcomeTitleText ref={namesRef}>
              {eventSport?.name}
            </StyledBasketHistoryOutcomeTitleText>
          </Tooltip>
        </StyledBasketHistoryOutcomeTitle>

        <Tooltip isCommon={!isTeamsOverflowing} title={outcome.matchname}>
          <StyledBasketHistoryOutcomeCompetitors ref={teamsRef}>
            {outcome.matchname}
          </StyledBasketHistoryOutcomeCompetitors>
        </Tooltip>
      </div>

      <Tooltip isCommon={!isOutcomeOverflowing} title={outcome.matchname}>
        <StyledBasketHistoryOutcomeCoefficient>
          <StyledBasketHistoryOutcomeName ref={outcomeRef}>
            {outcome.eventname}
          </StyledBasketHistoryOutcomeName>

          <StyledBasketHistoryOutcomeCoefficientValue>
            {outcome.value}
          </StyledBasketHistoryOutcomeCoefficientValue>
        </StyledBasketHistoryOutcomeCoefficient>
      </Tooltip>
    </StyledBasketHistoryOutcome>
  )
}
