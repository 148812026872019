import { FC, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  searchProviderActions,
  selectFetchItemsLoading,
  selectSearchEventsItems,
  selectSearchTournamentsItems
} from 'astra-core/containers/SearchProvider'
import { useTranslation } from 'react-i18next'

import { SearchTournamentRow, GlobalSearchTabs } from 'features/global-search'
import { EventsTable, EventsTableLoader } from 'widgets/events-table'
import { Typography } from 'shared/ui/typography'
import { Skeleton } from 'shared/ui/skeleton'
import { Button } from 'shared/ui/Button'

import {
  EmptyWrapper,
  SearchResultSectionTitle,
  SearchResultsWrapper,
  SectionTitleWrapper
} from './search-results.styled'

interface Props {
  sportId: number
  selectedTab: GlobalSearchTabs
}
export const SearchResults: FC<Props> = ({ sportId, selectedTab }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const events = useSelector(selectSearchEventsItems)
  const tournaments = useSelector(selectSearchTournamentsItems)
  const loading = useSelector(selectFetchItemsLoading)

  const liveEvents = useMemo(
    () =>
      events.filter(
        (e) => e.live && (sportId ? e.tournament.sportId === sportId : true)
      ),
    [events, sportId]
  )
  const lineEvents = useMemo(
    () =>
      events.filter(
        (e) => !e.live && (sportId ? e.tournament.sportId === sportId : true)
      ),
    [events, sportId]
  )
  const filteredTournaments = useMemo(
    () => tournaments.filter((t) => (sportId ? t.sportId === sportId : true)),
    [sportId, tournaments]
  )

  const clearValue = useCallback(() => {
    dispatch(searchProviderActions.setSearch({ valueSearch: '' }))
  }, [dispatch])

  const totalResult = events.length + tournaments.length

  const isEmpty =
    (selectedTab === GlobalSearchTabs.All &&
      !lineEvents.length &&
      !liveEvents.length &&
      !filteredTournaments.length) ||
    (selectedTab === GlobalSearchTabs.Events &&
      !lineEvents.length &&
      !liveEvents.length) ||
    (selectedTab === GlobalSearchTabs.Tournaments &&
      !filteredTournaments.length)

  if ((!totalResult || isEmpty) && !loading) {
    return (
      <EmptyWrapper>
        <div>
          <Typography font="Body / 16 SemiBold">
            {t('nothing found')}
          </Typography>
          <Typography color="text-secondary-3" font="Body / 14 Medium">
            {t('check if there are any typos or try another query')}
          </Typography>
        </div>
        <Button view="secondary-large" onClick={clearValue}>
          {t('reset')}
        </Button>
      </EmptyWrapper>
    )
  }

  return (
    <Skeleton
      styledFallback={
        <>
          <SearchResultSectionTitle>
            <SectionTitleWrapper>
              <Typography font="Body / 16 Bold">{t('search')}</Typography>
            </SectionTitleWrapper>
          </SearchResultSectionTitle>
          <EventsTableLoader />
          <EventsTableLoader />
        </>
      }
      isLoading={!!loading}
    >
      <SearchResultsWrapper>
        {!!liveEvents.length &&
          selectedTab !== GlobalSearchTabs.Tournaments && (
            <>
              <SearchResultSectionTitle>
                <SectionTitleWrapper>
                  <Typography font="Body / 16 Bold">
                    {t('live events')}&nbsp;({liveEvents.length})
                  </Typography>
                </SectionTitleWrapper>
              </SearchResultSectionTitle>
              <EventsTable
                events={liveEvents}
                headerProps={{ withIcon: true }}
                rowProps={{ withSearchHighlight: true }}
              />
            </>
          )}

        {!!lineEvents.length &&
          selectedTab !== GlobalSearchTabs.Tournaments && (
            <>
              <SearchResultSectionTitle>
                <SectionTitleWrapper>
                  <Typography font="Body / 16 Bold">
                    {t('line events')}&nbsp;({lineEvents.length})
                  </Typography>
                </SectionTitleWrapper>
              </SearchResultSectionTitle>
              <EventsTable
                events={lineEvents}
                headerProps={{ withIcon: true }}
                rowProps={{ withSearchHighlight: true }}
              />
            </>
          )}

        {!!filteredTournaments.length &&
          selectedTab !== GlobalSearchTabs.Events && (
            <>
              <SearchResultSectionTitle>
                <SectionTitleWrapper>
                  <Typography font="Body / 16 Bold">
                    {t('tournaments')}&nbsp;({filteredTournaments.length})
                  </Typography>
                </SectionTitleWrapper>
              </SearchResultSectionTitle>
              {filteredTournaments.map((tournament) => (
                <SearchTournamentRow
                  key={tournament.id}
                  tournament={tournament}
                />
              ))}
            </>
          )}
      </SearchResultsWrapper>
    </Skeleton>
  )
}
