import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  betsHistoryProviderActions,
  selectBetsHistoryBetsStatus
} from 'astra-core/containers/BetsHistoryProvider'

import { Select } from 'shared/ui/select'

import { ALL_OPTION_VALUE, BET_STATUS_OPTIONS } from '../../lib'

import { SelectWrapper } from './SelectBetStatus.styled'

export const SelectBetStatus = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const betsStatus = useSelector(selectBetsHistoryBetsStatus)

  const optionsSelectCheckbox = useMemo(() => BET_STATUS_OPTIONS(t), [t])

  const handleBetsStatusChange = useCallback(
    (value) => {
      if (value.value === betsStatus) {
        return
      }
      if (value.value === ALL_OPTION_VALUE) {
        dispatch(betsHistoryProviderActions.setFilterBetStatus(null))
      } else if (betsStatus !== value.value) {
        dispatch(betsHistoryProviderActions.setFilterBetStatus(value.value))
      }
    },
    [dispatch, betsStatus]
  )

  return (
    <SelectWrapper>
      <Select
        value={
          optionsSelectCheckbox.find((item) => item.value === betsStatus) ||
          optionsSelectCheckbox[0]
        }
        options={optionsSelectCheckbox}
        onChange={handleBetsStatusChange}
      />
    </SelectWrapper>
  )
}
