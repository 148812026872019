import { FC } from 'react'
import { useSelector } from 'react-redux'
import { selectConfig } from 'astra-core/containers/ConfigProvider'

import { ColumnProps, ColumnTypes } from './column.types'
import * as S from './column.styled'

const COLUMN_TYPE_STYLES_MAP = {
  [ColumnTypes.THREE_COLUMN]: S.ThreeColumnLayout,
  [ColumnTypes.THREE_COLUMN_WIDE_FILTER]: S.ThreeColumnWideFilterLayout,
  [ColumnTypes.TWO_COLUMN]: S.TwoColumnLayout
}

export const Column: FC<ColumnProps> = ({
  children,
  className,
  type = ColumnTypes.THREE_COLUMN,
  leftGridArea = 'sports-menu'
}) => {
  const config = useSelector(selectConfig)

  const ColumnTypeComponent = COLUMN_TYPE_STYLES_MAP[type]

  if (ColumnTypeComponent) {
    return (
      <ColumnTypeComponent
        className={className}
        hideHeader={config.IS_IFRAME}
        leftGridArea={leftGridArea}
      >
        {children}
      </ColumnTypeComponent>
    )
  } else {
    return (
      <S.OneColumnLayout className={className} hideHeader={config.IS_IFRAME}>
        {children}
      </S.OneColumnLayout>
    )
  }
}
