import React, { FC, ReactElement } from 'react'
import { useSelector } from 'react-redux'
import {
  EMakeBetStatus,
  selectBasketHeaderTab,
  selectBasketMakeBetStatus
} from 'astra-core/containers/BasketProvider'

import { CLOSE_BASKET_ON_SMALL_WINDOW_TIMEOUT } from 'entities/basket/lib'

import { BASKET_CONTENT_MAP } from '../../model/basket-content-map'
import { useCollapseBasket } from '../../lib'

import { EBasketHeaderTypes } from './content.types'

export const Content: FC<{ BasketTypeTabs: ReactElement }> = ({
  BasketTypeTabs
}) => {
  const currentBetStatus = useSelector(selectBasketMakeBetStatus)
  const basketType = useSelector(selectBasketHeaderTab)
  const basketContent = BASKET_CONTENT_MAP[EBasketHeaderTypes[basketType]]

  useCollapseBasket({
    currentBetStatus,
    delayToClose: CLOSE_BASKET_ON_SMALL_WINDOW_TIMEOUT
  })

  return (
    <>
      {currentBetStatus === EMakeBetStatus.init && BasketTypeTabs}
      {basketContent.status[currentBetStatus] || basketContent.content}
    </>
  )
}
