import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { layoutContainerActions } from 'containers/LayoutContainer/slice'
import { IconSetting } from 'shared/ui/Icon/General/IconSetting'
import { IconButton } from 'shared/ui/buttons/IconButton'

export const SettingsButton = () => {
  const dispatch = useDispatch()
  const handleClickSettings = useCallback(() => {
    dispatch(layoutContainerActions.setIsDisplayModalSettings(true))
  }, [dispatch])

  return (
    <IconButton
      icon={IconSetting}
      view="ghost-l"
      onClick={handleClickSettings}
    />
  )
}
