import {
  accountProviderActions,
  selectAccountId,
  UseAccountProviderProps
} from 'astra-core/containers/AccountProvider'
import { useInjectReducer, useInjectSaga } from 'astra-core'
import { reducer, sliceKey } from 'astra-core/containers/AccountProvider/slice'
import { accountProviderSaga } from 'astra-core/containers/AccountProvider/saga'
import { useDispatch, useSelector } from 'react-redux'
import { useAccountProviderSSE } from 'astra-core/containers/AccountProvider/hooks/useAccountProviderSSE'
import { useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'

import { ERoutes } from 'shared/types/routes'

export function useAccountProvider({
  getEventSource
}: UseAccountProviderProps) {
  useInjectReducer({ key: sliceKey, reducer })
  useInjectSaga({ key: sliceKey, saga: accountProviderSaga })
  const match = useRouteMatch([
    ERoutes.SuccessLoginPage,
    ERoutes.SuccessFullSignUp,
    ERoutes.SuccessFastSignUp
  ])
  const accountId = useSelector(selectAccountId)

  const dispatch = useDispatch()

  useAccountProviderSSE({ getEventSource, accountId })

  useEffect(() => {
    if (!match) {
      dispatch(accountProviderActions.getUser({}))
    }
  }, [dispatch, match])

  useEffect(() => {
    if (accountId) {
      dispatch(accountProviderActions.getSportGamesToken({ accountId }))
    }
  }, [accountId, dispatch])

  useEffect(() => {
    if (accountId) {
      dispatch(accountProviderActions.fetchLoyalty())
    }
  }, [accountId, dispatch])
}
