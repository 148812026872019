import { ComponentProps, forwardRef } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { selectInputSearch } from 'astra-core/containers/SearchProvider'

import { Typography } from 'shared/ui/typography'
import { splitSearchValue } from 'shared/lib/search'

const StyledSearchHighlighter = styled(Typography)`
  > span {
    background: #ffbf0040;
  }
`

export const SearchHighlighter = forwardRef<
  HTMLSpanElement,
  Omit<ComponentProps<typeof Typography>, 'children'> & { text: string }
>(({ text, ...props }, ref) => {
  const valueSearch = useSelector(selectInputSearch)

  return (
    <StyledSearchHighlighter
      {...props}
      dangerouslySetInnerHTML={{
        __html: splitSearchValue(text, valueSearch)
      }}
      ref={ref}
    />
  )
})
