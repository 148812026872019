import React from 'react'

import { IconChevronDoubleUp } from 'shared/ui/Icon/General/IconChevronDoubleUp'
import { IconButton } from 'shared/ui/buttons/IconButton'
import { useBetsHistoryLegacyContext } from 'entities/bet-history/lib/bet-history-legacy.context'

export const ButtonToggleCollapse = () => {
  const { allExpressSystemCollapsed, toggleCollapseAll, collapseAllDisabled } =
    useBetsHistoryLegacyContext()

  return (
    <IconButton
      disabled={collapseAllDisabled}
      icon={IconChevronDoubleUp}
      twist={allExpressSystemCollapsed ? 0 : 180}
      view="outline-l"
      onClick={toggleCollapseAll}
    />
  )
}
