import {
  basketProviderActions,
  EBetTypes
} from 'astra-core/containers/BasketProvider'
import { FC, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { IconButton } from 'shared/ui/buttons/IconButton'
import { IconTrashFilled } from 'shared/ui/Icon/General/IconTrashFilled'
import { BasketTooltipProvider } from 'shared/ui/Tooltip/basket-tooltip'

import { BasketNavItem } from '../basket-nav-item'

import { BetTypesLocale } from './BasketNav.types'
import {
  StyledBetsWrapper,
  StyledNavSpace,
  StyledNavWrapper
} from './BasketNav.styled'

const betTypesLocale: BetTypesLocale[] = [
  { name: EBetTypes.Ordinar, locale: 'ordinar' },
  { name: EBetTypes.Express, locale: 'express' },
  { name: EBetTypes.System, locale: 'system' }
]

export const BasketNav: FC = () => {
  const dispatch = useDispatch()

  const deleteBasket = useCallback(() => {
    dispatch(basketProviderActions.deleteBasket())
  }, [dispatch])

  return (
    <StyledNavSpace>
      <BasketTooltipProvider>
        <StyledNavWrapper>
          <StyledBetsWrapper>
            {betTypesLocale.map(({ name, locale }) => (
              <BasketNavItem key={name} locale={locale} name={name} />
            ))}
          </StyledBetsWrapper>
          <IconButton
            icon={IconTrashFilled}
            view="outline-l"
            onClick={deleteBasket}
          />
        </StyledNavWrapper>
      </BasketTooltipProvider>
    </StyledNavSpace>
  )
}
