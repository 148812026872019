import { useSelector } from 'react-redux'

import {
  selectActivePaymentTabValue,
  BalanceOperationsTypes,
  selectPaymentMethodVariants
} from '../../../model'

export const useGetPaymentMethodVariantsVariants = (
  balanceOperationType: BalanceOperationsTypes
) => {
  const activePaymentTabValue = useSelector((state) =>
    selectActivePaymentTabValue(state, balanceOperationType)
  )

  return useSelector((state) =>
    selectPaymentMethodVariants(
      state,
      activePaymentTabValue,
      balanceOperationType
    )
  )
}
