import { useSelector } from 'react-redux'
import { selectConfig } from 'astra-core/containers/ConfigProvider'

import { BasketHistoryLegacy } from '../legacy/ui/BasketHistoryLegacy'

import { BasketHistory } from './BasketHistory'

export const BasketHistoryFacade = () => {
  const { IS_TRANSIT } = useSelector(selectConfig)

  if (IS_TRANSIT) {
    return <BasketHistoryLegacy />
  }

  return <BasketHistory />
}
