import React from 'react'
import { useSelector } from 'react-redux'
import { selectActiveLoyaltyLength } from 'astra-core/containers/AccountProvider'

import { ERoutes } from 'shared/types/routes'
import { IconTicketCouponNo } from 'shared/ui/Icon/General/IconTicketCouponNo'

import { StyledIconValue, StyledIconWrapper } from './BonusCouponButton.styled'

export const BonusCouponButton = () => {
  const activeLoyaltyLength = useSelector(selectActiveLoyaltyLength)

  return (
    <StyledIconWrapper to={ERoutes.MyBonuses}>
      <IconTicketCouponNo size={32} />
      <StyledIconValue>{activeLoyaltyLength}</StyledIconValue>
    </StyledIconWrapper>
  )
}
