import { FC, useMemo } from 'react'
import {
  ESportsCodes,
  selectOutcomeCategoryById,
  selectSport
} from 'astra-core/containers/CommonDataProvider'
import { useSelector } from 'react-redux'
import { getBasketOutcomeName, getEventTitle } from 'astra-core'
import { selectOutcomeType } from 'astra-core/containers/BasketProvider'
import { BetOutcomeWithPlayerName } from 'astra-core/utils/bets/types'

import { RootState } from 'shared/types/store'
import { useTextOverflow } from 'shared/lib/text-overflow/use-text-overflow'
import { Tooltip } from 'shared/ui/Tooltip'

import {
  StyledBasketHistoryOutcome,
  StyledBasketHistoryOutcomeCoefficient,
  StyledBasketHistoryOutcomeCoefficientValue,
  StyledBasketHistoryOutcomeCompetitors,
  StyledBasketHistoryOutcomeName,
  StyledBasketHistoryOutcomeTitle,
  StyledBasketHistoryOutcomeTitleText,
  StyledSportIcon
} from './BasketHistoryOutcome.styled'

export const BasketHistoryOutcome: FC<{
  outcome: BetOutcomeWithPlayerName
}> = ({ outcome }) => {
  const { event, coefficient } = outcome

  const [namesRef, isNamesOverflowing] = useTextOverflow<HTMLDivElement>()
  const [teamsRef, isTeamsOverflowing] = useTextOverflow<HTMLSpanElement>()
  const [outcomeRef, isOutcomeOverflowing] = useTextOverflow<HTMLSpanElement>()

  const outcomeTypeData = useSelector((state: RootState) =>
    selectOutcomeType(state, outcome.outcomeTypeId)
  )
  const outcomeCategory = useSelector((state: RootState) =>
    selectOutcomeCategoryById(state, outcome.outcomeCategoryId)
  )

  const outcomeType = useMemo(
    () =>
      getBasketOutcomeName({
        probWithOutcome: { ...outcome, outcomeTypeData: outcomeTypeData! },
        event: outcome.event!,
        isBetOutcome: true,
        outcomeCategory
      }),
    [outcome, outcomeCategory, outcomeTypeData]
  )

  const eventSport = useSelector((state) =>
    selectSport(state, event?.sportId || 0)
  )

  const eventTitle = getEventTitle(event)

  return (
    <StyledBasketHistoryOutcome>
      {event && (
        <div>
          <StyledBasketHistoryOutcomeTitle>
            <StyledSportIcon
              size="s"
              sport={eventSport?.code as ESportsCodes}
            />
            <Tooltip
              isCommon={!isNamesOverflowing}
              title={event.tournamentName}
            >
              <StyledBasketHistoryOutcomeTitleText ref={namesRef}>
                {event.tournamentName}
              </StyledBasketHistoryOutcomeTitleText>
            </Tooltip>
          </StyledBasketHistoryOutcomeTitle>
          <Tooltip isCommon={!isTeamsOverflowing} title={eventTitle}>
            <StyledBasketHistoryOutcomeCompetitors ref={teamsRef}>
              {eventTitle}
            </StyledBasketHistoryOutcomeCompetitors>
          </Tooltip>
        </div>
      )}

      <Tooltip isCommon={!isOutcomeOverflowing} title={outcomeType}>
        <StyledBasketHistoryOutcomeCoefficient>
          <StyledBasketHistoryOutcomeName ref={outcomeRef}>
            {outcomeType}
          </StyledBasketHistoryOutcomeName>

          <StyledBasketHistoryOutcomeCoefficientValue>
            {coefficient}
          </StyledBasketHistoryOutcomeCoefficientValue>
        </StyledBasketHistoryOutcomeCoefficient>
      </Tooltip>
    </StyledBasketHistoryOutcome>
  )
}
