import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'
import { IconPolygonBgLeftPart } from 'shared/ui/Icon/General/IconPolygonBgLeftPart'
import { Typography } from 'shared/ui/typography'

export const StyledInputWrapper = styled.div`
  margin-bottom: 4px;
  padding: 4px 16px;
  position: relative;
`
export const StyledTotalAmount = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0px ${theme.tokens.alias.space['space-s-12']}px
      ${theme.tokens.alias.space['space-xxs-6']}px;
    position: relative;

    &:after {
      background: ${theme.tokens.colors.border['border-hover']};
      bottom: 0px;
      content: '';
      height: 1px;
      left: -8px;
      position: absolute;
      width: calc(100% + 16px);
    }
  `}
`

export const StyledTotalAmountText = styled.div`
  ${typography['Body / 12 Regular']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary-2']};
  `}
`

export const StyledTotalAmountValue = styled.div`
  ${typography['Body / 14 SemiBold']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
  `}
`

export const StyledBasketExpressBottomWrapper = styled.div`
  bottom: 0px;
  height: fit-content;
  position: sticky;

  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-page']};
    box-shadow: inset 1px 0px
      ${({ theme }) => theme.tokens.colors.border['border-hover']};
  `}
`

export const StyledBasketExpressBottomContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
    padding: ${theme.tokens.alias.space['space-s-12']}px
      ${theme.tokens.alias.space['space-xs-8']}px
      ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const StyledTotalAmountValueWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
`

export const StyledMaxTagPart = styled(IconPolygonBgLeftPart)`
  height: 12px;
  width: max-content;
`

export const StyledMaxTagTypography = styled(Typography)`
  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-active-3']};
    line-height: 12px;
    margin-left: -0.2px;
    margin-right: -0.2px;
  `}
`

export const StyledMaxTag = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  & > span {
    width: 27px;
  }

  ${({ theme }) => css`
    & svg {
      color: ${theme.tokens.colors.surface['surface-active-3']};
    }
  `}
`
