import { useTranslation } from 'react-i18next'

import {
  ENotificationContainerIds,
  usePopUpNotification
} from 'shared/lib/Toastify'
import { TOASTIFY_CASHOUT_ID } from 'widgets/bets-history-legacy/components/Cashout/components/ToastifyCashoutNotification/WithdrawalFormMethods.types'
import { IconWarningTriangleFilled16 } from 'shared/ui/Icon/General/IconWarningTriangleFilled16'
import { IconCheckCircleFilled } from 'shared/ui/Icon/General/IconCheckCircleFilled'

export const useCashoutToast = () => {
  const { t } = useTranslation()

  const showSuccessToast = usePopUpNotification({
    content: {
      leftContent: <IconCheckCircleFilled colorToken="icon-positive" />,
      children: t('bet sold')
    },
    options: {
      closeButton: false,
      containerId: ENotificationContainerIds.SYSTEM,
      toastId: TOASTIFY_CASHOUT_ID
    }
  })

  const showErrorToast = usePopUpNotification({
    content: {
      leftContent: <IconWarningTriangleFilled16 colorToken="icon-warning" />,
      children: t('failed to sell bet')
    },
    options: {
      closeButton: false,
      containerId: ENotificationContainerIds.SYSTEM,
      toastId: TOASTIFY_CASHOUT_ID
    }
  })

  return { showSuccessToast, showErrorToast }
}
