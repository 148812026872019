import React from 'react'
import { sportCountersProviderActions } from 'astra-core/containers/SportCountersProvider'

import { getRouteParamAtIndex } from 'pages/lib/getRouteParamAtIndex'
import { lineTournamentsContainerActions } from 'pages/page-line-tournaments/LineTournamentsContainer/slice'
import { LineContainer } from 'pages/page-line-events/LineContainer'
import { getFetchLineReqData } from 'pages/page-line-events/LineContainer/utils'
import { LayoutPrimary } from 'layouts/primary'
import { LayoutEventsTable } from 'layouts/events-table'

export const PageLine = () => (
  <LayoutPrimary>
    <LayoutEventsTable>
      <LineContainer />
    </LayoutEventsTable>
  </LayoutPrimary>
)

export const pageLinePrefetch = ({ store, req }) => {
  store.dispatch(
    lineTournamentsContainerActions.fetchLine(
      getFetchLineReqData({ tournamentId: getRouteParamAtIndex(req.params, 2) })
    )
  )
  store.dispatch(sportCountersProviderActions.fetchLineSportCounters({}))
}

export default PageLine
