import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useCallback } from 'react'

import {
  searchTournamentContainerActions,
  selectSearchTournamentLayoutIsLoading
} from 'entities/search-tournament'
import { useEventsPoll } from 'entities/event/api'
import {
  getFetchLineReqData,
  LINE_POLLING_INTERVAL
} from 'pages/page-line-events/LineContainer/utils'
import { LineParams } from 'pages/page-line-events/LineContainer/types'

export const useSearchTournamentData = () => {
  const dispatch = useDispatch()
  const { tournamentId } = useParams<LineParams>()
  const isLayoutLoading = useSelector(selectSearchTournamentLayoutIsLoading)

  const fetch = useCallback(
    ({ tournamentId }) => {
      if (tournamentId) {
        dispatch(
          searchTournamentContainerActions.fetchSearchTournament(
            getFetchLineReqData({
              tournamentId
            })
          )
        )
      }
    },
    [dispatch]
  )

  useEventsPoll({
    isLoading: isLayoutLoading,
    tournamentId: +tournamentId,
    fetch,
    pollingInterval: LINE_POLLING_INTERVAL
  })
}
