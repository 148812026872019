import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  selectSettingsDataProp,
  selectUserSettingLayoutTheme
} from 'astra-core/containers/SettingsProvider'
import { useSetUserSetting } from 'astra-core/containers/SettingsProvider/utils'

import { RootState } from 'shared/types/store'
import { foldersThemesMap } from 'shared/lib/import/useDynamicImageImport'
import { Typography } from 'shared/ui/typography'

import {
  StyledSettingItem,
  StyledSettingItemContentRadio,
  StyledSettingItemIcon,
  StyledSettingItemLabel,
  StyledSettingItemTitle
} from '../../Settings.styled'

export const SettingLiveOrLine = () => {
  const [t] = useTranslation()
  const isDisplayLive = useSelector((state: RootState) =>
    selectSettingsDataProp(state, 'liveInitiallyDisplayed')
  )
  const { onSetUserSetting } = useSetUserSetting()

  const handleChangeSection = useCallback(
    (isLive: boolean) => () => {
      onSetUserSetting({
        liveInitiallyDisplayed: isLive
      })
    },
    [onSetUserSetting]
  )

  const themeType = useSelector(selectUserSettingLayoutTheme)

  return (
    <StyledSettingItem>
      <StyledSettingItemTitle>
        {t('active section by default')}
      </StyledSettingItemTitle>

      <StyledSettingItemContentRadio>
        <StyledSettingItemLabel
          checked={!isDisplayLive}
          onClick={handleChangeSection(false)}
        >
          <StyledSettingItemIcon
            $src={`/images/settings/${foldersThemesMap[themeType]}/setting_site_section_line.png`}
          />
          <Typography
            color={!isDisplayLive ? 'text-primary' : 'text-secondary'}
            font="Body / 14 SemiBold"
          >
            {t('line')}
          </Typography>
        </StyledSettingItemLabel>

        <StyledSettingItemLabel
          checked={isDisplayLive}
          onClick={handleChangeSection(true)}
        >
          <StyledSettingItemIcon
            $src={`/images/settings/${foldersThemesMap[themeType]}/setting_site_section_live.png`}
          />
          <Typography
            color={isDisplayLive ? 'text-primary' : 'text-secondary'}
            font="Body / 14 SemiBold"
          >
            {t('live')}
          </Typography>
        </StyledSettingItemLabel>
      </StyledSettingItemContentRadio>
    </StyledSettingItem>
  )
}
