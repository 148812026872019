import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { selectSports } from 'astra-core/containers/CommonDataProvider'
import { useGroupedTournaments } from 'astra-core/hooks/useEvents'
import {
  getSelectedPeriodOptionValue,
  useLineFilterPeriodTimesComparison
} from 'astra-core'

import { LineParams } from 'pages/page-line-events/LineContainer/types'
import {
  getFetchLineReqData,
  LINE_POLLING_INTERVAL
} from 'pages/page-line-events/LineContainer/utils'
import { useEventsPoll } from 'entities/event/api'
import { RootState } from 'shared/types/store'
import {
  getScheduleTime,
  OPTIONS_SELECT_PERIOD
} from 'widgets/events-page-header/ui/select-filter-period/constants'

import {
  getFilterPeriod,
  selectEventsLayoutIsLoading,
  selectFilterIsTop,
  selectFilterSportId,
  selectLayoutIsLoading,
  selectLineTournaments
} from './selectors'
import {
  getFetchLineTournamentsReqData,
  LINE_TOURNAMENTS_POLLING_INTERVAL
} from './utils'
import {
  LineTournamentsParams,
  UseTournamentsGroupedBySubSportProps
} from './types'
import { lineTournamentsContainerActions } from './slice'

export const useLineContainerData = () => {
  const dispatch = useDispatch()
  const { tournamentId } = useParams<LineParams>()
  const isLayoutLoading = useSelector(selectEventsLayoutIsLoading)
  const top = useSelector(selectFilterIsTop)
  const filterPeriod = useSelector(getFilterPeriod)
  const periodOptions = OPTIONS_SELECT_PERIOD()

  const selectedPeriodValue = useMemo(
    () => getSelectedPeriodOptionValue(periodOptions, filterPeriod),
    [filterPeriod, periodOptions]
  )

  const setFilterPeriod = useCallback(() => {
    if (selectedPeriodValue) {
      dispatch(
        lineTournamentsContainerActions.setFilterPeriod({
          ...getScheduleTime(selectedPeriodValue)
        })
      )
    }
  }, [dispatch, selectedPeriodValue])

  const fetchLine = useCallback(
    ({ tournamentId, tagIds }) => {
      if (tournamentId) {
        dispatch(
          lineTournamentsContainerActions.fetchLine(
            getFetchLineReqData({
              tournamentId,
              tagIds
            })
          )
        )
      }
    },
    [dispatch]
  )

  /* Update Line if the current time does not match the filter */
  useLineFilterPeriodTimesComparison({
    filterPeriod,
    callback: setFilterPeriod,
    selectedPeriodValue
  })

  useEventsPoll({
    isLoading: isLayoutLoading,
    tournamentId: +tournamentId,
    fetch: fetchLine,
    top,
    pollingInterval: LINE_POLLING_INTERVAL
  })
}

export const useLineTournamentsContainerData = () => {
  const dispatch = useDispatch()
  const { sportId } = useParams<LineTournamentsParams>()
  const top = useSelector(selectFilterIsTop)
  const isLayoutLoading = useSelector(selectLayoutIsLoading)
  const filterPeriod = useSelector(getFilterPeriod)
  const periodOptions = OPTIONS_SELECT_PERIOD()

  const selectedPeriodValue = useMemo(
    () => getSelectedPeriodOptionValue(periodOptions, filterPeriod),
    [filterPeriod, periodOptions]
  )

  const setFilterPeriod = useCallback(() => {
    if (selectedPeriodValue) {
      dispatch(
        lineTournamentsContainerActions.setFilterPeriod({
          ...getScheduleTime(selectedPeriodValue)
        })
      )
    }
  }, [dispatch, selectedPeriodValue])

  const fetchLineTournaments = useCallback(
    ({ sportId }) => {
      if (sportId) {
        dispatch(
          lineTournamentsContainerActions.fetchLineTournaments(
            getFetchLineTournamentsReqData({ sportId })
          )
        )
      }
    },
    [dispatch]
  )

  /* Update Line if the current time does not match the filter */
  useLineFilterPeriodTimesComparison({
    filterPeriod,
    callback: setFilterPeriod,
    selectedPeriodValue
  })

  useEventsPoll({
    isLoading: isLayoutLoading,
    sportId: +sportId,
    fetch: fetchLineTournaments,
    top,
    pollingInterval: LINE_TOURNAMENTS_POLLING_INTERVAL
  })
}

export const useTournamentsGroupedBySubSport = ({
  sportId
}: UseTournamentsGroupedBySubSportProps) => {
  const allTournaments = useSelector(
    (state: RootState) =>
      selectLineTournaments(state, { sportId: `${sportId}` }) // TODO: selector waits sportId as string
  )

  const sportIdFilter = useSelector(selectFilterSportId)
  const sports = useSelector(selectSports)
  const allGroupedTournaments = useGroupedTournaments(allTournaments, sports)

  const isGenericSport = useMemo(() => {
    return !!(
      allGroupedTournaments.length &&
      allGroupedTournaments.every((t) => t.genericSportId === sportId)
    )
  }, [allGroupedTournaments, sportId])

  let groupedTournaments = allGroupedTournaments
  if (isGenericSport && sportIdFilter) {
    groupedTournaments = groupedTournaments.filter(
      ({ id: sportId }) => sportId === sportIdFilter
    )
  }

  return { isGenericSport, allGroupedTournaments, groupedTournaments }
}
