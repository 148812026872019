import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { selectIsShownBroadcastToastifyNotification } from 'entities/match-broadcast/model'
import {
  usePopUpNotification,
  ENotificationContainerIds
} from 'shared/lib/Toastify'
import { TOASTIFY_BROADCAST_ID } from 'widgets/broadcast-toastify-notification/constants'
import { BroadcastToastifyNotification } from 'widgets/broadcast-toastify-notification'

export const useToastifyBroadcastModal = () => {
  const isShownBroadcastToastifyNotification = useSelector(
    selectIsShownBroadcastToastifyNotification
  )

  const setToastifyNotify = usePopUpNotification({
    content: {
      children: (props) => <BroadcastToastifyNotification {...props} />
    },
    options: {
      closeButton: false,
      containerId: ENotificationContainerIds.SYSTEM,
      autoClose: false,
      toastId: TOASTIFY_BROADCAST_ID
    }
  })

  useEffect(() => {
    if (isShownBroadcastToastifyNotification) {
      setToastifyNotify()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShownBroadcastToastifyNotification])

  return null
}
