import styled, { css } from 'styled-components'

import { combineMedias } from 'shared/lib/styled'
import { RIGHT_CONTAINER_WIDTH } from 'entities/basket/lib'

import { IStyledBasketAsideWrapper } from './basket-aside.types'

const RIGHT_CONTAINER_TOP_POSITION = 48

export const StyledBasketAsideWrapper = styled.div<IStyledBasketAsideWrapper>`
  display: flex;
  flex-direction: column;
  grid-area: right-container;
  height: calc(100vh - ${RIGHT_CONTAINER_TOP_POSITION}px);
  justify-content: center;
  overflow-y: auto;
  position: relative;
  transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
  width: 300px;

  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-page']};
    box-shadow: inset 1px 0 ${theme.tokens.colors.border['border-hover']};
  `}

  ${combineMedias(['tabletOrMobile', 'small'])} {
    display: flex;
    position: absolute;
    right: 0;
    top: ${RIGHT_CONTAINER_TOP_POSITION}px;
    transform: ${({ isShowBasketOnSmallWindow }) =>
      !isShowBasketOnSmallWindow
        ? `translateX(${RIGHT_CONTAINER_WIDTH}px)`
        : 'translateX(0)'};
    z-index: 1000;
  }
`

export const StyledBasketWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: unset;
  position: relative;
`

export const StyledLiveMatchBroadcastWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  margin-top: 16px;
  padding-left: 8px;
  padding-right: 8px;
  position: relative;
`

export const StyledDraggedDestinationElement = styled.div<{
  destinationIndex: number
}>`
  position: absolute;

  ${({ theme: { tokens } }) => css`
    background-color: rgba(158, 204, 250, 0.15);
    border: solid 1px #258cf4;
    border-radius: ${tokens.alias['border-radius']['border-radius-m-8']}px;
  `}
`
