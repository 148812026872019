import {
  selectBasketOutcomesLength,
  selectExpressRate
} from 'astra-core/containers/BasketProvider'
import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { selectAccountUser } from 'astra-core/containers/AccountProvider'

import { IconPolygonBgLeftPart } from 'shared/ui/Icon/General/IconPolygonBgLeftPart'
import { separateNumber } from 'shared/lib/format/separate-number'
import { rightBasketContainerActions } from 'entities/basket/model/slice'

import {
  StyledBasketButtonSmallEventCounter,
  StyledBasketButtonSmallEventRect,
  StyledBasketButtonSmallWindowText,
  StyledBasketButtonSmallWindowWrapper,
  StyledDivideLine
} from './BasketButtonSmallWindow.styled'

export const BasketButtonSmallWindow: FC<{
  isSmall?: boolean
  className?: string
}> = ({ isSmall, className }) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()

  const expressRate = useSelector(selectExpressRate)
  const outcomesLength = useSelector(selectBasketOutcomesLength)

  const user = useSelector(selectAccountUser)

  const toggleShowBasket = useCallback(() => {
    dispatch(
      rightBasketContainerActions.toggleBasketOnSmallWindow({ isShow: true })
    )
  }, [dispatch])

  return user ? (
    <StyledBasketButtonSmallWindowWrapper
      className={className}
      isSmall={isSmall}
      onClick={toggleShowBasket}
    >
      <StyledBasketButtonSmallWindowText>
        {t('just coupon')}
      </StyledBasketButtonSmallWindowText>

      {!!outcomesLength && (
        <StyledBasketButtonSmallEventCounter>
          <IconPolygonBgLeftPart />

          <StyledBasketButtonSmallEventRect>
            <div>{outcomesLength}</div>
            <StyledDivideLine />
            <div>{separateNumber(+expressRate.toFixed(2))}</div>
          </StyledBasketButtonSmallEventRect>

          <IconPolygonBgLeftPart twist={180} />
        </StyledBasketButtonSmallEventCounter>
      )}
    </StyledBasketButtonSmallWindowWrapper>
  ) : null
}
