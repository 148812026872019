import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrencyMaxPayout } from 'astra-core/containers/CommonDataProvider'
import {
  EBetTypes,
  selectBasketAppliedCoupon,
  selectBasketBetTypeTab,
  selectBonusOnExpress
} from 'astra-core/containers/BasketProvider'

const useMaxSum = (winSum: number, inputValue: number | string) => {
  const maxPayout = useSelector(selectCurrencyMaxPayout)
  const appliedCoupon = useSelector(selectBasketAppliedCoupon)
  const bonus = useSelector(selectBonusOnExpress)
  const betTypeTab = useSelector(selectBasketBetTypeTab)

  const maxSum = useMemo(() => {
    const bonusSum = winSum * (bonus - 1)
    const totalWinSum =
      betTypeTab === EBetTypes.Express ? winSum + bonusSum : winSum

    const sum = totalWinSum > maxPayout ? maxPayout : totalWinSum
    const newValue = appliedCoupon ? inputValue : sum.toFixed(2)
    return newValue
  }, [winSum, maxPayout, appliedCoupon, inputValue, bonus, betTypeTab])

  const showMaxTag = useMemo(() => +maxSum === maxPayout, [maxSum, maxPayout])

  return { maxSum, showMaxTag }
}

export default useMaxSum
