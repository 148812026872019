import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  EBasketHeaderTypes,
  basketProviderActions,
  selectBasketHeaderTab,
  selectBasketOutcomesLength
} from 'astra-core/containers/BasketProvider'

import { IconBasketClose } from 'shared/ui/Icon/General/IconBasketClose'
import { IconPolygonBgLeftPart } from 'shared/ui/Icon/General/IconPolygonBgLeftPart'
import { selectIsShowBasketOnSmallWindow } from 'entities/basket/model/selectors'
import { rightBasketContainerActions } from 'entities/basket/model/slice'
import { IconState } from 'shared/ui/buttons/icon-state'
import { IconCouponSetting } from 'shared/ui/Icon/General/IconCouponSetting'
import { layoutContainerActions } from 'containers/LayoutContainer/slice'
import { selectIsDisplayModalCouponSetting } from 'containers/LayoutContainer/selectors'

import {
  BasketTitleItemCounter,
  BasketTitleItemNum,
  BasketTitleItemTitle,
  BasketTitleTabs,
  StyledBasketToolbar,
  StyledBasketTitleItemWrapper,
  StyledBasketTitleWrapper,
  StyledCloseButtonWrapper
} from './BasketTitle.styled'
import {
  BasketTitleItemProps,
  BasketTitleItemsLocale
} from './BasketTitle.types'

const basketTitleItemsLocale: BasketTitleItemsLocale[] = [
  { name: EBasketHeaderTypes.Coupon, locale: 'just coupon' },
  { name: EBasketHeaderTypes.History, locale: 'history' }
]

export const BasketTitle: FC = () => {
  const dispatch = useDispatch()
  const isShowBasketOnSmallWindow = useSelector(selectIsShowBasketOnSmallWindow)
  const isShowModalCouponSetting = useSelector(
    selectIsDisplayModalCouponSetting
  )

  const toggleShowBasket = useCallback(() => {
    dispatch(
      rightBasketContainerActions.toggleBasketOnSmallWindow({ isShow: false })
    )
  }, [dispatch])

  const handleCouponSettingToggle = useCallback(() => {
    dispatch(
      layoutContainerActions.setIsDisplayModalCouponSetting(
        !isShowModalCouponSetting
      )
    )
  }, [dispatch, isShowModalCouponSetting])

  return (
    <StyledBasketTitleWrapper>
      <BasketTitleTabs>
        {basketTitleItemsLocale.map(({ name, locale }) => (
          <BasketTitleItem key={name} locale={locale} name={name} />
        ))}
      </BasketTitleTabs>

      <StyledBasketToolbar>
        <IconState
          icon={IconCouponSetting}
          isActive={isShowModalCouponSetting}
          size="m"
          onClick={handleCouponSettingToggle}
        />

        {isShowBasketOnSmallWindow && (
          <StyledCloseButtonWrapper onClick={toggleShowBasket}>
            <IconBasketClose size={10} />
          </StyledCloseButtonWrapper>
        )}
      </StyledBasketToolbar>
    </StyledBasketTitleWrapper>
  )
}

export const BasketTitleItem: FC<BasketTitleItemProps> = ({ name, locale }) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()

  const headerTab = useSelector(selectBasketHeaderTab)
  const outcomesLength = useSelector(selectBasketOutcomesLength)

  const handleTabChange = useCallback(() => {
    dispatch(basketProviderActions.handleTabChange({ headerTab: name }))
  }, [dispatch, name])

  return (
    <StyledBasketTitleItemWrapper
      active={name === headerTab}
      onClick={handleTabChange}
    >
      <BasketTitleItemTitle active={name === headerTab}>
        {t(locale)}
      </BasketTitleItemTitle>
      {name === EBasketHeaderTypes.Coupon && (
        <BasketTitleItemCounter>
          <IconPolygonBgLeftPart />
          <BasketTitleItemNum>{outcomesLength}</BasketTitleItemNum>
          <IconPolygonBgLeftPart twist={180} />
        </BasketTitleItemCounter>
      )}
    </StyledBasketTitleItemWrapper>
  )
}
