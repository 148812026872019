import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  EBetTypes,
  getBasketLoading,
  selectBasketBetTypeTab,
  selectBasketOutcomes
} from 'astra-core/containers/BasketProvider'
import {
  selectAccountUser,
  selectFetchUserRequest
} from 'astra-core/containers/AccountProvider'

import { BasketNav } from 'features/basket/basket-header'
import { BasketEmptyScreen } from 'entities/basket'
import { Skeleton } from 'shared/ui/skeleton'
import { useCompleted } from 'hooks'
import {
  rightBasketContainerActions,
  selectIsBasketLoaded
} from 'entities/basket/model'
import { selectBroadcastsEventIdsLength } from 'entities/match-broadcast/model'

import { BasketBodyLoader } from './BasketBody.loader'
import { Ordinar } from './Ordinar/Ordinar'
import { Express } from './Express/Express'
import { System } from './System/System'
import { StyledBasketBodyWrapper } from './BasketBody.styled'
import { BetTypes } from './BasketBody.types'

const betTypes = ({ outcomes }): BetTypes => ({
  [EBetTypes.Ordinar]: <Ordinar outcomes={outcomes} />,
  [EBetTypes.Express]: <Express outcomes={outcomes} />,
  [EBetTypes.System]: <System outcomes={outcomes} />
})

export const BasketBody: FC = () => {
  const dispatch = useDispatch()
  const outcomes = useSelector(selectBasketOutcomes)
  const betTypeTab = useSelector(selectBasketBetTypeTab)
  const loading = useSelector(getBasketLoading)
  const isCompleted = useCompleted(!!loading)
  const user = useSelector(selectAccountUser)
  const userFetch = useSelector(selectFetchUserRequest)
  const isBasketLoaded = useSelector(selectIsBasketLoaded)
  const isUserLoaded = useCompleted(!!userFetch.loading)
  const broadcastsEventIdsLength = useSelector(selectBroadcastsEventIdsLength)

  useEffect(() => {
    if ((isUserLoaded && !user) || isCompleted) {
      dispatch(rightBasketContainerActions.setBasketLoaded(true))
    }
  }, [dispatch, isCompleted, isUserLoaded, user])

  return (
    <Skeleton isLoading={!isBasketLoaded} styledFallback={<BasketBodyLoader />}>
      {outcomes.length ? (
        <StyledBasketBodyWrapper
          broadcastsEventIdsLength={broadcastsEventIdsLength}
        >
          <BasketNav />

          {betTypes({ outcomes })[betTypeTab]}
        </StyledBasketBodyWrapper>
      ) : (
        <BasketEmptyScreen />
      )}
    </Skeleton>
  )
}
