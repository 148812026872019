import { FC, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectSettingsDataProp } from 'astra-core/containers/SettingsProvider'
import { selectAccountBalance } from 'astra-core/containers/AccountProvider'
import { useGetCurrencyIcon } from 'astra-core/hooks'
import { useTranslation } from 'react-i18next'
import useMeasure from 'react-use-measure'

import { useRouteRedirect } from 'hooks/useRouteRedirect'
import { RootState } from 'shared/types/store'
import { ERoutes } from 'shared/types/routes'
import { Button } from 'shared/ui/Button'
import { formatBalance } from 'shared/lib/format/format-balance'

import {
  StyledBalanceContainer,
  StyledBalanceInner,
  StyledBalanceValue,
  StyledCurrencyIcon,
  StyledHiddenMark,
  StyledUserAccountBalance,
  StyledValueContainer
} from './user-account-balance.styled'

export const UserAccountBalance: FC = ({ ...props }) => {
  const { t } = useTranslation()
  const [ref, { width }] = useMeasure()

  const balance = useSelector(selectAccountBalance)
  const isBalanceHidden = useSelector((state: RootState) =>
    selectSettingsDataProp(state, 'balanceHidden')
  )
  const currencyIcon = useGetCurrencyIcon()
  const routeRedirect = useRouteRedirect()

  const handleRedirectList = useCallback(
    () => routeRedirect({ route: ERoutes.ProfileBalance }),
    [routeRedirect]
  )

  const formattedBalance = useMemo(() => formatBalance(balance), [balance])

  return (
    <StyledUserAccountBalance {...props} onClick={handleRedirectList}>
      <StyledBalanceInner>
        <StyledValueContainer active={isBalanceHidden} totalWidth={width}>
          <StyledHiddenMark>***</StyledHiddenMark>
          <StyledBalanceValue ref={ref}>{formattedBalance}</StyledBalanceValue>
        </StyledValueContainer>

        <StyledBalanceContainer>
          <StyledCurrencyIcon>{currencyIcon}</StyledCurrencyIcon>

          <Button view="primary-medium">{t('top up')}</Button>
        </StyledBalanceContainer>
      </StyledBalanceInner>
    </StyledUserAccountBalance>
  )
}
