import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Button } from 'shared/ui/Button'
import { IconTop } from 'shared/ui/Icon/General/IconTop'
import { lineTournamentsContainerActions } from 'pages/page-line-tournaments/LineTournamentsContainer/slice'
import {
  selectFilterIsTop,
  selectLineEvents,
  selectLineFetchItems,
  selectEventsLayoutIsLoading
} from 'pages/page-line-tournaments/LineTournamentsContainer/selectors'
import { useIsSmall, useIsTabletOrMobile } from 'shared/lib/styled'
import { BasketButtonSmallWindow } from 'features/basket/open-basket-small-window'
import { RootState } from 'shared/types/store'
import { LineParams } from 'pages/page-line-events/LineContainer/types'
import { LineSearch } from 'features/line-search'
import { useIsEventListHasTop } from 'entities/event'

import { SelectFilterPeriod } from '../select-filter-period'

import {
  StyledButtonsWrapper,
  StyledSearchDivider,
  StyledSearchWrapper
} from './line-content.styled'

export const LineContent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isTop = useSelector(selectFilterIsTop)
  const { tournamentId } = useParams<LineParams>()

  const layoutIsLoading = useSelector(selectEventsLayoutIsLoading)
  const lineFetchItems = useSelector(selectLineFetchItems)

  const lineEvents = useSelector((state: RootState) =>
    selectLineEvents(state, { tournamentId })
  )
  const { isEventListHasTop } = useIsEventListHasTop(lineEvents)

  const toggleFilterTop = useCallback(() => {
    dispatch(lineTournamentsContainerActions.toggleFilterIsTop())
  }, [dispatch])

  useEffect(() => {
    if (
      isTop &&
      !isEventListHasTop &&
      !layoutIsLoading &&
      !lineFetchItems.loading
    ) {
      toggleFilterTop()
    }
  }, [
    isTop,
    isEventListHasTop,
    toggleFilterTop,
    dispatch,
    layoutIsLoading,
    lineFetchItems.loading
  ])

  const isTabletOrMobile = useIsTabletOrMobile()
  const isSmall = useIsSmall()

  return (
    <StyledButtonsWrapper>
      <StyledSearchWrapper>
        <LineSearch placeholder={t('search in line')} />
      </StyledSearchWrapper>
      <StyledSearchDivider />
      <SelectFilterPeriod />

      <Button
        disabled={!isEventListHasTop || layoutIsLoading}
        icon={IconTop}
        isActive={isTop}
        view="filter"
        onClick={toggleFilterTop}
      >
        {t('only top')}
      </Button>

      {(isTabletOrMobile || isSmall) && <BasketButtonSmallWindow isSmall />}
    </StyledButtonsWrapper>
  )
}
