import { EntityTag, TagFilterOperation } from 'betweb-openapi-axios'

import { LineFilterPeriod } from 'pages/page-line-events/LineContainer/types'

interface GetFetchLineReqDataParams {
  tournamentId: string | number
  filterPeriod?: LineFilterPeriod
  tagIds?: EntityTag['id'][]
}

export const getFetchLineReqData = ({
  tournamentId,
  filterPeriod,
  tagIds
}: GetFetchLineReqDataParams) => ({
  tagIds,
  live: false,
  tournamentId: +tournamentId,
  tagFilterOperation: TagFilterOperation.Loose,
  ...filterPeriod
})

export const LINE_POLLING_INTERVAL = 5000
