import styled, { css } from 'styled-components'

export const StyledNavSpace = styled.div`
  ${({ theme }) => css`
    padding: ${theme.tokens.alias.space['space-m-16']}px
      ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`
export const StyledNavWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const StyledBetsWrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-default']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
    display: flex;
    outline: 1px solid ${theme.tokens.colors.border['border-primary']};
    padding: ${theme.tokens.alias.space['space-xxxxs-2']}px;
  `}
`
