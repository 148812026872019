import React, { FC } from 'react'
import {
  EFeatureFlags,
  selectConfig,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'
import { useSelector } from 'react-redux'
import { selectAccountUser } from 'astra-core/containers/AccountProvider'

import { GlobalSearchOverlay } from 'widgets/global-search'

import { useFetchSportsCounters } from './hooks/useFetchSportsCounters'
import { useCurrentTabMenu } from './hooks/useCurrentTabMenu'
import { FavoriteTab, SportsMenuList, SportsMenuTabs } from './components'
import { SportsMenuProps } from './sports-menu.types'
import {
  StyledScrollContainer,
  StyledSportsMenu,
  StyledTabsWrapper,
  StyledWrapper
} from './sports-menu.styled'

export const SportsMenu: FC<SportsMenuProps> = () => {
  const [currentTabMenu, setCurrentTabMenu] = useCurrentTabMenu()
  const SEARCH_ENABLED = useFeatureFlag('SEARCH_ENABLED' as EFeatureFlags)

  useFetchSportsCounters(currentTabMenu)

  const user = useSelector(selectAccountUser)
  const { IS_IFRAME } = useSelector(selectConfig)

  return (
    <StyledSportsMenu withSearch={SEARCH_ENABLED}>
      {SEARCH_ENABLED && <GlobalSearchOverlay />}
      <StyledWrapper>
        <StyledScrollContainer showTab={IS_IFRAME && !!user}>
          <StyledTabsWrapper>
            <SportsMenuTabs
              currentTabMenu={currentTabMenu}
              setCurrentTabMenu={setCurrentTabMenu}
            />
            {IS_IFRAME && !!user && <FavoriteTab />}
          </StyledTabsWrapper>

          <SportsMenuList currentTabMenu={currentTabMenu} />
        </StyledScrollContainer>
      </StyledWrapper>
    </StyledSportsMenu>
  )
}
