import { FC } from 'react'

import { Tooltip } from 'shared/ui/Tooltip'
import { Typography } from 'shared/ui/typography'

import { CompetitorsInfoProps } from './competitors-info.types'
import * as S from './competitors-info.styled'

export const CompetitorsInfo: FC<CompetitorsInfoProps> = ({
  linkToEventPage,
  teams
}) => {
  const [homeTeam, awayTeam] = teams

  return (
    <S.StyledCompetitorsCell>
      <S.CompetitorsWrapper to={linkToEventPage}>
        {!!homeTeam && (
          <Tooltip
            isCommon={homeTeam.isCommonTooltip}
            placement="left"
            title={homeTeam.name}
          >
            <S.CompetitorName>
              {homeTeam.CustomTitleName || (
                <Typography
                  font="Body / 12 SemiBold"
                  lineClamp={1}
                  ref={homeTeam.titleNameRef}
                >
                  {homeTeam.name}
                </Typography>
              )}

              {homeTeam.isDisplayCurrentServerPoint && <S.CurrentServerPoint />}
            </S.CompetitorName>
          </Tooltip>
        )}

        {!!awayTeam && (
          <Tooltip
            isCommon={awayTeam.isCommonTooltip}
            placement="left"
            title={awayTeam.name}
          >
            <S.CompetitorName>
              {awayTeam.CustomTitleName || (
                <Typography
                  font="Body / 12 SemiBold"
                  lineClamp={1}
                  ref={awayTeam.titleNameRef}
                >
                  {awayTeam.name}
                </Typography>
              )}
              {awayTeam.isDisplayCurrentServerPoint && <S.CurrentServerPoint />}
            </S.CompetitorName>
          </Tooltip>
        )}
      </S.CompetitorsWrapper>
    </S.StyledCompetitorsCell>
  )
}
