import React, { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { selectInputSearchLine } from 'astra-core/containers/SearchProvider'

import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import { RootState } from 'shared/types/store'
import {
  selectEventsLayoutIsLoading,
  selectLineEvents,
  selectLineFetchItems
} from 'pages/page-line-tournaments/LineTournamentsContainer/selectors'
import { LineParams } from 'pages/page-line-events/LineContainer/types'
import { lineTournamentsContainerActions } from 'pages/page-line-tournaments/LineTournamentsContainer/slice'
import {
  EventsPageHeader,
  EventsPageHeaderTypes
} from 'widgets/events-page-header'
import { EmptyPageFilter } from 'features/empty-page-filter'
import { StyledLineEvents } from 'pages/page-line-events/ui/line.styled'
import { ClearSearchResults } from 'features/clear-search-results'
import { EventsTable } from 'widgets/events-table'
import { getBackLinkBuilder } from 'shared/lib/events'
import { ESportsMenuTabTypes } from 'widgets/sports-menu/sports-menu.types'

export const LineEvents: FC = () => {
  const dispatch = useDispatch()
  const { tournamentId } = useParams<LineParams>()
  const searchText = useSelector(selectInputSearchLine)
  const events = useSelector((state: RootState) =>
    selectLineEvents(state, { tournamentId })
  )

  const isLayoutEventsLoading = useSelector(selectEventsLayoutIsLoading)
  const eventsLineFetchItems = useSelector(selectLineFetchItems)

  const resetLineFilter = useCallback(() => {
    dispatch(lineTournamentsContainerActions.resetFilters())
  }, [dispatch])

  return (
    <StyledLineEvents>
      <EventsPageHeader type={EventsPageHeaderTypes.Line} />

      {isLayoutEventsLoading ? (
        <LoaderSpinner />
      ) : events.length ? (
        <EventsTable
          headerProps={{
            backLinkBuilder: (tournament, genericSportId) =>
              getBackLinkBuilder(
                ESportsMenuTabTypes.LINE,
                tournament,
                genericSportId
              )
          }}
          events={events}
        />
      ) : searchText ? (
        <ClearSearchResults />
      ) : eventsLineFetchItems.loading && !events.length ? (
        <LoaderSpinner />
      ) : (
        <EmptyPageFilter onButtonClickResetFilter={resetLineFilter} />
      )}
    </StyledLineEvents>
  )
}
