import React, { memo, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { OptionType } from 'astra-core/utils/line/types'

import {
  INITIAL_FILTER_PERIOD_ID,
  actions as calendarEventsContainerActions
} from 'containers/CalendarEventsContainer/slice'
import { ESelectInputsNames } from 'containers/CalendarEventsContainer/types'
import {
  selectFilterStream,
  selectFilterSelectInputPeriod,
  selectCalendarEventsIsEmpty
} from 'containers/CalendarEventsContainer/selectors'
import { IconStreamFilled } from 'shared/ui/Icon/General/IconStreamFilled'
import { IconTimeSelect } from 'shared/ui/Icon/General/icon-time-select'

import { OPTIONS_SELECT_PERIOD } from './constants'
import {
  StyledFilters,
  StyledFilterStream,
  StyledSelectPeriod
} from './Filters.styled'

export const getSelectedPeriodOptionValue = (
  selectPeriodOptions: OptionType[],
  filterPeriod
) => selectPeriodOptions.find(({ id }) => id === filterPeriod.id)

export const Filters = memo(() => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const filterSelectInputPeriod = useSelector(selectFilterSelectInputPeriod)
  const periodOptionsFilter = OPTIONS_SELECT_PERIOD()
  const isCalendarEventsEmpty = useSelector(selectCalendarEventsIsEmpty)

  const selectedPeriodValue = useMemo(
    () =>
      getSelectedPeriodOptionValue(
        periodOptionsFilter,
        filterSelectInputPeriod
      ),
    [periodOptionsFilter, filterSelectInputPeriod]
  )

  const handleSelect = useCallback(
    (selectName: ESelectInputsNames) => (data) => {
      if (selectName === ESelectInputsNames.Period) {
        dispatch(calendarEventsContainerActions.setSelectPeriod(data))
      } else {
        dispatch(calendarEventsContainerActions.setSelectOrder(data))
      }
    },
    [dispatch]
  )

  if (!selectedPeriodValue) {
    return null
  }

  const isDisabled =
    isCalendarEventsEmpty && selectedPeriodValue.id === INITIAL_FILTER_PERIOD_ID

  const leftIconColorToken = isDisabled ? 'icon-opacity' : 'icon-secondary-2'

  return (
    <StyledFilters>
      {/* <FilterStream /> */}

      {/* <StyledSelectOrder
        value={defaultValuesSelect.order}
        onChange={handleSelect(ESelectInputsNames.Order)}
        options={optionsSelect.order}
        view={ESelectView.Mini}
      /> */}

      <StyledSelectPeriod
        isDisabled={isDisabled}
        leftIcon={<IconTimeSelect colorToken={leftIconColorToken} />}
        options={periodOptionsFilter}
        placeholder={t('select')}
        value={selectedPeriodValue}
        onChange={handleSelect(ESelectInputsNames.Period)}
      />
    </StyledFilters>
  )
})

export const FilterStream = () => {
  const dispatch = useDispatch()
  const isActive = useSelector(selectFilterStream)

  const handleClickStream = useCallback(
    () => dispatch(calendarEventsContainerActions.toggleFilterStream()),
    [dispatch]
  )
  return (
    <StyledFilterStream $isActive={isActive} onClick={handleClickStream}>
      <IconStreamFilled />
    </StyledFilterStream>
  )
}
