import { EBetTypes } from 'astra-core/containers/BasketProvider'
import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const StyledBasketBonusCouponsWrapper = styled.div<{
  betTypeTab: EBetTypes
}>`
  display: flex;
  flex-direction: column;

  & > div {
    ${({ theme }) => css`
      border-bottom: 1px solid ${theme.tokens.colors.divider['divider-primary']};
    `}

    &:last-child {
      border-bottom: none;
    }
  }
`

export const StyledBasketBonusCouponWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
  padding: 8px;
`

export const StyledBonusText = styled.div`
  ${typography['Body / 12 Medium']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary-2']};
  `}
  flex-grow: 1;
`

export const StyledAlertWrapper = styled.div`
  padding: 4px 8px 8px;
`
