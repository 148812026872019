import { useMemo } from 'react'
import { LayoutTheme } from 'betweb-openapi-axios'
import { useSelector } from 'react-redux'

import {
  ADAPTIVE_TOKENS,
  ALIAS_TOKENS,
  BUTTON_TOKENS,
  DARK_THEME_SHADOW_TOKENS,
  DARK_THEME_TOKENS,
  LIGHT_THEME_SHADOW_TOKENS,
  LIGHT_THEME_TOKENS,
  ThemeContext
} from 'shared/lib/theme'
import { selectTheme } from 'shared/lib/theme/select-theme'

import { FONTS_STYLES } from './fonts'
import { FIXED_COLORS } from './colors-fixed'
import { LIGHT_THEME_COLORS } from './colors-light'
import { DARK_THEME_COLORS } from './colors-dark'

export const useSelectDefaultTheme = () => {
  const layoutTheme = useSelector(selectTheme)

  const defaultTheme: ThemeContext = useMemo(
    () => ({
      colors: {
        ...(layoutTheme === LayoutTheme.Light
          ? LIGHT_THEME_COLORS
          : DARK_THEME_COLORS),
        fixed: FIXED_COLORS
      },
      tokens: {
        colors:
          layoutTheme === LayoutTheme.Light
            ? LIGHT_THEME_TOKENS
            : DARK_THEME_TOKENS,
        adaptive: ADAPTIVE_TOKENS,
        alias: ALIAS_TOKENS,
        button: BUTTON_TOKENS,
        shadows:
          layoutTheme === LayoutTheme.Light
            ? LIGHT_THEME_SHADOW_TOKENS
            : DARK_THEME_SHADOW_TOKENS
      },
      fonts: FONTS_STYLES,
      typeLayoutTheme: layoutTheme,
      pageBackground:
        layoutTheme === LayoutTheme.Light
          ? LIGHT_THEME_TOKENS.surface['surface-page']
          : 'linear-gradient(174deg, #232A34 4.26%, #000000 50.95%, #252B37 95.74%)',
      metaThemeColor:
        layoutTheme === LayoutTheme.Light
          ? LIGHT_THEME_TOKENS.surface['surface-page']
          : '#232A34'
    }),
    [layoutTheme]
  )

  return [defaultTheme]
}
