import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { selectAccountId } from 'astra-core/containers/AccountProvider'
import { selectConfig } from 'astra-core/containers/ConfigProvider'

import { layoutContainerActions } from 'containers/LayoutContainer/slice'
import {
  StyledSettingsList,
  StyledSettingsListItems,
  StyledSettingsListTitle
} from 'widgets/user-account-settings/components/SettingsList/SettingsList.styled'
import { useClickOutside } from 'shared/lib/click-outside/click-outside'
import { SettingLanguage } from 'widgets/user-account-settings/components/SettingLanguage'

import { SettingBalance, SettingLiveOrLine, SettingTheme } from '..'

export const SettingsList = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const accountId = useSelector(selectAccountId)
  const config = useSelector(selectConfig)

  const handleModalClose = useCallback(() => {
    dispatch(layoutContainerActions.setIsDisplayModalSettings(false))
  }, [dispatch])

  const modalRef = useClickOutside<HTMLDivElement>(handleModalClose)

  return (
    <StyledSettingsList isAuthorized={!!accountId} ref={modalRef}>
      <StyledSettingsListTitle>{t('site settings')}</StyledSettingsListTitle>

      <StyledSettingsListItems>
        {accountId && <SettingBalance />}
        {!config.IS_TRANSIT && <SettingTheme />}
        <SettingLiveOrLine />
        <SettingLanguage />
      </StyledSettingsListItems>
    </StyledSettingsList>
  )
}
